document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home") {
        let tableMaterialRent = null;

        tableMaterialRent = $('#table-materials_rent').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": true,
            'width' : [[0, '1']],
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });

        let tablePointage = $('#table-pointage').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": true,
            'width' : [[0, '1']],
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });

        $("#home-workers").select2({
            minimumResultsForSearch: -1,
            placeholder: "Agents"
        });

        $("#home-workers").on("change", function() {

            let workers_id = $(this).val()
            //let date = moment($("#home-pointing_date").datepicker("getDate")).format('YYYY-MM-DD');

            let start_date
            let end_date

            if ($("#home-pointing_date > input[name='start']").datepicker("getDate") != null)
                start_date = moment($("#home-pointing_date > input[name='start']").datepicker("getDate")).format('YYYY-MM-DD')
            if ($("#home-pointing_date > input[name='end']").datepicker("getDate") != null)
                end_date = moment($("#home-pointing_date > input[name='end']").datepicker("getDate")).format('YYYY-MM-DD')

            getPointing(workers_id, start_date, end_date)
        })

        $("#home-pointing_date").datepicker({
            format: 'dd/mm/yyyy',
            autoclose: true,
            language: 'fr'
        })
        $("#home-pointing_date").datepicker("setDate", new Date());

        $("#home-pointing_date").on("changeDate", function() {

            let start_date
            let end_date

            if ($("#home-pointing_date > input[name='start']").datepicker("getDate") != null)
                start_date = moment($("#home-pointing_date > input[name='start']").datepicker("getDate")).format('YYYY-MM-DD')
            if ($("#home-pointing_date > input[name='end']").datepicker("getDate") != null)
                end_date = moment($("#home-pointing_date > input[name='end']").datepicker("getDate")).format('YYYY-MM-DD')

            let workers_id = $("#home-workers").val()
            //let start_date = moment($("#home-pointing_date").datepicker("getDate")).format('YYYY-MM-DD');

            getPointing(workers_id, start_date, end_date)
        })

        $(".change-state-received").on("click", function(e) {
            let material_id = $(e.currentTarget).data('id')

            Swal.fire({
                title: "Matériel de location",
                icon: "warning",
                text: "Ce matériel est-il récupéré ?",
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    $.ajax({
                        url: `${HOST_API}/materials/update_materials_rent/${material_id}`,
                        type: 'PUT',
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                        },
                        data: {
                            
                        },
                        dataType: "json",
                        success: function (data) {
                            //$("#material_rent-" + material_id).remove()
                            tableMaterialRent.row($(`#material_rent-` + material_id)).remove().draw();
                        }
                    })
                }
            });
        })

        function getPointing(worker_id, date_start, date_end) {
            $.ajax({
                url: `/pointage/${worker_id}/${date_start}/${date_end}`,
                type: 'GET',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tablePointage.rows().clear().draw();
                    jQuery.each(data, function (i, val) {
                        
                        let pointage = "<span class='badge'>Pas encore validé</span>"

                        if (val.pointage) {
                            pointage = `<span class="badge badge-success">Validé (${moment(val.pointage).format("LT")})</span>`
                        }

                        let newRow = tablePointage.row.add( [
                            val.worker,
                            val.worksite,
                            val.planning_type,
                            moment(val.date).format("DD-MM-YYYY"),
                            pointage
                        ] ).draw( false ).node();

                        $(newRow).attr("id", `pointing-` + val.id);
                    })
                }
            })
        }
    }
})