const { get } = require("jquery");
const { search } = require("modernizr");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "worksites" && ($("body").data("action") == 'index' || $("body").data("action") == 'show')) {
        let map;
        let method = "new"
        let method_contact = 'new'
        let methodAgent = 'new'
        let methodMaterialRent = 'new'
        let methodMaterialSell = 'new'
        let showPlanningTab = false
        let worksite_id;
        let worksite_id_duplicate;
        let planning_id_duplicate;
        let working_day_duplicate
        let agents_selected = [];
        let planning_id;
        let planning_regular_id;
        let planning_regular_id_duplicate;
        let working_day_regular_duplicate
        let working_day_duplicate_regular;
        let contact_id;
        let material_rent_id;
        let material_sell_id;
        let tableWorksites = null;
        let tableContacts = null;
        let tableAgents = null;
        let tablePlanning = null;
        let tablePlanningRegular = null;
        let tableMaterials = null;
        let tableMaterialsRent = null;
        let tableMaterialsSell = null;
        let tableListAgents = null;
        let tableListAgentsRegular = null;
        let tableListMaterials = null;
        let selectedContacts = []
        let selectedContactsDeleted = []
        let selectedAgents = []
        let selectedAgentsRegular = []
        let selectedMaterials = []
        let selectedMaterialRents = []
        let selectedMaterialSells = []
        let planningWorkingDays = [];
        let deletedPlanningWorkingDays = [];
        let planningWorkingDaysRegular = [];
        let deletedPlanningWorkingDaysRegular = [];
        let deletedMaterials = [];
        let deletedMaterialRents = [];
        let deletedMaterialSells = [];
        let planningAgents = [];
        let planningAgentsRegular = [];
        let codePhoto;
        let codePhotoPlanning;
        let codePhotoPlanningRegular;
        let myDropzone;
        let myDropzonePlanning;
        let myDropzonePlanningRegular;
        let removePhotos = []
        let removePhotosPlanning = []
        let removePhotosPlanningRegular = []
        let current_ideo_workers_needed = 0
        let total_ideo_workers_available = 0

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableWorksites != null) {
                tableWorksites.destroy();
            }
            if (tablePlanning != null) {
                tablePlanning.destroy();
            }
            if (tablePlanningRegular != null) {
                tablePlanningRegular.destroy();
            }
            if (tableMaterials != null) {
                tableMaterials.destroy();
            }
            if (tableMaterialsRent != null) {
                tableMaterialsRent.destroy();
            }
            if (tableMaterialsSell != null) {
                tableMaterialsSell.destroy();
            }
            if (tableListAgents != null) {
                tableListAgents.destroy();
            }
            if (tableListAgentsRegular != null) {
                tableListAgentsRegular.destroy();
            }
            if (tableListMaterials != null) {
                tableListMaterials.destroy();
            }
            if (tableContacts != null) {
                tableContacts.destroy();
            }
        })

        let dropzone = new Dropzone("#worksite-photos", { 
            url: `${HOST_API}/worksites/upload_photos`,
            autoProcessQueue: true,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 100,
            addRemoveLinks: true,
            init: function() {
                myDropzone = this;
            
                // First change the button to actually tell Dropzone to process the queue.
                this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
                  // Make sure that the form isn't actually being sent.
                  e.preventDefault();
                  e.stopPropagation();
                  myDropzone.processQueue();
                });
            
                // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
                // of the sending event because uploadMultiple is set to true.
                this.on("addedfile", function() {
                    $("#button-submit-photo").attr("disabled", false)
                });
                this.on("removedfile", function(e) {
                    removePhotos.push(e.id)
                    if ($(".dz-preview").length == 0) {
                        $("#button-submit-photo").attr("disabled", true)
                    }
                });
                this.on("sending", function(file, xhr, formData){
                    if (codePhoto != null) 
                        formData.append("codePhoto", codePhoto);

                    if (codePhoto == null) {
                        formData.append("worksite_id", worksite_id);
                    }

                });
                this.on("sendingmultiple", function() {
                  // Gets triggered when the form is actually being sent.
                  // Hide the success button or the complete form.
                });
                this.on("successmultiple", function(files, response) {
                  // Gets triggered when the files have successfully been sent.
                  // Redirect user or notify of success.
                });
                this.on("errormultiple", function(files, response) {
                  // Gets triggered when there was an error sending the files.
                  // Maybe show form again, and notify user of error
                });
                this.on("complete", function(file) { 
                    //this.removeAllFiles(true); 
                });


            }
        });

        let dropzone_planning = new Dropzone("#planning-photos", { 
            url: `${HOST_API}/plannings/upload_photos`,
            autoProcessQueue: true,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 100,
            addRemoveLinks: true,
            init: function() {
                myDropzonePlanning = this;
            
                // First change the button to actually tell Dropzone to process the queue.
                this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
                  // Make sure that the form isn't actually being sent.
                  e.preventDefault();
                  e.stopPropagation();
                  myDropzonePlanning.processQueue();
                });
            
                // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
                // of the sending event because uploadMultiple is set to true.
                this.on("addedfile", function() {
                    $("#button-submit-photo").attr("disabled", false)
                });
                this.on("removedfile", function(e) {
                    removePhotosPlanning.push(e.id)
                    if ($(".dz-preview").length == 0) {
                        $("#button-submit-photo").attr("disabled", true)
                    }
                });
                this.on("sending", function(file, xhr, formData){
                    if (codePhotoPlanning != undefined) 
                        formData.append("codePhoto", codePhotoPlanning);

                    if (planning_id != null) {
                        formData.append("planning_id", planning_id);
                    }

                });
                this.on("sendingmultiple", function() {
                  // Gets triggered when the form is actually being sent.
                  // Hide the success button or the complete form.
                });
                this.on("successmultiple", function(files, response) {
                  // Gets triggered when the files have successfully been sent.
                  // Redirect user or notify of success.
                });
                this.on("errormultiple", function(files, response) {
                  // Gets triggered when there was an error sending the files.
                  // Maybe show form again, and notify user of error
                });
                this.on("complete", function(file) { 
                    //this.removeAllFiles(true); 
                });


            }
        });

        let dropzone_planning_regular = new Dropzone("#planning_regular-photos", { 
            url: `${HOST_API}/planning_regulars/upload_photos`,
            autoProcessQueue: true,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 100,
            addRemoveLinks: true,
            init: function() {
                myDropzonePlanningRegular = this;
            
                // First change the button to actually tell Dropzone to process the queue.
                this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
                  // Make sure that the form isn't actually being sent.
                  e.preventDefault();
                  e.stopPropagation();
                  myDropzonePlanningRegular.processQueue();
                });
            
                // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
                // of the sending event because uploadMultiple is set to true.
                this.on("addedfile", function() {
                    $("#button-submit-photo").attr("disabled", false)
                });
                this.on("removedfile", function(e) {
                    removePhotosPlanningRegular.push(e.id)
                    if ($(".dz-preview").length == 0) {
                        $("#button-submit-photo").attr("disabled", true)
                    }
                });
                this.on("sending", function(file, xhr, formData){
                    if (codePhotoPlanningRegular != undefined) 
                        formData.append("codePhoto", codePhotoPlanningRegular);

                    if (planning_regular_id != null) {
                        formData.append("planning_regular_id", planning_regular_id);
                    }

                });
                this.on("sendingmultiple", function() {
                  // Gets triggered when the form is actually being sent.
                  // Hide the success button or the complete form.
                });
                this.on("successmultiple", function(files, response) {
                  // Gets triggered when the files have successfully been sent.
                  // Redirect user or notify of success.
                });
                this.on("errormultiple", function(files, response) {
                  // Gets triggered when there was an error sending the files.
                  // Maybe show form again, and notify user of error
                });
                this.on("complete", function(file) { 
                    //this.removeAllFiles(true); 
                });


            }
        });

        $(`.btn-delete-worksite`).on("click", function(e) {
            Swal.fire({
                title: "Suppression",
                icon: "warning",
                text: `Voulez-vous vraiment supprimer ce chantier ?`,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    destroy($(e.currentTarget).data("worksite_id"))
                }
            });
        })

        $(`.btn-edit-worksite`).on("click", function (e) {
            method = 'edit'
            if($(e.target).data('worksite_id') == undefined)
                worksite_id = $(e.currentTarget).data('worksite_id')
            else
                worksite_id = $(e.target).data('worksite_id')
            
            $('#modal-worksite').modal({
                backdrop: 'static',
                keyboard: false  
            })
        });

        $('.btn-duplicate-worksite').on("click", function(e) {
            worksite_id_duplicate = $(e.currentTarget).data('worksite_id')
           
            $("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
            $('#modal-worksite-duplicate').modal({
                backdrop: 'static',
                keyboard: false  
            })
        })
        
        $("#worksite-photos").addClass("dropzone")
        $("#planning-photos").addClass("dropzone")
        $("#planning_regular-photos").addClass("dropzone")
        // $('#worksite-date_start').on("changeDate", function() {
        //     if (moment($('#worksite-date_start').datepicker("getDate")) > moment($('#worksite-date_end').datepicker("getDate"))) {
        //         $('#worksite-date_end').datepicker("setDate", "")
        //     }

        //     let date = moment($('#worksite-date_start').datepicker("getDate")).format("DD-MM-YYYY")
        //     let new_date = moment(date, "DD-MM-YYYY").add(1, 'days')
        //     $('#worksite-date_end').datepicker('setStartDate', moment(new_date).format("DD/MM/YYYY"))
        // })

        // detect agent affectation for each day
        $("#worksite-planning-date").on("changeDate", function(e) {
            getAffectation()
        })
        
        tableWorksites = $('#table-worksites').DataTable({
            'processing': true,
            'serverSide': true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "ajax": {
                url: $('#table-worksites').data('source'),
                data: function(e) {
                    e.worksite_ended = $("#filter-worksite_finished").prop('checked');
                    // e.worksites = $("#photos-worksites").val();
                    // e.dates = $("#photos-dates").val();
                }
            },
            
            "columns": [
                {"data": "name",
                "render": function(data, display, record) {
                    return `<a href='/worksites/${record.id}' title='Voir le chantier en détail'>${record.name}</a>`
                }},
                {"data": "category"},
                {"data": "address"},
                // {"data": "date_start"},
                // {"data": "date_end"},
                {"data": "created_at",
                    "render": function(data, display, record) {
                        let dd = record.created_at //moment(record.created_at.replaceAll(' UTC', '')).format("DD-MM-YYYY LT")
                        return `${dd}`
                }},
                {"data": "actions",
                 "render": function(data, display, record) {
                    let edit_btn = '';
                    let delete_btn = '';
                    if (record.state == "in_progress" || record.state == 'canceled') {
                        edit_btn = `<a href='javascript:void(0)' id='btn-edit-worksite-${record.id}' class='btn-edit-worksite' data-worksite_id='${record.id}' data-toggle='modal' data-target='#modal-worksite' data-method='edit' backdrop='static' keyboard='false' class='dropdown-item'>Modifier</a>`
                        delete_btn = `<a href='javascript:void(0)' class='btn-delete-worksite btn-delete-worksite-${record.id}' data-worksite_id='${record.id}' class='dropdown-item'>Supprimer</a>`
                    }

                    return `<div class='dropdown'>
                    <a id='card-settings-${record.id}' data-target='#' href='#' data-toggle='dropdown' aria-haspopup='true' role='button' aria-expanded='false'>
                        <i class='card-icon card-icon-settings'></i>
                    </a>
                    <div class='dropdown-menu dropdown-menu-right' role='menu' aria-labelledby='card-settings' style='will-change: transform;'>
                        ${edit_btn}
                        <a href='javascript:void(0)' class='dropdown-item btn-duplicate-worksite' data-worksite_id='${record.id}'>Dupliquer</a>
                        ${delete_btn}
                    </div>
                </div>`
                 }
                }
            ],
            createdRow: function (row, data, index) {
                if (data.rate != '') {
                    $(row).addClass("worksite_finished")
                }

                if (data.state == 'canceled') {
                    $(row).addClass("worksite_canceled")
                }

                $(row).attr('id', "worksite-" + data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.btn-edit-worksite`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('worksite_id')

                    if($(e.target).data('worksite_id') == undefined)
                        worksite_id = $(e.currentTarget).data('worksite_id')
                    else
                        worksite_id = $(e.target).data('worksite_id')
                    
                    $('#modal-worksite').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })
                });

                $(`.btn-delete-worksite`).on("click", function(e) {

                    Swal.fire({
                        title: "Suppression",
                        icon: "warning",
                        text: `Voulez-vous vraiment supprimer ce chantier?`,
                        showCancelButton: true,
                        cancelButtonText: 'Annuler',
                        confirmButtonText: 'Oui',
                    }).then(result => {
                        if (result.isConfirmed) {
                            destroy($(this).data('worksite_id'))
                        }
                    });
                })

                $(`.btn-duplicate-worksite`).on("click", function(e) {

                    worksite_id_duplicate = $(e.currentTarget).data('worksite_id')
                    $("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                    $('#modal-worksite-duplicate').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })
                })
            },
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 100,
            "bAutoWidth": false,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 4,
                    'className': 'dt-body-right'
                },
            ],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksites_wrapper > div > .col-sm-6:nth-child(1)").remove()

        $("#table-worksites_filter").append("<span class='filter-planning_passed'>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-worksite_finished' checked>" + 
                "<label for='filter-worksite_finished'>Afficher les chantiers terminés</label>" +
            "</div>" + 
        "</span>")

        // filter show finished worksite
        $("#filter-worksite_finished").on("change", function() {
            tableWorksites.ajax.reload()
        })

        tableContacts = $('#table-worksite-contacts').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '80'], [2, '80'], [3, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 3,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-contacts_wrapper > div > .col-sm-6:nth-child(1)").remove()

        $.fn.dataTable.moment( 'DD MMMM YYYY' );
        $.fn.dataTable.ext.type.order[ 'moment-'+'DD MMMM YYYY'+'-pre' ] = function ( d ) {
            return moment( d, 'DD MMMM YYYY', 'fr', true ).unix();
        };

        tablePlanning = $('#table-worksite-planning').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '50'], [2, '80'], [3, '10']],
            'order' : [[1,'desc']],
            'columnDefs': [
                { visible: false, targets: 1 },
                {
                    'targets': 6,
                    'className': 'dt-body-right'
                }
            ],
            // "aoColumnDefs": [
            //     {
            //         "aTargets": [0],
            //         "sType": "date",
            //         "fnRender": function ( dateObj ) {
            //             var oDate = new Date(dateObj.aData[0]);
            //             result = oDate.getDate()+"/"+(oDate.getMonth()+1)+"/"+oDate.getFullYear();
            //             return "<span>"+result+"</span>";
            //         }
            //     }
            // ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-planning_wrapper > div > .col-sm-6:nth-child(1)").remove()
        

        tablePlanningRegular = $('#table-worksite-planning_regular').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '50'], [1, '80'], [2, '10']],
            'order' : [[0,'asc']],
            'columnDefs': [
                {
                    'targets': 6,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-planning_regular_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableAgents = $('#table-worksite-agents').DataTable({
            "bPaginate": true,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '80'], [2, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 2,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-agents_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableListAgents = $('#table-worksite-list_agents').DataTable({
            "scrollY": '300px',
            "scrollCollapse": true,
            "paging": false,
            "bPaginate": false,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            'columnDefs': [
                {
                    'targets': [5, 6],
                    'className': 'dt-body-center'
                }
            ],
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '50'], [2, '100']],
            'order' : [[4,'desc']],
            "columnDefs": [
                { "type": "num", "targets": 4 }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-list_agent_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableListAgentsRegular = $('#table-worksite-list_agents_regular').DataTable({
            "scrollY": '300px',
            "scrollCollapse": true,
            "paging": false,
            "bPaginate": false,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '50'], [2, '100']],
            'order' : [[2,'asc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-list_agents_regular_wrapper > div > .col-sm-6:nth-child(1)").remove()
        $(".dataTables_scroll").css("width", "100%");

        tableListMaterials = $('#table-worksite-list_materials').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '80'], [2, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-list_materials_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableMaterials = $('#table-worksite-materials').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '80'], [2, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 3,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-materials_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableMaterialsRent = $('#table-worksite-materials_rent').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '10'], [2, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 5,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-materials_rent_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableMaterialsSell = $('#table-worksite-materials_sell').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[0, '100'], [1, '80'], [2, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 3,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-worksite-materials_sell_wrapper > div > .col-sm-6:nth-child(1)").remove()
        
        $('#datepicker-material_rent').datepicker({
            format: 'dd/mm/yyyy',
            language: 'fr' 
        });

        $("#worksite-planning_regular-materials").select2({
            minimumResultsForSearch: -1,
            placeholder: "Creators"
        });

        $("#worksite-planning-materials").select2({
            minimumResultsForSearch: -1,
            placeholder: "Creators"
        });

        $("#worksite-select_contact").on("change", function() {
            
            $("#btn-save-contact").attr('disabled', true)

            $("#worksite-contact-name").val("")
            $("#worksite-contact-phone").val("")
            $("#worksite-contact-email").val("")
            $("#worksite-contact-role").val("")
            $("#worksite-contact-company").val("")
            $("#worksite-contact-function").val("")

            fetch(`${HOST_API}/contacts/${$(this).val()}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default' 
            }).then(function(data) {
                data.json().then(function(data) {
                    $("#worksite-contact-name").val(data.name)
                    $("#worksite-contact-phone").val(data.phone)
                    $("#worksite-contact-email").val(data.email)
                    $("#worksite-contact-role").val(data.role)
                    $("#worksite-contact-company").val(data.company)
                    $("#worksite-contact-function").val(data.function)

                    $("#btn-save-contact").attr('disabled', false)
                })
            })

        })

        // open new worksite
        $('#btn-add-worksite').on("click", function (e) {
            method = 'new'
            var length = 6,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#!$",
            retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }

            codePhoto = retVal
            $('#modal-worksite').modal({
                backdrop: 'static',
                keyboard: false  
            })
        });

        $("#btn-edit-worksite").on("click", function (e) {
            method = 'edit'
            worksite_id = $(e.currentTarget).data("worksite_id")
            $('#modal-worksite').modal({
                backdrop: 'static',
                keyboard: false  
            })
        })

        // open contact
        $('#btn-add-contact').on("click", function () {
            method_contact = 'new'
            $('#modal-contact').modal({
                backdrop: 'static',
                keyboard: false  
            })
            $("#modal-worksite").css('z-index', '1050')
        });

        // open list agent
        $('#btn-add-date').on("click", function () {
            $('#modal-list_agents').modal({
                backdrop: 'static',
                keyboard: false  
            })

            $("#worksite-planning-materials").val(null).trigger('change');
            $(".modal-backdrop").last().css("z-index", "1050")
        });

        // open planning regular
        $('#btn-add-date_regular').on("click", function () {
            $('#modal-planning_regular').modal({
                backdrop: 'static',
                keyboard: false  
            })

            $("#worksite-planning_regular-materials").val(null).trigger('change');
            $(".modal-backdrop").last().css("z-index", "1050")
        });

        // show modal contact
        $('#modal-contact').on('shown.bs.modal', function(e) { 
            $(".modal-backdrop").last().css("z-index", "1050")

            if (method_contact == 'edit') {
                $(".subtitle-modal-contact").text('Modifier un contact')
                $("#btn-save-contact").text("Modifier")
                $("#worksite-contact-name").val($(`#contact-` + contact_id + " td:nth-child(1)").text())
                $("#worksite-contact-phone").val($(`#contact-` + contact_id + " td:nth-child(2)").text())
                $("#worksite-contact-email").val($(`#contact-` + contact_id + " td:nth-child(3)").text())
                $("#worksite-contact-role").val($(`#contact-` + contact_id + " td:nth-child(4)").text())
            }
            else {
                $(".subtitle-modal-contact").text('Ajouter un contact')
                $("#btn-save-contact").text("Ajouter")
            }
        });

        // open add/edit contact
        $('#btn-new-contact').on("click", function () {
            $('#modal-new_contact').modal({
                backdrop: 'static',
                keyboard: false  
            })
            $(".modal-backdrop").last().css("z-index", "1051")
        });

        $("#btn-save-new-contact").on("click", function() {
            $("#form-new-contact").validate()
            $('#form-new-contact').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-new-contact").valid()) {
               createContact()
            }
        })

        // hidden modal contact
        $('#modal-contact').on('hidden.bs.modal', function(e) { 
            $(".alert-contact_error").addClass("hidden")
            $("#worksite-select_contact").val('').trigger('change')
            $("#worksite-contact-name").val("")
            $("#worksite-contact-phone").val("")
            $("#worksite-contact-email").val("")
            $("#worksite-contact-role").val("")
            $("#worksite-contact-company").val("")
            $("#worksite-contact-name").attr('disabled', false)
            $("#worksite-contact-phone").attr('disabled', false)
            $("#worksite-contact-email").attr('disabled', false)
            $("#worksite-contact-role").attr('disabled', false)
            $("#worksite-contact-company").attr('disabled', false)

            contact_id = undefined
        });


        // hidden modal duplicate
        $('#modal-worksite-duplicate').on('hidden.bs.modal', function(e) { 
            worksite_id_duplicate = null
            $(".alert-duplicate").addClass("hidden")
            $(".progress").addClass("hidden")
            $("#worksite-duplicate-name").val("")
            $("#worksite-duplicate-category").val("").trigger("change")
        })

        // shown modal list agents
        $('#modal-list_agents').on('shown.bs.modal', function(e) { 
            // getAffectation($(e.relatedTarget).data("planning_id"))

            planning_id = $(e.relatedTarget).data("planning_id")

            var length = 6,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$",
            retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }

            codePhotoPlanning = retVal

            $("#table-worksite-list_agents_wrapper .dataTables_scrollBody").scrollTop(0);
        });

        $('#modal-list_agents').on('hidden.bs.modal', function(e) { 
            $("#table-worksite-list_agents input[type='checkbox']").attr("checked", false)

            let data;
            if (methodAgent == 'new') {
                data =  {
                    codePhotoPlanning: codePhotoPlanning
                }
            }
            else {
                data =  {
                    planning_id: planning_id
                }
            }

            if (methodAgent == 'new') {
                dropzone_planning.removeAllFiles(true);
                $(".dz-image-preview").remove()
                $("#planning-photos").removeClass("dz-started")
            }
            else {
                dropzone_planning.removeAllFiles(true);
                $(".dz-image-preview").remove()
                $("#planning-photos").removeClass("dz-started")
            }

            $(".selecting-agent").attr("checked", false)
        });

        $('#modal-planning_regular').on('hidden.bs.modal', function(e) { 
            $("#table-worksite-list_agents_regular input[type='checkbox']").attr("checked", false)
           
            planning_regular_id = ''
        });

        // show modal list agents
        $('#modal-list_agents').on('shown.bs.modal', function(e) { 
            agents_selected = []
            methodAgent = $(e.relatedTarget).data('method') 
            
            if ($(e.relatedTarget).data('method') == "edit_new") {
       
                $("#planning_id").val($(e.relatedTarget).data('planning_id'))
                let p_id = $(e.relatedTarget).data('planning_id')

                $("#btn-save-agent").text("Modifier")
                $(".planning-subtitle").text("Modifier une journée")

                methodAgent = 'edit_new'
                let disabledDates = []
                let agents_id = []
                let date = $(e.relatedTarget).data('working_day')
                disabledDates.push(date)
                
                // set date
                if ($("#worksite-planning-date").val() == "") {
                    $("#worksite-planning-date").val(date)
                    $("#worksite-planning-date").attr("disabled", true)

                    $("#worksite-planning-date").datepicker({
                        format: 'dd/mm/yyyy',
                        datesDisabled: disabledDates,
                        autoclose: true,
                        language: 'fr'
                    })
                    
                }
                
                // set leader
                if (Array.isArray($(e.relatedTarget).data('leader_id')))
                    $("#worksite-planning-leader").val($(e.relatedTarget).data('leader_id').map(val => parseInt(val))).trigger("change")
                else {
                    let ids = $(e.relatedTarget).data('leader_id')
                    $("#worksite-planning-leader").val(String(ids).split(',').map(val => parseInt(val))).trigger("change")
                }

                // set agents
                if($(e.relatedTarget).data('agents_id').toString().includes(',')) {
                    agents_id = $(e.relatedTarget).data('agents_id').split(',')
                    agents_selected = $(e.relatedTarget).data('agents_id').split(',')
                }
                else {
                    agents_id.push($(e.relatedTarget).data('agents_id'))
                    agents_selected.push($(e.relatedTarget).data('agents_id'))
                }

                // for(let i=0 ;i < agents_id.length ; i++) {
                //     $("#selecting-agent-" + agents_id[i]).prop("checked", true)
                //     $("#list_agent-" + agents_id[i]).addClass('selected');

                //     let one_agent = {
                //         id: agents_id[i]
                //     }

                //     selectedAgents.push(one_agent)
                // }
                
                $("#worksite-planning-needs").val($(`#working_day-${date.replaceAll('/', '-')} td:nth-child(4)`).text())
                $("#worksite-planning-remark").val($(`#working_day-${date.replaceAll('/', '-')} td:nth-child(5)`).text())

                let materials = $(e.relatedTarget).attr('data-materials')

                if (materials != undefined)
                    $("#worksite-planning-materials").val(materials.split(',')).trigger('change');
            }
            else {
                $("#btn-save-agent").text("Ajouter")
                $(".planning-subtitle").text("Ajouter une journée")
                methodAgent = 'new'
                let disabledDates = []

                $("#worksite-planning_regular-materials").val(null).trigger('change');
                $("#worksite-planning-materials").val(null).trigger('change');

                Array.prototype.forEach.call(document.getElementsByClassName('working_day'), function(item) {
                    disabledDates.push($(item).attr('data-date'))
                })

                $("#worksite-planning-date").attr("disabled", false)

                $("#worksite-planning-date").datepicker({
                    format: 'dd/mm/yyyy',
                    datesDisabled: disabledDates,
                    autoclose: true,
                    language: 'fr'
                })
            }

            if ($(e.relatedTarget).data("working_day") != undefined)
                getPlannigWorkerDetails($(e.relatedTarget).data("planning_id"), "working_day-" + $(e.relatedTarget).data("working_day").replaceAll('/', '-'))
            else
                getPlannigWorkerDetails($(e.relatedTarget).data("planning_id"), null)
        });

        // show modal list agents regular
        $('#modal-planning_regular').on('shown.bs.modal', function(e) { 
            planning_regular_id = $(e.relatedTarget).data('planning_id')
            
            var length = 6,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$",
            retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            codePhotoPlanningRegular = retVal

            if ($(e.relatedTarget).data('method') == "edit_new") {
                $("#planning_id").val($(e.relatedTarget).data('planning_id'))

                $("#btn-save-agent_regular").text("Modifier")
                $(".planning-subtitle").text("Modifier une journée")

                methodAgent = 'edit_new'
                let disabledDates = []
                let agents_id = []
                let planning_id = $(e.relatedTarget).data('planning_id')
                planning_regular_id = planning_id
                let date_start = $(e.relatedTarget).data('working_day_regular_start')
                let date_end = $(e.relatedTarget).data('working_day_regular_end')
                disabledDates.push(date_start)
               
                let code_photo;

                // set date
                $("#worksite-planning_regular-date_start").val(date_start)
                $("#worksite-planning_regular-date_start").attr("disabled", true)
                $("#worksite-planning_regular-date_end").val(date_end)
                $("#worksite-planning_regular-date_end").attr("disabled", true)

                if (planning_id != undefined) {
                    $("#worksite-planning_regular-needs").val($(`#${planning_id}-working_day_regular-${date_start.replaceAll('/', '-')} td:nth-child(4)`).text())
                    $("#worksite-planning_regular-remark").val($(`#${planning_id}-working_day_regular-${date_start.replaceAll('/', '-')} td:nth-child(5)`).text())

                    let mission = $(`#${planning_id}-working_day_regular-${date_start.replaceAll('/', '-')} td:nth-child(6)`).text()
                    let mission_id = $(`#planning_regular-mission option:contains(${mission})`).val()
                    $(`#planning_regular-mission`).val(mission_id).trigger('change')
                    code_photo = $(`#${planning_id}-working_day_regular-${date_start.replaceAll('/', '-')}`).data('code_photo')
                }
                else {
                    $("#worksite-planning_regular-needs").val($(`#working_day_regular-${date_start.replaceAll('/', '-')} td:nth-child(4)`).text())
                    $("#worksite-planning_regular-remark").val($(`#working_day_regular-${date_start.replaceAll('/', '-')} td:nth-child(5)`).text())
                    let mission = $(`#working_day_regular-${date_start.replaceAll('/', '-')} td:nth-child(6)`).text()
                    let mission_id = $(`#planning_regular-mission option:contains(${mission})`).val()
                    $(`#planning_regular-mission`).val(mission_id).trigger('change')
                    code_photo = $(`#working_day_regular-${date_start.replaceAll('/', '-')}`).data('code_photo')
                }

                $("#worksite-planning_regular-date_start, #worksite-planning_regular-date_end").datepicker({
                    format: 'dd/mm/yyyy',
                    datesDisabled: disabledDates,
                    autoclose: true,
                    language: 'fr'
                })
             
                $("#planning-duplicate-dateStart_regular, #planning-duplicate-dateEnd_regular").datepicker({
                    format: 'dd/mm/yyyy',
                    datesDisabled: disabledDates,
                    autoclose: true,
                    language: 'fr'
                })

                // set agents
                if($(e.relatedTarget).data('agents_regular_id').toString().includes(','))
                    agents_id = $(e.relatedTarget).data('agents_regular_id').split(',')
                else 
                    agents_id.push($(e.relatedTarget).data('agents_regular_id'))

                for(let i=0 ;i < agents_id.length ; i++) {
                    $("#selecting-agent-" + agents_id[i]).prop("checked", true)
                    $("#list_agent_regular-" + agents_id[i]).addClass('selected');
                }

                let materials = $(e.relatedTarget).attr('data-materials')

                if (materials != undefined)
                    $("#worksite-planning_regular-materials").val(materials.split(',')).trigger('change');


                // get photo tmp
                if (code_photo != undefined) {
                    $.ajax({
                        url: `/photos/planning-photos/${code_photo}`,
                        type: 'GET',
                        suppressGlobalErrorHandler: true,
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                        },
                        dataType: "json",
                        success: function (data) {
                            // photos temp
                            jQuery.each(data, function (i, val) {
                                var mockFile = { name: `${val.photo.url}`, size: 12345, id: val.id };
                                myDropzonePlanningRegular.options.addedfile.call(myDropzonePlanningRegular, mockFile);
                                myDropzonePlanningRegular.options.thumbnail.call(myDropzonePlanningRegular, mockFile, `${val.photo.thumb.url}`);
                                
                            });
                            $(".dz-preview").addClass("dz-complete")
                            $(".dz-progress").remove()
                        }
                    })
                }
                else {
                    // get photo tmp
                    $.ajax({
                        url: `${HOST_API}/planning_regulars/workers/${planning_regular_id}`,
                        type: 'GET',
                        suppressGlobalErrorHandler: true,
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                        },
                        dataType: "json",
                        success: function (data) {
                            // photos temp
                            jQuery.each(data.t_photos, function (i, val) {
                                var mockFile = { name: `${HOST_API}/${val.photo.url}`, size: 12345, id: val.id };
                                myDropzonePlanningRegular.options.addedfile.call(myDropzonePlanningRegular, mockFile);
                                myDropzonePlanningRegular.options.thumbnail.call(myDropzonePlanningRegular, mockFile, `${HOST_API}/${val.photo.thumb.url}`);
                                
                            });
                            $(".dz-preview").addClass("dz-complete")
                            $(".dz-progress").remove()
                        }
                    })
                }
            }
            else {

                $("#btn-save-agent_regular").text("Ajouter")
                $(".planning-subtitle").text("Ajouter une journée")
                methodAgent = 'new'
                let disabledDates = []

                Array.prototype.forEach.call(document.getElementsByClassName('working_day_regular'), function(item) {
                    disabledDates.push($(item).attr('data-date_start'))
                })

                $("#worksite-planning_regular-date_start").attr("disabled", false)
                $("#worksite-planning_regular-date_end").attr("disabled", false)

                $("#worksite-planning_regular-date_start, #worksite-planning_regular-date_end").datepicker({
                    format: 'dd/mm/yyyy',
                    datesDisabled: disabledDates,
                    autoclose: true,
                    language: 'fr'
                })

                $("#planning-duplicate-dateStart_regular, #planning-duplicate-dateEnd_regular").datepicker({
                    format: 'dd/mm/yyyy',
                    datesDisabled: disabledDates,
                    autoclose: true,
                    language: 'fr'
                })

            }
        });

        // close modal list agents
        $("#modal-list_agents").on("hidden.bs.modal", function() {
            $("#table-worksite-list_agents .selecting-agent").closest('tr').removeClass('selected');
            $("#table-worksite-list_agents .selecting-agent").prop("checked", false)

            $("#worksite-planning-date").datepicker("clearDates")
            $("#worksite-planning-date").datepicker("destroy")
            $("#worksite-planning-date").attr("disabled", false)

            $(".list_agent").removeClass('agent-affection-1')
            $(".list_agent").removeClass('agent-affection-2')
            $(".list_agent").removeClass('agent-affection-3')
            $(`.list_agent td:nth-child(5)`).text("")
            $(`.selecting-agent`).attr('disabled', false)

            $("#worksite-planning-needs").val("")
            $("#worksite-planning-remark").val("")

            selectedAgents = []
            tableListAgents.search('').draw();

            $("#planning_id").val("")

            $("#worksite-planning-workers_needed").val("")
            $("#worksite-planning-workers_remaining").val("")

            $("#worksite-planning-workers_remaining").parent().removeClass('worker_remaining_out')

            $("#worksite-planning-leader").val("").trigger('change')

            current_ideo_workers_needed = 0

            planning_id = null
        })

        // close modal list agents
        $("#modal-planning_regular").on("hidden.bs.modal", function() {
            $("#table-worksite-list_agents_regular .selecting-agent").closest('tr').removeClass('selected');
            $("#table-worksite-list_agents_regular .selecting-agent").prop("checked", false)

            $("#worksite-planning_regular-date_start").datepicker("clearDates")
            $("#worksite-planning_regular-date_start").datepicker("destroy")
            $("#worksite-planning_regular-date_start").attr("disabled", false)
            $("#worksite-planning_regular-date_end").datepicker("clearDates")
            $("#worksite-planning_regular-date_end").datepicker("destroy")
            $("#worksite-planning_regular-date_end").attr("disabled", false)

            $("#worksite-planning_regular-needs").val("")
            $("#worksite-planning_regular-remark").val("")
            
            $(`.selecting-agent`).attr('disabled', false)

            selectedAgentsRegular = []
            tableListAgentsRegular.search('').draw();

            $("#planning_id").val("")

            let data;
            if (methodAgent == 'new') {
                data =  {
                    codePhotoPlanningRegular: codePhotoPlanningRegular
                }
            }
            else {
                data =  {
                    planning_regular_id: planning_regular_id
                }
            }

            if (methodAgent == 'new') {
                dropzone_planning_regular.removeAllFiles(true);
                $(".dz-image-preview").remove()
                $("#planning_regular-photos").removeClass("dz-started")
            }
            else {
                dropzone_planning.removeAllFiles(true);
                $(".dz-image-preview").remove()
                $("#planning_regular-photos").removeClass("dz-started")
            }
        })

        // open list materials
        $('#btn-add-list_materials').on("click", function () {
            $('#modal-list_materials').modal({
                backdrop: 'static',
                keyboard: false  
            })
            $(".modal-backdrop").last().css("z-index", "1050")
        }); 
        
        // show modal list materials
        $('#modal-list_materials').on('show.bs.modal', function(e) { 
            for(let i=0 ;i < selectedMaterials.length ; i++) {
                $("#selecting-material-" + selectedMaterials[i].material_id).prop("checked", true)
                $("#list_material-" + selectedMaterials[i].material_id).addClass('selected');
            }
        });

        // close modal list materials
        $("#modal-list_materials").on("hidden.bs.modal", function() {
            $("#table-worksite-list_materials input[type=checkbox]").closest('tr').removeClass('selected');
            $("#table-worksite-list_materials input[type=checkbox]").prop("checked", false)
            tableListMaterials.search('').draw();
        })

        // open modal material rent 
        $('#btn-add-material_rent').on("click", function () {
            $('#modal-material_rent').modal({
                backdrop: 'static',
                keyboard: false  
            })
            $(".modal-backdrop").last().css("z-index", "1050")
        });

        // show modal material rent
        $('#modal-material_rent').on('shown.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit_new') {
                material_rent_id = $(e.relatedTarget).data('material_rent_id')
                methodMaterialRent = 'edit_new'
                $("#modal-material_rent .subtitle-modal").text("Modifier un matériel de location")
                $("#btn-save-material_rent").text("Modifier")
                $("#worksite-material_rent-name").val($(`#material_rent-` + material_rent_id + " td:nth-child(1)").text())
                $("#worksite-material_rent-quantity").val($(`#material_rent-` + material_rent_id + " td:nth-child(2)").text())
                $("#worksite-material_rent-price").val($(`#material_rent-` + material_rent_id + " td:nth-child(3)").text())
                //$("#datepicker-material_rent input[name='start']").val($(`#material_rent-` + material_rent_id + " td:nth-child(4)").text())
                //$("#datepicker-material_rent input[name='end']").val($(`#material_rent-` + material_rent_id + " td:nth-child(5)").text())
                //$("#datepicker-material_rent input[name='start']").val($('#material_rent-' + material_rent_id).data('date_start'))
                //$("#datepicker-material_rent input[name='end']").val($('#material_rent-' + material_rent_id).data('date_end')).trigger('change')
                $("#datepicker-material_rent input[name='start']").datepicker('setDate', moment($('#material_rent-' + material_rent_id).data('date_start')).format("DD/MM/YYYY"))
                $("#datepicker-material_rent input[name='end']").datepicker('setDate', moment($('#material_rent-' + material_rent_id).data('date_end')).format("DD/MM/YYYY"))
            }
            else {
                $("#modal-material_rent .subtitle-modal").text("Ajouter un matériel de location")
                $("#btn-save-material_rent").text("Ajouter")
                methodMaterialRent = 'new'
            }
        });

        // shown modal material rent
        $('#modal-material_rent').on('shown.bs.modal', function(e) { 
            $(".modal-backdrop").last().css("z-index", "1051")
        });

        // close modal material rent
        $("#modal-material_rent").on("hidden.bs.modal", function() {
            $("#worksite-material_rent-name").val("")
            $("#worksite-material_rent-quantity").val(1)
            $("#worksite-material_rent-days").val(1)
            $("#worksite-material_rent-price").val("")
            $("#datepicker-material_rent input[name='start']").val("")
            $("#datepicker-material_rent input[name='end']").val("")
            $("#datepicker-material_rent input[name='start']").datepicker("clearDates")
            $("#datepicker-material_rent input[name='end']").datepicker("clearDates")
            $("#start-error").remove() 
            $("#end-error").remove() 

            material_rent_id = null
            methodMaterialRent = 'new'
        })
        
        // open modal material sell 
        $('#btn-add-material_sell').on("click", function () {
            $('#modal-material_sell').modal({
                backdrop: 'static',
                keyboard: false  
            })
            $(".modal-backdrop").last().css("z-index", "1050")
        }); 

        // show modal material sell
        $('#modal-material_sell').on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit_new') {
                material_sell_id = $(e.relatedTarget).data('material_sell_id')
                methodMaterialSell = 'edit_new'
                $("#modal-material_sell .subtitle-modal").text("Modifier un matériel de vente")
                $("#btn-save-material_sell").text("Modifier")
                $("#worksite-material_sell-name").val($(`#material_sell-` + material_sell_id + " td:nth-child(1)").text())
                $("#worksite-material_sell-quantity").val($(`#material_sell-` + material_sell_id + " td:nth-child(2)").text())
                $("#worksite-material_sell-price").val($(`#material_sell-` + material_sell_id + " td:nth-child(3)").text())
            }
            else {
                $("#modal-material_sell .subtitle-modal").text("Ajouter un matériel de vente")
                $("#btn-save-material_sell").text("Ajouter")
                methodMaterialSell = 'new'
            }
        });

        // shown modal material sell
        $('#modal-material_sell').on('shown.bs.modal', function(e) { 
            $(".modal-backdrop").last().css("z-index", "1050")
        });

        // close modal material sell
        $("#modal-material_sell").on("hidden.bs.modal", function() {
            $("#worksite-material_sell-name").val("")
            $("#worksite-material_sell-quantity").val(1)
            $("#worksite-material_sell-price").val("")

            material_sell_id = null
            methodMaterialSell = 'new'
        })

        // show modal worksite
        $("#modal-worksite").on('show.bs.modal', function(e) { 
            if (method == 'edit') {
                $(".worksite-subtitle").text("Modifier un chantier")
                get(worksite_id)
            }
            else {
                $(".worksite-subtitle").text("Ajouter un nouveau chantier")
            }

            getRegularTeam();
        });
  
        $("#modal-planning-duplicate").on('shown.bs.modal', function(e) { 
            let disabledDates = []

            Array.prototype.forEach.call(document.getElementsByClassName('working_day'), function(el) {
                disabledDates.push($(el).data('date'))
            })

            $("#planning-duplicate-date").datepicker({
                format: 'dd/mm/yyyy',
                datesDisabled: disabledDates,
                autoclose: true,
                language: 'fr',
            })
        });


        $("#modal-planning-duplicate_regular").on('shown.bs.modal', function(e) { 
            // let disabledDates = []
            // let date_start
            // let date_end

            // Array.prototype.forEach.call(document.getElementsByClassName('working_day_regular'), function(el) {
            //    // disabledDates.push($(el).data('date_start'))
            //    date_start = $(el).data('date_start')
            //    date_end = $(el).data('date_end')
            // })
            
            // while(moment(date_start).add(1, 'days').diff(date_end) < 0) {
            //     console.log(moment(date_start).toDate());
            //     disabledDates.push(moment(date_start).clone().toDate());
            // }

            // console.log(disabledDates)
            // $("#worksite-planning_regular-date_start, #worksite-planning_regular-date_end").datepicker({
            //     format: 'dd/mm/yyyy',
            //     datesDisabled: disabledDates,
            //     autoclose: true,
            //     language: 'fr'
            // })

            $("#planning-duplicate-dateStart_regular, #planning-duplicate-dateEnd_regular").datepicker({
                format: 'dd/mm/yyyy',
                autoclose: true,
                language: 'fr'
            })

        });

        $("#modal-planning-duplicate").on('hidden.bs.modal', function(e) {
            //$("#planning-duplicate-date").datepicker('setDate', '')
            $("#planning-duplicate-date").datepicker("clearDates")
            $("#planning-duplicate-date").datepicker("destroy")
        });


        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            }).on('select2:open', function () {

            });
        });

        $("#planning-duplicate-leader").select2()
        

        // hide modal worksite
        $("#modal-worksite").on('hidden.bs.modal', function(e) { 
            let data;

            if (method == 'new') {
                data =  {
                    codePhoto: codePhoto
                }
            }
            else {
                data =  {
                    worksite_id: worksite_id
                }
            }

            fetch(`${HOST_API}/worksites/destroy_tmp_photos`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)

            })

            // $.ajax({
            //     url: `${HOST_API}/worksites/destroy_tmp_photos`,
            //     type: 'POST',
            //     suppressGlobalErrorHandler: true,
            //     beforeSend: function (xhr) {
            //         xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
            //     },
            //     data: data,
            //     dataType: "json",
            //     success: function (data) {
            //     }
            // });

            showPlanningTab = false

            resetWorksiteFields()
            disableFields()

            $(".alert-worksite-info, .alert-worksite-materials").addClass('hidden')
        });

        // cancel modal worksite
        $("#btn-cancel-worksite, .close_worksite").on("click", function() {
            Swal.fire({
                title: "Chantier",
                icon: "warning",
                text: `Voulez-vous vraiment annuler ?`,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    $("#modal-worksite").modal("hide")
                }
            });
        })

        // cancel planning 
        $("#btn-cancel-planning").on("click", function() {

            let data;
            if (methodAgent == 'new') {
                data =  {
                    codePhotoPlanning: codePhotoPlanning
                }
            }
            else {
                data =  {
                    planning_id: planning_id
                }
            }

            fetch(`${HOST_API}/plannings/destroy_tmp_photos`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)

            })
            
            removePhotosPlanning = []
            $("#modal-list_agents").modal("hide")
        })

        // cancel planning regular
        $("#btn-cancel-planning_regular").on("click", function() {

            let data;
            if (methodAgent == 'new') {
                data = {
                    codePhotoPlanningRegular: codePhotoPlanningRegular
                }
            }
            else {
                data = {
                    planning_regular_id: planning_regular_id
                }
            }

            fetch(`${HOST_API}/planning_regulars/destroy_tmp_photos`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)

            })
            
            removePhotosPlanningRegular = []
            $("#modal-planning_regular").modal("hide")
        })

        // add contact
        $("#btn-save-contact").on("click", function() {
            $("#form-contact").validate()
            $('#form-contact').submit(function(e){
                e.preventDefault()
            })

            // let new_contact;
            // let new_contact_id;

            if ($("#form-contact").valid()) {
                // $("#worksite-contact-name").attr("disabled", true)
                // $("#worksite-contact-phone").attr("disabled", true)
                // $("#worksite-contact-email").attr("disabled", true)
                // $("#worksite-contact-role").attr("disabled", true)

                $(".alert-contact_error").addClass("hidden")
                
                //if (contact_id == undefined) {
                    let contact_id = $("#worksite-select_contact").val()

                    if ($(`#contact-${contact_id}`).length > 0) {
                        $(".alert-contact_error").removeClass("hidden")
                        $(".alert-contact_error").text("Contact déja ajouté!")

                        return
                    }
                    $(".alert-contact_error").addClass("hidden")
                    // if (selectedContacts.length > 0) {
                    //     new_contact_id = selectedContacts.at(-1).id + 1
                    // }
                    // else {
                    //     new_contact_id = 1
                    // }

                    // new_contact = {
                    //     id: new_contact_id,
                    //     name: $("#worksite-contact-name").val(),
                    //     phone: $("#worksite-contact-phone").val(),
                    //     email: $("#worksite-contact-email").val(),
                    //     role: $("#worksite-contact-role").val(),
                    //     destroy: false
                    // }

                    let new_contact = {
                        id: contact_id
                    }

                    selectedContacts.push(new_contact)
                    selectedContactsDeleted.splice(selectedContactsDeleted.indexOf(contact_id), 1)

                    let options = `<div class="dropdown">
                        <a id="card-settings-${contact_id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;" >
                                <a href="javascript:void(0)" id='btn-delete-contact-${contact_id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    
                    let newRow = tableContacts.row.add( [
                        $("#worksite-contact-name").val(),
                        $("#worksite-contact-phone").val(),
                        $("#worksite-contact-email").val(),
                        $("#worksite-contact-role").val(),
                        options
                    ] ).draw( false ).node();
                    
                    $(newRow).attr("id", `contact-` + contact_id);

                    $(`#btn-delete-contact-${contact_id}`).on("click", function() {
                        selectedContactsDeleted.push(contact_id)
                        tableContacts.row($(`#contact-` + contact_id)).remove().draw();
                    });
                    
                    $("#worksite-select_contact").val("").trigger("change")
                    $("#worksite-contact-name").val("")
                    $("#worksite-contact-phone").val("")
                    $("#worksite-contact-email").val("")
                    $("#worksite-contact-role").val("")
                    $("#worksite-contact-company").val("")

                    // $("#worksite-contact-name").attr("disabled", false)
                    // $("#worksite-contact-phone").attr("disabled", false)
                    // $("#worksite-contact-email").attr("disabled", false)
                    // $("#worksite-contact-role").attr("disabled", false)
                    // $("#worksite-contact-name").val("")
                    // $("#worksite-contact-phone").val("")
                    // $("#worksite-contact-email").val("")
                    // $("#worksite-contact-role").val("")

                    $("#modal-contact").modal("hide")
                //}
                // else {

                //     for(let i=0 ;i < selectedContacts.length ; i++) {
                //         if (selectedContacts[i].id == contact_id) {

                //             selectedContacts[i].name = $("#worksite-contact-name").val()
                //             selectedContacts[i].phone = $("#worksite-contact-phone").val()
                //             selectedContacts[i].email = $("#worksite-contact-email").val()
                //             selectedContacts[i].role = $("#worksite-contact-role").val()

                //             $("#contact-" + contact_id + " td:nth-child(1)").text(selectedContacts[i].name);
                //             $("#contact-" + contact_id + " td:nth-child(2)").text(selectedContacts[i].phone);
                //             $("#contact-" + contact_id + " td:nth-child(3)").text(selectedContacts[i].email);
                //             $("#contact-" + contact_id + " td:nth-child(4)").text(selectedContacts[i].role);

                //             break
                //         }
                //     }

                //     $("#modal-contact").modal("hide")
                // }
            }
        })
        
        // add agent punctual
        $("#btn-save-agent").on("click", function() {
            $(".alert-agent").addClass("hidden")
            $("#form-agent").validate()

            $('#form-agent').submit(function(e){
                e.preventDefault()
            })

            if (document.getElementsByClassName('list_agent selected').length == 0) {
                $(".alert-agent").removeClass("hidden")
                $(".alert-agent").text("Veuillez sélectionner des agents!")
                return
            }

            if ($("#form-agent").valid()) {
                tableListAgents.search('').draw();

                let date = $("#worksite-planning-date").datepicker("getDate")
                let day = moment(date).format("DD-MM-YYYY")
                let leader = $("#worksite-planning-leader").val()
                let agents = [];
                let agents_id = [];
                let drivers_id = [];
                let leaders_id = [];
                let listAgents = "";
                let listAgentNames = [];
                let needs = $("#worksite-planning-needs").val();
                let remark = $("#worksite-planning-remark").val();
                let materials = $("#worksite-planning-materials").val()
                let workers_needed = $("#worksite-planning-workers_needed").val()
                
                if(methodAgent == 'new') {
    
                    Array.prototype.forEach.call(document.getElementsByClassName('list_agent selected'), function(el) {

                        let one_agent = {
                            id: $(el).attr('data-id'),
                            name: $(`#list_agent-` + $(el).attr('data-id') + " td:nth-child(2)").text(),
                            leader: $(`.agent-leader-${$(el).attr('data-id')}`).prop("checked"),
                            driver: $(`.agent-driver-${$(el).attr('data-id')}`).prop("checked"),
                            needs: needs,
                            remark: remark
                        }

                        agents_id.push(one_agent.id)
                        one_agent.driver ? drivers_id.push(one_agent.id) : ''
                        one_agent.leader ? leaders_id.push(one_agent.id) : ''
                        agents.push(one_agent)

                        listAgents += `-${one_agent.name}<br> `
                        listAgentNames.push(one_agent.name)

                    })

                    listAgentNames = listAgentNames.map(name => `<span class='label'>${name}</span>`)

                    planningAgents.push(agents)

                    let options = `<div class="dropdown">
                        <a id="card-settings-${day}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                        <i class="card-icon card-icon-settings "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                            <a href="javascript:void(0)" data-working_day='${moment(date).format("DD/MM/YYYY")}' data-toggle="modal" data-target="#modal-list_agents" data-leader_id='${leader}' data-agents_id=${agents_id.join(',')} data-materials='${materials}' data-workers_needed='${workers_needed}' data-method='edit_new' class="dropdown-item working_day-edit-${moment(date).format("DD-MM-YYYY")}">Modifier</a>
                            <a href="javascript:void(0)" id='btn-remove-working_day-${day}' data-working_day_id='${planningAgents.length}' class="dropdown-item">Supprimer</a>
                            <a href="javascript:void(0)" id='btn-duplicate-working_day-${day}' data-working_day='${moment(date).format("DD/MM/YYYY")}' class="dropdown-item">Dupliquer</a>
                        </div>
                    </div>`

                    let leaders_name = []
                    for (var i = 0; i <= $("#worksite-planning-leader").select2("data").length-1; i++) {
                        leaders_name.push($("#worksite-planning-leader").select2("data")[i].text)
                    }
                    
                    let newRow = tablePlanning.row.add( [
                        moment(date).format("LL"),
                        date,
                        leaders_name.join(', '),
                        listAgentNames.join(' '),
                        needs,
                        remark,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `working_day-` + day);
                    $(newRow).attr("class", `working_day`);
                    $(newRow).attr("data-date", `${moment(date).format("DD/MM/YYYY")}`);
                    $(newRow).attr("data-leader_id", leader);
                    $(newRow).data("leader_id", leader);
                    $(newRow).attr("data-agents_id", agents_id.join(','));
                    $(newRow).attr("data-drivers_id", drivers_id.join(','));
                    $(newRow).attr("data-leaders_id", leaders_id.join(','));
                    $(newRow).attr("data-materials", materials);
                    $(newRow).attr("data-workers_needed", workers_needed);
                    
                    let codePhotoArr = []
                    if ($(newRow).attr("data-code_photo") != undefined) {
                        codePhotoArr = $(newRow).attr("data-code_photo").split(',')
                        codePhotoArr.push(codePhotoPlanning)
                    }
                    else {
                        codePhotoArr.push(codePhotoPlanning)
                    }
                    $(newRow).attr("data-code_photo", codePhotoArr.join(','));

                    // delete
                    $(`#btn-remove-working_day-${day}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer cette date ? "${moment(date).format("LL")}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                tablePlanning.row($(`#working_day-${day}`)).remove().draw();
                            }
                        });
                    })

                    // duplicate
                    $(`#btn-duplicate-working_day-${day}`).on("click", function() {
                        //planning_id_duplicate = val.id
                        working_day_duplicate = day

                        //$("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                        $('#modal-planning-duplicate').modal({
                            backdrop: 'static',
                            keyboard: false  
                        })
                    })
                }
                else {
                    let leader = $("#worksite-planning-leader").val()
                    let drivers_id = []
                    let leaders_id = []

                    Array.prototype.forEach.call(document.getElementsByClassName('list_agent selected'), function(el) {
                        let one_agent = {
                            id: $(el).attr('data-id'),
                            name: $(`#list_agent-` + $(el).attr('data-id') + " td:nth-child(2)").text(),
                            email: $(`#list_agent-` + $(el).attr('data-id') + " td:nth-child(4)").text(),
                            phone: $(`#list_agent-` + $(el).attr('data-id') + " td:nth-child(3)").text(),
                            leader: $(`.agent-leader-${$(el).attr('data-id')}`).prop("checked"),
                            driver: $(`.agent-driver-${$(el).attr('data-id')}`).prop("checked"),
                            needs: needs,
                            remark: remark
                        }
                        
                        agents_id.push(one_agent.id)
                        one_agent.driver ? drivers_id.push(one_agent.id) : ''
                        one_agent.leader ? leaders_id.push(one_agent.id) : ''

                        listAgents += `-${one_agent.name}<br> `
                        listAgentNames.push(one_agent.name)
                    })
                    
                    listAgentNames = listAgentNames.map(name => `<span class='label'>${name}</span>`)

                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).data("leader_id", leader)
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).data("agents_id", agents_id.join(','))
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).attr("data-agents_id", agents_id.join(','))
                    let leaders_name = []
                    for (var i = 0; i <= $("#worksite-planning-leader").select2("data").length-1; i++) {
                        leaders_name.push($("#worksite-planning-leader").select2("data")[i].text)
                    }
                    $(`#working_day-${day} td:nth-child(2)`).text(leaders_name.join(', '));
                    $(`#working_day-${day}`).data("leader_id", leader)
                    $(`#working_day-${day}`).attr("data-leader_id", leader)
                    $(`#working_day-${day}`).data("agents_id", agents_id.join(','))
                    $(`#working_day-${day}`).attr("data-agents_id", agents_id.join(','))
                    $(`#working_day-${day}`).attr("data-drivers_id", drivers_id.join(','))
                    $(`#working_day-${day}`).attr("data-leaders_id", leaders_id.join(','))
                    $(`#working_day-${day}`).attr("data-materials", materials)
                    $(`#working_day-${day}`).data("workers_needed", workers_needed)
                    $(`#working_day-${day}`).attr("data-workers_needed", workers_needed)
                    $(`#working_day-${day} td:nth-child(3)`).html(listAgentNames.join(' '))
                    $(`#working_day-${day} td:nth-child(4)`).html(needs)
                    $(`#working_day-${day} td:nth-child(5)`).html(remark)
                    
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).data("type", 'edit')
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).attr("data-type", 'edit')
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).attr("data-materials", materials)
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).attr("data-workers_needed", workers_needed)
                    $(`.working_day-edit-${moment(date).format("DD-MM-YYYY")}`).data("workers_needed", workers_needed)

                    let codePhotoArr = []
                    if ($(`#working_day-${day}`).attr("data-code_photo") != undefined) {
                        codePhotoArr = $(`#working_day-${day}`).attr("data-code_photo").split(',')
                        codePhotoArr.push(codePhotoPlanning)
                    }
                    else {
                        codePhotoArr.push(codePhotoPlanning)
                    }

                    $(`#working_day-${day}`).attr("data-code_photo", codePhotoArr.join(','));
                    $(`#working_day-${day}`).data("code_photo", codePhotoArr.join(','));
                }

                $("#modal-list_agents").modal("hide")
            }
        })

        // add agent regular
        $("#btn-save-agent_regular").on("click", function() {
            $(".alert-agent").addClass("hidden")
            $("#form-agent_regular").validate()

            $('#form-agent_regular').submit(function(e){
                e.preventDefault()
            })

            if (document.getElementsByClassName('list_agent_regular selected').length == 0) {
                $(".alert-agent").removeClass("hidden")
                $(".alert-agent").text("Veuillez sélectionner des agents!")
                return
            }

            if ($("#form-agent_regular").valid()) {
                tableListAgentsRegular.search('').draw();

                let date_start = $("#worksite-planning_regular-date_start").datepicker("getDate")
                let day_start = moment(date_start).format("DD-MM-YYYY")
                let date_end = $("#worksite-planning_regular-date_end").datepicker("getDate")
                let day_end = moment(date_end).format("DD-MM-YYYY")
                let needs = $("#worksite-planning_regular-needs").val()
                let remark = $("#worksite-planning_regular-remark").val()
                let mission = $("#planning_regular-mission option:selected").text()
                let agents = [];
                let agents_id = [];
                let listAgents = "";
                let listAgentNames = [];
                let materials = $("#worksite-planning_regular-materials").val()

                //(date2.year * 12 + date2.month) - (date1.year * 12 + date1.month)
                let year1 = moment(date_start).format('YYYY')
                let month1 = moment(date_start).format('MM')
                let year2 = moment(date_end).format('YYYY')
                let month2 = moment(date_end).format('MM')

                let total_month = (parseInt(year2) * 12 + parseInt(month2)) - (parseInt(year1) * 12 + parseInt(month1))
                let date = `${moment(date_start).format('DD-MM-YYYY')} - ${moment(date_start).endOf('month').format('DD-MM-YYYY')}`
                let next_date = date_start

                if(methodAgent == 'new') {
    
                    Array.prototype.forEach.call(document.getElementsByClassName('list_agent_regular selected'), function(el) {

                        let one_agent = {
                            id: $(el).attr('data-id'),
                            name: $(`#list_agent_regular-` + $(el).attr('data-id') + " td:nth-child(2)").text(),
                        }

                        agents_id.push(one_agent.id)
                        agents.push(one_agent)

                        listAgents += `-${one_agent.name}<br> `
                        listAgentNames.push(one_agent.name)

                    })

                    listAgentNames = listAgentNames.map(name => `<span class='label'>${name}</span>`)

                    planningAgentsRegular.push(agents)

                    for (let m = moment(date_start); m.isBefore(date_end); m.add(1, 'months').startOf('month')) {

                        let day_str = m.format('LL')
                        let day_start_id = m.format("DD-MM-YYYY")
                        let d_end = ''
                        let d_r_end = ''
                        if (parseInt(m.format('MM')) === parseInt(moment(date_end).format('MM'))) {
                            d_end = moment(date_end).format('LL')
                            d_r_end = moment(date_end).format("DD-MM-YYYY")
                        }
                        else {
                            d_end = m.endOf('month').format('LL');
                            d_r_end = m.endOf('month').format("DD-MM-YYYY")
                        }

                        let options = `<div class="dropdown">
                            <a id="card-settings-${day_start}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-working_day_regular_start='${day_start_id}' data-working_day_regular_end='${d_r_end}' data-toggle="modal" data-target="#modal-planning_regular" data-agents_regular_id=${agents_id.join(',')} data-materials='${materials}' data-method='edit_new' class="dropdown-item working_day_regular-edit-${m.format('DD-MM-YYYY')}">Modifier</a>
                                <a href="javascript:void(0)" id='btn-remove-working_day_start-${day_start_id}' data-working_day_id='${planningAgentsRegular.length}' data-day_start_id='${m.format('DD-MM-YYYY')}' class="dropdown-item">Supprimer</a>
                                <a href="javascript:void(0)" id='btn-duplicate-working_day_regular-${m.format('DD-MM-YYYY')}' data-working_day='${m.format('DD-MM-YYYY')}' class="dropdown-item">Dupliquer</a>
                            </div>
                        </div>`

                        let newRow = tablePlanningRegular.row.add( [
                            day_str,
                            d_end,
                            listAgentNames.join(' '),
                            needs,
                            remark,
                            mission,
                            options
                        ] ).draw( false ).node();

                        $(newRow).attr("id", `working_day_regular-${day_start_id}`);
                        $(newRow).attr("class", `working_day_regular`);
                        $(newRow).attr("data-date_start", `${day_start_id}`);
                        $(newRow).attr("data-date_end", `${d_r_end}`);
                        $(newRow).attr("data-needs", needs);
                        $(newRow).attr("data-agents_regular_id", agents_id.join(','));
                        $(newRow).attr("data-category_id", $("#planning_regular-mission").val());
                        $(newRow).attr("data-materials", materials);
                        let codePhotoArr = []
                        if ($(newRow).attr("data-code_photo") != undefined) {
                            codePhotoArr = $(newRow).attr("data-code_photo").split(',')
                            codePhotoArr.push(codePhotoPlanningRegular)
                        }
                        else {
                            codePhotoArr.push(codePhotoPlanningRegular)
                        }
                        $(newRow).attr("data-code_photo", codePhotoArr.join(','));
                        
                        // delete
                        $(`#btn-remove-working_day_start-${m.format('DD-MM-YYYY')}`).on("click", function(e) {
                            Swal.fire({
                                title: "Suppression",
                                icon: "warning",
                                text: `Voulez-vous vraiment supprimer ce planning ? "${day_str} au ${d_end}"?`,
                                showCancelButton: true,
                                cancelButtonText: 'Annuler',
                                confirmButtonText: 'Oui',
                            }).then(result => {
                                if (result.isConfirmed) {
                                    tablePlanningRegular.row($(`#working_day_regular-${moment(day_str).format("DD-MM-YYYY")}`)).remove().draw();
                                }
                            });
                        })
    
                        // duplicate
                        $(`#btn-duplicate-working_day_regular-${day_str}`).on("click", function() {

                            working_day_duplicate_regular = day_str
    
                            $('#modal-planning-duplicate_regular').modal({
                                backdrop: 'static',
                                keyboard: false  
                            })
                        })
                    }
                }
                else {

                    Array.prototype.forEach.call(document.getElementsByClassName('list_agent_regular selected'), function(el) {
                        let one_agent = {
                            id: $(el).attr('data-id'),
                            name: $(`#list_agent_regular-` + $(el).attr('data-id') + " td:nth-child(2)").text(),
                            email: $(`#list_agent_regular-` + $(el).attr('data-id') + " td:nth-child(4)").text(),
                            phone: $(`#list_agent_regular-` + $(el).attr('data-id') + " td:nth-child(3)").text(),
                        }
                        
                        agents_id.push(one_agent.id)

                        listAgents += `-${one_agent.name}<br> `
                        listAgentNames.push(one_agent.name)
                    })
                    
                    listAgentNames = listAgentNames.map(name => `<span class='label'>${name}</span>`)
                    
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).data("agents_regular_id", agents_id.join(','))
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).attr("data-agents_regular_id", agents_id.join(','))
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).data("category_id", $("#planning_regular-mission").val())
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).attr("data-category_id", $("#planning_regular-mission").val())
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).attr("data-materials", materials)

                    if (planning_regular_id != undefined) {
                        $(`#${planning_regular_id}-working_day_regular-${day_start}`).data("agents_regular_id", agents_id.join(','))
                        $(`#${planning_regular_id}-working_day_regular-${day_start}`).attr("data-agents_regular_id", agents_id.join(','))
                        $(`#${planning_regular_id}-working_day_regular-${day_start}`).data("category_id", $("#planning_regular-mission").val())
                        $(`#${planning_regular_id}-working_day_regular-${day_start}`).attr("data-category_id", $("#planning_regular-mission").val())
                        $(`#${planning_regular_id}-working_day_regular-${day_start}`).attr("data-materials", materials)

                        $(`#${planning_regular_id}-working_day_regular-${day_start} td:nth-child(3)`).html(listAgentNames.join(' '))
                        $(`#${planning_regular_id}-working_day_regular-${day_start} td:nth-child(4)`).text($("#worksite-planning_regular-needs").val())
                        $(`#${planning_regular_id}-working_day_regular-${day_start} td:nth-child(5)`).text($("#worksite-planning_regular-remark").val())
                        $(`#${planning_regular_id}-working_day_regular-${day_start} td:nth-child(6)`).text($("#planning_regular-mission option:selected").text())
                    }
                    else {
                        $(`#working_day_regular-${day_start}`).data("agents_regular_id", agents_id.join(','))
                        $(`#working_day_regular-${day_start}`).attr("data-agents_regular_id", agents_id.join(','))
                        $(`#working_day_regular-${day_start}`).data("category_id", $("#planning_regular-mission").val())
                        $(`#working_day_regular-${day_start}`).attr("data-category_id", $("#planning_regular-mission").val())
                        $(`#working_day_regular-${day_start}`).attr("data-materials", $("#worksite-planning_regular-materials").val())

                        $(`#working_day_regular-${day_start} td:nth-child(3)`).html(listAgentNames.join(' '))
                        $(`#working_day_regular-${day_start} td:nth-child(4)`).text($("#worksite-planning_regular-needs").val())
                        $(`#working_day_regular-${day_start} td:nth-child(5)`).text($("#worksite-planning_regular-remark").val())
                        $(`#working_day_regular-${day_start} td:nth-child(6)`).text($("#planning_regular-mission option:selected").text())
                    }
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).data("type", 'edit')
                    $(`.working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}`).attr("data-type", 'edit')
                    
                    let codePhotoArr = []
                    if ($(`#${planning_regular_id}-working_day_regular-${day_start}`).attr("data-code_photo") != undefined) {
                        codePhotoArr = $(`#working_day_regular-${day_start}`).attr("data-code_photo").split(',')
                        codePhotoArr.push(codePhotoPlanningRegular)
                    }
                    else {
                        codePhotoArr.push(codePhotoPlanningRegular)
                    }

                    $(`#${planning_regular_id}-working_day_regular-${day_start}`).attr("data-code_photo", codePhotoArr.join(','));
                    $(`#${planning_regular_id}-working_day_regular-${day_start}`).data("code_photo", codePhotoArr.join(','));

                }

                $("#modal-planning_regular").modal("hide")
            }

           // console.log(moment($("#worksite-planning_regular-date_start").val()).format('MM-DD-YYYY'))
           
            
            $("#worksite-planning_regular-needs").val("")
            $("#worksite-planning_regular-remark").val("")
            $("#worksite-planning_regular-materials").val(null).trigger('change');
        })

        // add material
        $("#btn-save-worksite-material").on("click", function() {
            $('#form-material').submit(function(e){
                e.preventDefault()
            })

            tableMaterials.rows().clear().draw();

            jQuery.each(selectedMaterials, function (i, val) {
                let options = `<div class="dropdown">
                            <a id="card-settings-${val.material_id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" id='btn-remove-material-${val.material_id}' data-material_id='${val.material_id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`

                let newRow = tableMaterials.row.add( [
                    val.name,
                    val.type_material,
                    val.region,
                    options
                ] ).draw( false ).node();
    
                $(newRow).attr("id", `material-` + val.material_id);

                $(`#btn-remove-material-${val.material_id}`).on("click", function() {
                
                    for(let i=0 ;i < selectedMaterials.length ; i++) {
                        if (selectedMaterials[i].material_id == $(this).data('material_id')) {
                            selectedMaterials.splice(i, 1)

                            tableMaterials.row($(`#material-` + $(this).data('material_id'))).remove().draw();
                        }
                    }
                })
            })
            
            $("#modal-list_materials").modal("hide")
        })
        
        // add material rent
        $("#btn-save-material_rent").on("click", function() {
            $("#form-material_rent").validate()
            $('#form-material_rent').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-material_rent").valid()) {
                if (methodMaterialRent == 'new') {
                    let new_material_rent_id;

                    if (selectedMaterialRents.length > 0) {
                        new_material_rent_id = selectedMaterialRents.at(-1).id + 1
                    }
                    else {
                        new_material_rent_id = 1
                    }

                    let one_material_rent = {
                        id: new_material_rent_id,
                        name: $("#worksite-material_rent-name").val(),
                        quantity: $("#worksite-material_rent-quantity").val(),
                        date_start: moment($("#datepicker-material_rent input[name='start']").datepicker('getDate')).format('YYYY-MM-DD'),
                        date_end: moment($("#datepicker-material_rent input[name='end']").datepicker('getDate')).format('YYYY-MM-DD'),
                        price: $("#worksite-material_rent-price").val(),
                        method: 'new'
                    };

                    selectedMaterialRents.push(one_material_rent)

                    let options = `<div class="dropdown">
                                    <a id="card-settings-${one_material_rent.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-material_rent_id='${one_material_rent.id}' data-toggle="modal" data-target="#modal-material_rent" data-method='edit_new' class="dropdown-item">Modifier</a>
                                        <a href="javascript:void(0)" id='btn-remove-material_rent-${one_material_rent.id}' data-material_rent_id='${one_material_rent.id}' class="dropdown-item">Supprimer</a>
                                    </div>
                                </div>`
                                //<a href="javascript:void(0)" data-material_rent_id='${one_material_rent.id}' data-toggle="modal" data-target="#modal-material_rent" data-method='edit_new' class="dropdown-item">Modifier</a>
                    let newRow = tableMaterialsRent.row.add( [
                        $("#worksite-material_rent-name").val(),
                        $("#worksite-material_rent-quantity").val(),
                        $("#worksite-material_rent-price").val(),
                        moment($("#datepicker-material_rent input[name='start']").datepicker("getDate")).format("LL"),
                        moment($("#datepicker-material_rent input[name='end']").datepicker("getDate")).format("LL"),
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `material_rent-` + one_material_rent.id);

                    $("#modal-material_rent").modal("hide")

                    $(`#btn-remove-material_rent-${one_material_rent.id}`).on("click", function() {
                        tableMaterialsRent.row($(`#material_rent-` + one_material_rent.id)).remove().draw();

                        for(let i=0 ;i < selectedMaterialRents.length ; i++) {
                            if (selectedMaterialRents[i].id == one_material_rent.id) {
                                selectedMaterialRents.splice(i, 1);
                            }
                        }
                    })
                }
                else {
                    $(`#material_rent-` + material_rent_id + " td:nth-child(1)").text($("#worksite-material_rent-name").val())
                    $(`#material_rent-` + material_rent_id + " td:nth-child(2)").text($("#worksite-material_rent-quantity").val())
                    $(`#material_rent-` + material_rent_id + " td:nth-child(3)").text($("#worksite-material_rent-price").val())
                    $(`#material_rent-` + material_rent_id + " td:nth-child(4)").text($("#datepicker-material_rent input[name='start']").val())
                    $(`#material_rent-` + material_rent_id + " td:nth-child(5)").text($("#datepicker-material_rent input[name='end']").val())
                    
                    for(let i=0 ;i < selectedMaterialRents.length ; i++) {
                        if (selectedMaterialRents[i].id == material_rent_id) {
                            selectedMaterialRents[i].name = $("#worksite-material_rent-name").val()
                            selectedMaterialRents[i].quantity = $("#worksite-material_rent-quantity").val()
                            selectedMaterialRents[i].price = $("#worksite-material_rent-price").val()
                            selectedMaterialRents[i].date_start = $("#datepicker-material_rent input[name='start']").val()
                            selectedMaterialRents[i].date_end = $("#datepicker-material_rent input[name='end']").val(),
                            selectedMaterialRents[i].method = 'edit'
                        }
                    }

                    $("#modal-material_rent").modal("hide")
                }
            }
        })

        // add material sell
        $("#btn-save-material_sell").on("click", function() {
            $("#form-material_sell").validate()
            $('#form-material_sell').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-material_sell").valid()) {
                if (methodMaterialSell == 'new') {
                    let new_material_sell_id;

                    if (selectedMaterialSells.length > 0) {
                        new_material_sell_id = selectedMaterialSells.at(-1).id + 1
                    }
                    else {
                        new_material_sell_id = 1
                    }

                    let one_material_sell = {
                        id: new_material_sell_id,
                        name: $("#worksite-material_sell-name").val(),
                        quantity: $("#worksite-material_sell-quantity").val(),
                        price: $("#worksite-material_sell-price").val(),
                        method: 'new'
                    };

                    selectedMaterialSells.push(one_material_sell)

                    let options = `<div class="dropdown">
                                    <a id="card-settings-${one_material_sell.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-material_sell_id='${one_material_sell.id}' data-toggle="modal" data-target="#modal-material_sell" data-method='edit_new' class="dropdown-item">Modifier</a>
                                        <a href="javascript:void(0)" id='btn-remove-material_sell-${one_material_sell.id}' data-material_sell_id='${one_material_sell.id}' class="dropdown-item">Supprimer</a>
                                    </div>
                                </div>`

                    let newRow = tableMaterialsSell.row.add( [
                        $("#worksite-material_sell-name").val(),
                        $("#worksite-material_sell-quantity").val(),
                        $("#worksite-material_sell-price").val(),
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `material_sell-` + one_material_sell.id);

                    $("#modal-material_sell").modal("hide")

                    $(`#btn-remove-material_sell-${one_material_sell.id}`).on("click", function() {
                        tableMaterialsSell.row($(`#material_sell-` + one_material_sell.id)).remove().draw();
                        
                        for(let i=0 ;i < selectedMaterialSells.length ; i++) {
                            if (selectedMaterialSells[i].id == one_material_sell.id) {
                                selectedMaterialSells.splice(i, 1);
                            }
                        }
                    })
                }
                else {
                    $(`#material_sell-` + material_sell_id + " td:nth-child(1)").text($("#worksite-material_sell-name").val())
                    $(`#material_sell-` + material_sell_id + " td:nth-child(2)").text($("#worksite-material_sell-quantity").val())
                    $(`#material_sell-` + material_sell_id + " td:nth-child(3)").text($("#worksite-material_sell-price").val())
                    
                    for(let i=0 ;i < selectedMaterialSells.length ; i++) {
                        if (selectedMaterialSells[i].id == material_sell_id) {
                            selectedMaterialSells[i].name = $("#worksite-material_sell-name").val()
                            selectedMaterialSells[i].quantity = $("#worksite-material_sell-quantity").val()
                            selectedMaterialSells[i].price = $("#worksite-material_sell-price").val(),
                            selectedMaterialSells[i].method = 'edit'
                        }
                    }

                    $("#modal-material_sell").modal("hide")
                }
            }
        })

        // btn save worksite
        $("#btn-save-worksite").on("click", function() {
            let has_errors = false;
            $("#form-worksite").validate()
            $('#form-worksite').submit(function(e){
                e.preventDefault()
            })
            
            $(".alert-worksite-info").addClass("hidden")
            $(".alert-worksite-materials").addClass("hidden")
            $(".alert-worksite-team").addClass("hidden")
            $(".alert-worksite-photos").addClass("hidden")
            $(".alert-worksite-info").text("")

            if (tableContacts.rows().count() == 0) {
                $(".alert-worksite-info").removeClass("hidden")
                $(".alert-worksite-info").text("Veuillez ajouter un contact client!")
                $(".tab-info").addClass("onglet_error")

                has_errors = true
            }
            
            if (tableMaterials.rows().count() == 0) {
                $(".alert-worksite-materials").removeClass("hidden")
                $(".alert-worksite-materials").text("Veuillez remplir la liste de matériels mis à disposition!")
                $(".tab-info").addClass("onglet_error")

                has_errors = true
            }

            // if (tablePlanning.rows().count() == 0) {
            //     $(".alert-worksite-team").removeClass("hidden")
            //     $(".alert-worksite-team").text("Veuillez remplir le planning!")
            //     $(".tab-info").addClass("onglet_error")

            //     has_errors = true
            // }

            Array.prototype.forEach.call(document.getElementsByClassName('dz-preview'), function(item) {
                if(!$(item).hasClass('dz-complete')) {
                
                    $(".nav-item").removeClass("active")
                    $(".tab-photos").addClass("active")
                    $(".tab-pane").removeClass("active")
                    $("#tab-photos").addClass("active")

                    $(".alert-worksite-photos").removeClass("hidden")
                    $(".alert-worksite-photos").text("Veuillez uploader les nouveaux photos!")

                    has_errors = true;
                    return false;
                }
            })

            if (has_errors) {
                return
            }

            
            if ($("#form-worksite").valid()) {
                disableFields(true)

                if (method == "new") {
                    create()
                }
                else {
                    update()
                }
            }
        })

        // duplicate worksite
        $("#btn-worksite-duplicate").on("click", function(e) {
            $("#form-duplicate").validate()
            $('#form-duplicate').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-duplicate").valid()) {
                $('.alert-duplicate').addClass("hidden")
                $("#btn-worksite-duplicate").attr("disabled", true)
                $("#worksite-duplicate-category").attr("disabled", true)

                $("#modal-worksite-duplicate .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")

                let url;

                url = `${HOST_API}/worksites/${worksite_id_duplicate}/duplicate`
                
                $.ajax({
                    url: url,
                    type: 'POST',
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                    },
                    data: {
                        name: $("#worksite-duplicate-name").val(),
                        category_id: $('#worksite-duplicate-category').val()
                    },
                    dataType: "json",
                    success: function (data) {
                        tableWorksites.ajax.reload()

                        // let options = `<div class="dropdown">
                        //             <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                        //                 <i class="card-icon card-icon-settings "></i>
                        //             </a>
                        //             <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                        //                 <a href="javascript:void(0)" id='btn-edit-worksite-${data.id}' data-worksite_id='${data.id}' data-toggle="modal" data-target="#modal-worksite" data-method='edit' backdrop="static" keyboard="false" class="dropdown-item">Modifier</a>
                        //                 <a href="javascript:void(0)" class='dropdown-item btn-duplicate-worksite-${data.id}' data-worksite_id='${data.id}'>Dupliquer</a>
                        //                 <a href="javascript:void(0)" id='btn-delete-worksite-${data.id}' class="dropdown-item">Supprimer</a>
                        //             </div>
                        //         </div>`
                        // let newRow = tableWorksites.row.add( [
                        //     `<a href='/worksites/${data.id}' title='Voir le chantier en détail'>${data.name}</a>`,
                        //     '',
                        //     data.address,
                        //     '',
                        //     '',
                        //     options
                        // ] ).draw( false ).node();

                        // $(newRow).attr("id", `worksite-` + data.id);

                        // $(`#btn-edit-worksite-${data.id}`).on("click", function (e) {
                        //     method = 'edit'
                        //     if($(e.target).data('worksite_id') == undefined)
                        //         worksite_id = $(e.currentTarget).data('worksite_id')
                        //     else
                        //         worksite_id = $(e.target).data('worksite_id')
                            
                        //     $('#modal-worksite').modal({
                        //         backdrop: 'static',
                        //         keyboard: false  
                        //     })
                        // });
                        
                        // $(`#btn-delete-worksite-${data.id}`).on("click", function() {
                        //     Swal.fire({
                        //         title: "Suppression",
                        //         icon: "warning",
                        //         text: `Voulez-vous vraiment supprimer ce chantier "${data.name}"?`,
                        //         showCancelButton: true,
                        //         cancelButtonText: 'Annuler',
                        //         confirmButtonText: 'Oui',
                        //     }).then(result => {
                        //         if (result.isConfirmed) {
                        //             destroy(data.id)
                        //         }
                        //     });
                        // })

                        // $(`.btn-duplicate-worksite-${data.id}`).on("click", function(e) {
                        //     worksite_id_duplicate = $(e.currentTarget).data('worksite_id')
                        //     $("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                        //     $('#modal-worksite-duplicate').modal({
                        //         backdrop: 'static',
                        //         keyboard: false  
                        //     })
                        // })

                        Swal.fire(
                            'Chantier!',
                            'Nouveau chantier crée!',
                            'success'
                        )

                        $("#modal-worksite-duplicate").modal("hide")

                        $("#btn-worksite-duplicate").attr("disabled", false)
                        $("#worksite-duplicate-category").attr("disabled", false)
        
                        $("#modal-worksite-duplicate .close").attr("data-dismiss", "modal")
                        $(".progress").addClass("hidden")
                    },
                    error: function(data) {
                        $('.alert-duplicate').removeClass("hidden")
                        $('.alert-duplicate').text(data.responseJSON.error)
                        $("#worksite-duplicate-name").attr("disabled", false)
                        $("#worksite-duplicate-category").attr("disabled", false)
                        $("#btn-worksite-duplicate").attr("disabled", false)
                        $("#modal-worksite-duplicate .close").attr("data-dismiss", "modal")
                        $(".progress").addClass("hidden")
                    }
                })
            }
        })


        //duplicate planning
        $("#btn-planning-duplicate").on("click", function(e) {
            $("#form-duplicate-planning").validate()
            $('#form-duplicate-planning').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-duplicate-planning").valid()) {
                $("#btn-planning-duplicate").attr("disabled", true)

                tableListAgents.search('').draw();

                let date = $("#planning-duplicate-date").datepicker("getDate")
                let day = moment(date).format("DD-MM-YYYY")
                let leader = $("#planning-duplicate-leader").val()
                let agents = [];
                let agents_id = [];
                let listAgents = "";
                let listAgentNames = [];
                let drivers = [];
                let leaders_id = [];
                let drivers_id = [];
                let ids = [];
                let needs = '';
                let remark = '';
                let materials = []

                //ids = $(`#table-worksite-planning tr[data-planning_id=${planning_id_duplicate}]`).data("agents_id").split(',')

                if ($(`#working_day-${working_day_duplicate}`).data("agents_id").toString().includes(','))
                    ids = $(`#working_day-${working_day_duplicate}`).data("agents_id").split(',')
                else 
                    ids.push($(`#working_day-${working_day_duplicate}`).data("agents_id"))

                if ($(`#working_day-${working_day_duplicate}`).data("leaders_id").toString().includes(','))
                    leaders_id = $(`#working_day-${working_day_duplicate}`).data("leaders_id").split(',')
                else 
                    leaders_id.push($(`#working_day-${working_day_duplicate}`).data("leaders_id"))

                if ($(`#working_day-${working_day_duplicate}`).data("drivers_id").toString().includes(','))
                    drivers_id = $(`#working_day-${working_day_duplicate}`).data("drivers_id").split(',')
                else 
                    drivers_id.push($(`#working_day-${working_day_duplicate}`).data("drivers_id"))

                if ($(`#working_day-${working_day_duplicate}`).data("materials").toString().includes(','))
                    materials = $(`#working_day-${working_day_duplicate}`).data("materials").split(',')
                else 
                    materials.push($(`#working_day-${working_day_duplicate}`).data("materials"))

                jQuery.each(ids, function (i, val) {
                    let one_agent = {
                        id: val,
                        name: '',
                        leader: leaders_id.includes(val),
                        driver: drivers_id.includes(val)
                    }

                    agents_id.push(one_agent.id)
                    agents.push(one_agent)
                })

                planningAgents.push(agents)

                let options = `<div class="dropdown">
                    <a id="card-settings-${day}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                    <i class="card-icon card-icon-settings "></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                        <a href="javascript:void(0)" data-working_day='${moment(date).format("DD/MM/YYYY")}' data-toggle="modal" data-target="#modal-list_agents" data-leader_id='${leader}' data-agents_id='${ids.join(',')}' data-materials='${materials.join(',')}' data-method='edit_new' class="dropdown-item working_day-edit-${moment(date).format("DD-MM-YYYY")}">Modifier</a>
                        <a href="javascript:void(0)" id='btn-remove-working_day-${day}' data-working_day_id='${planningAgents.length}' class="dropdown-item">Supprimer</a>
                        <a href="javascript:void(0)" id='btn-duplicate-working_day-${day}' class="dropdown-item">Dupliquer</a>
                    </div>
                </div>`

                let newRow = tablePlanning.row.add( [
                    moment(date).format("LL"),
                    date,
                    $("#planning-duplicate-leader option:selected").text(),
                    $(`#working_day-${working_day_duplicate} td:nth-child(3)`).html(),
                    $(`#working_day-${working_day_duplicate} td:nth-child(4)`).html(),
                    $(`#working_day-${working_day_duplicate} td:nth-child(5)`).html(),
                    options
                ] ).draw( false ).node();

                $(newRow).attr("id", `working_day-` + day);
                $(newRow).attr("class", `working_day`);
                $(newRow).attr("data-date", `${moment(date).format("DD/MM/YYYY")}`);
                $(newRow).attr("data-leader_id", leader);
                $(newRow).data("leader_id", leader);
                $(newRow).attr("data-agents_id", ids.join(','));
                $(newRow).attr("data-drivers_id", drivers_id.join(','));
                $(newRow).attr("data-leaders_id", leaders_id.join(','));
                $(newRow).attr("data-materials", materials.join(','));

                // delete
                $(`#btn-remove-working_day-${day}`).on("click", function() {
                    Swal.fire({
                        title: "Suppression",
                        icon: "warning",
                        text: `Voulez-vous vraiment supprimer cette date ? "${moment(date).format("LL")}"?`,
                        showCancelButton: true,
                        cancelButtonText: 'Annuler',
                        confirmButtonText: 'Oui',
                    }).then(result => {
                        if (result.isConfirmed) {
                            tablePlanning.row($(`#working_day-${day}`)).remove().draw();
                        }
                    });
                })

                // duplicate
                $(`#btn-duplicate-working_day-${day}`).on("click", function() {
                    //planning_id_duplicate = val.id
                    working_day_duplicate = day

                    $('#modal-planning-duplicate').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })
                })

                $("#modal-planning-duplicate").modal("hide")
                $("#btn-planning-duplicate").attr("disabled", false)
            }
        })

        //duplicate planning regular
        $("#btn-planning-duplicate_regular").on("click", function(e) {
            $("#form-duplicate-planning_regular").validate()
            $('#form-duplicate-planning_regular').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-duplicate-planning_regular").valid()) {
                $("#btn-planning-duplicate_regular").attr("disabled", true)

                tableListAgentsRegular.search('').draw();

                let date_start = $("#planning-duplicate-dateStart_regular").datepicker("getDate")
                let date_end = $("#planning-duplicate-dateEnd_regular").datepicker("getDate")
                let day = moment(date_start).format("DD-MM-YYYY")
                let agents = [];
                let agents_id = [];
                let listAgents = "";
                let listAgentNames = [];
                let ids = [];
                let needs = '';
                let remark = '';

                //ids = $(`#table-worksite-planning tr[data-planning_id=${planning_id_duplicate}]`).data("agents_id").split(',')

                if ($(`#${planning_regular_id_duplicate}-working_day_regular-${working_day_duplicate_regular}`).data("agents_regular_id").toString().includes(','))
                    ids = $(`#${planning_regular_id_duplicate}-working_day_regular-${working_day_duplicate_regular}`).data("agents_regular_id").split(',')
                else 
                    ids.push($(`#${planning_regular_id_duplicate}-working_day_regular-${working_day_duplicate_regular}`).data("agents_regular_id"))

                jQuery.each(ids, function (i, val) {
                    let one_agent = {
                        id: val,
                        name: '',
                    }

                    agents_id.push(one_agent.id)
                    agents.push(one_agent)

                })

                planningAgentsRegular.push(agents)

                let options = `<div class="dropdown">
                    <a id="card-settings-${day}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                    <i class="card-icon card-icon-settings "></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                        <a href="javascript:void(0)" data-working_day_regular_start='${moment(date_start).format("DD/MM/YYYY")}' data-working_day_regular_end='${moment(date_end).format("DD/MM/YYYY")}' data-planning_id='${planning_regular_id_duplicate}' data-toggle="modal" data-target="#modal-planning_regular" backdrop="static" keyboard="false" data-agents_regular_id='${agents_id.join(',')}' data-method='edit_new' class="dropdown-item working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}">Modifier</a>
                        <a href="javascript:void(0)" id='btn-remove-working_day_start-${day}' data-working_day_id='${planningAgents.length}' class="dropdown-item">Supprimer</a>
                        <a href="javascript:void(0)" id='btn-duplicate-working_day_regular-${day}' class="dropdown-item">Dupliquer</a>
                    </div>
                </div>`

                let newRow = tablePlanningRegular.row.add( [
                    moment(date_start).format("LL"),
                    moment(date_end).format("LL"),
                    $(`#${planning_regular_id_duplicate}-working_day_regular-${working_day_duplicate_regular} td:nth-child(3)`).html(),
                    $(`#${planning_regular_id_duplicate}-working_day_regular-${working_day_duplicate_regular} td:nth-child(4)`).html(),
                    $(`#${planning_regular_id_duplicate}-working_day_regular-${working_day_duplicate_regular} td:nth-child(5)`).html(),
                    $("#planning_regular-mission option:selected").text(),
                    options
                ] ).draw( false ).node();

                $(newRow).attr("id", `working_day_regular-` + day);
               //$(newRow).attr("data-planning_regular_id", planning_regular_id_duplicate);
                $(newRow).attr("data-agents_regular_id", agents_id.join(','));
                $(newRow).attr("class", `working_day_regular`);
                $(newRow).attr("data-date_start", `${moment(date_start).format("DD-MM-YYYY")}`);
                $(newRow).attr("data-date_end", `${moment(date_end).format("DD-MM-YYYY")}`);
                $(newRow).attr("data-category_id", $("#planning_regular-mission").val());
                

                // delete
                $(`#btn-remove-working_day_start-${day}`).on("click", function() {
                    Swal.fire({
                        title: "Suppression",
                        icon: "warning",
                        text: `Voulez-vous vraiment supprimer cette date ? "${moment(date_start).format("LL")}"?`,
                        showCancelButton: true,
                        cancelButtonText: 'Annuler',
                        confirmButtonText: 'Oui',
                    }).then(result => {
                        if (result.isConfirmed) {
                            tablePlanning.row($(`#working_day-${day}`)).remove().draw();
                        }
                    });
                })

                // duplicate
                $(`#btn-duplicate-working_day_regular-${day}`).on("click", function() {
                    //planning_id_duplicate = val.id
                    working_day_duplicate_regular = day

                    $('#modal-planning-duplicate_regular').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })
                })

                $("#modal-planning-duplicate_regular").modal("hide")
                $("#btn-planning-duplicate_regular").attr("disabled", false)
            }
        })

        // cancel worksite
        $('#btn-cancel_contact').on("click", function (e) { 
            Swal.fire({
                title: "Annulation",
                icon: "warning",
                text: `Voulez-vous vraiment annuler ce chantier "${$(".one-worksite-name").text()}"?, tous les agents seront notifiés.`,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    annulerChantier($("#worksite_id").val())
                }
            });
        });

        // get equipe
        async function getEquipe() {

            $("#table-worksite-list_agents_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            await fetch(`${HOST_API}/workers`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default' 
            }).then(function(data) {
                data.json().then(function(data) {
                    let agents;
                    let selecting;
                    tableListAgents.rows().clear().draw();
                    tableListAgentsRegular.rows().clear().draw();
                
                    if (!$("#worksite-planning-leader").data('select2')) {
                        $("#worksite-planning-leader").select2()
                    }

                    jQuery.each(data, function (i, val) {

                        if(val.role == 'team_leader' && val.active) {
                            $("#worksite-leader").append(`<option value='${val.id}'>${val.name}</option>`)

                            // add agent planning
                            $("#worksite-planning-leader").append(`<option value='${val.id}'>${val.name}</option>`)

                            //$("#planning-duplicate-leader").append(`<option value='${val.id}'>${val.name}</option>`)
                        }
                        else if((val.role == 'agent' || val.role == 'extern') && val.type_agent == "punctual" && val.active) {
                            let role;
                            if (val.role == 'agent')
                                role = 'Agent'
                            else if (val.role == 'extern')
                                role = 'Externe'
                            
                            let skills = []
                            jQuery.each(val.skill_workers, function (i, skill) {
                                skills.push(`<span class='label'>${skill.skill.name}</span>`)
                            })
                            
                            $("#worksite-agent").append(`<option value='${val.id}' data-job='${val.job}' data-role='${val.role}' data-email='${val.email}' data-phone='${val.phone}'>${val.name}</option>`)
                            agents += `<option class='agent-${val.id}' value='${val.id}' data-job='${val.job}'>${val.name}</option>`
                            
                            selecting = `<div class="form-check d-flex align-items-center justify-content-center">
                                            <input type="checkbox" value="3" class='selecting-agent' id="selecting-agent-${val.id}" disabled data-agent_id='${val.id}'>
                                            <label for="selecting-agent-${val.id}" class="no-padding no-margin"></label>
                                        </div>`
                            
                            let newRow = tableListAgents.row.add( [
                                selecting,
                                val.name,
                                role,
                                skills.join(' '),
                                '',
                                `<input type='checkbox' class='agent-leader agent-leader-${val.id}' >`,
                                `<input type='checkbox' class='agent-driver agent-driver-${val.id}' >`
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `list_agent-` + val.id);
                            $(newRow).attr("class", `list_agent`);
                            $(newRow).attr("data-id", val.id);

                            // add drivers
                            $("#worksite-driver").append(`<option value='${val.id}'>${val.name}</option>`)

                        }
                        else if(val.role == 'agent' && val.type_agent == "regular") {
                           
                            let role;
                            if (val.role == 'agent')
                                role = 'Agent'
                            
                            let skills = []
                            jQuery.each(val.skill_workers, function (i, skill) {
                                skills.push(`<span class='label'>${skill.skill.name}</span>`)
                            })

                            selecting = `<div class="form-check d-flex align-items-center justify-content-center">
                                            <input type="checkbox" value="3" class='selecting-agent' id="selecting-agent-${val.id}" data-agent_id='${val.id}'>
                                            <label for="selecting-agent-${val.id}" class="no-padding no-margin"></label>
                                        </div>`

                            let newRow = tableListAgentsRegular.row.add( [
                                selecting,
                                val.name,
                                role,
                                skills.join(' '),
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `list_agent_regular-` + val.id);
                            $(newRow).attr("class", `list_agent_regular`);
                            $(newRow).attr("data-id", val.id);
                        }
                    })
                
                    
                    // select agents punctual
                    $('#table-worksite-list_agents .list_agent .selecting-agent').click(function() {
                        if ($(this).is(':checked')) {
                            let one_agent = {
                                id: $(this).data("agent_id"),
                                name: $(`#list_agent-` + $(this).data("agent_id") + " td:nth-child(2)").text(),
                                email: $(`#list_agent-` + $(this).data("agent_id") + " td:nth-child(4)").text(),
                                phone: $(`#list_agent-` + $(this).data("agent_id") + " td:nth-child(3)").text()
                            }
                            selectedAgents.push(one_agent)

                            $(this).closest('tr').addClass('selected');
                        } else {
                            let agent_id = $(this).data("agent_id")

                            for(let i=0 ;i < selectedAgents.length ; i++) {
                                if (selectedAgents[i].id == agent_id) {
                                    selectedAgents.splice(i, 1)
                                }
                            }

                            $(this).closest('tr').removeClass('selected');
                        }
                    });

                    // select agents regular
                    $('#table-worksite-list_agents_regular .list_agent_regular .selecting-agent').click(function() {
                        if ($(this).is(':checked')) {
                            let one_agent = {
                                id: $(this).data("agent_id"),
                                name: $(`#list_agent_regular-` + $(this).data("agent_id") + " td:nth-child(2)").text(),
                                email: $(`#list_agent_regular-` + $(this).data("agent_id") + " td:nth-child(4)").text(),
                                phone: $(`#list_agent_regular-` + $(this).data("agent_id") + " td:nth-child(3)").text()
                            }
                            selectedAgentsRegular.push(one_agent)

                            $(this).closest('tr').addClass('selected');
                        } else {
                            let agent_id = $(this).data("agent_id")

                            for(let i=0 ;i < selectedAgentsRegular.length ; i++) {
                                if (selectedAgentsRegular[i].id == agent_id) {
                                    selectedAgentsRegular.splice(i, 1)
                                }
                            }

                            $(this).closest('tr').removeClass('selected');
                        }
                    });


                    // set checked agents
                    for(let i=0 ;i < agents_selected.length ; i++) {
                        $("#selecting-agent-" + agents_selected[i]).prop("checked", true)
                        $("#list_agent-" + agents_selected[i]).addClass('selected');
                    }

                });
            })
        }

        // get regular equipe
        async function getRegularTeam() {

            $("#table-worksite-list_agents_regular_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            await fetch(`${HOST_API}/workers`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default' 
            }).then(function(data) {
                data.json().then(function(data) {
                    let agents;
                    let selecting;

                    tableListAgentsRegular.rows().clear().draw();

                    jQuery.each(data, function (i, val) {

                        if(val.role == 'agent' && val.type_agent == "regular") {
                           
                            let role;
                            if (val.role == 'agent')
                                role = 'Agent'
                            
                            let skills = []
                            jQuery.each(val.skill_workers, function (i, skill) {
                                skills.push(`<span class='label'>${skill.skill.name}</span>`)
                            })

                            selecting = `<div class="form-check d-flex align-items-center justify-content-center">
                                            <input type="checkbox" value="3" class='selecting-agent' id="selecting-agent-${val.id}" data-agent_id='${val.id}'>
                                            <label for="selecting-agent-${val.id}" class="no-padding no-margin"></label>
                                        </div>`

                            let newRow = tableListAgentsRegular.row.add( [
                                selecting,
                                val.name,
                                role,
                                skills.join(' '),
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `list_agent_regular-` + val.id);
                            $(newRow).attr("class", `list_agent_regular`);
                            $(newRow).attr("data-id", val.id);
                        }
                    })
                
                    
                    // select agents regular
                    $('#table-worksite-list_agents_regular .list_agent_regular .selecting-agent').click(function() {
                        if ($(this).is(':checked')) {
                            let one_agent = {
                                id: $(this).data("agent_id"),
                                name: $(`#list_agent_regular-` + $(this).data("agent_id") + " td:nth-child(2)").text(),
                                email: $(`#list_agent_regular-` + $(this).data("agent_id") + " td:nth-child(4)").text(),
                                phone: $(`#list_agent_regular-` + $(this).data("agent_id") + " td:nth-child(3)").text()
                            }
                            selectedAgentsRegular.push(one_agent)

                            $(this).closest('tr').addClass('selected');
                        } else {
                            let agent_id = $(this).data("agent_id")

                            for(let i=0 ;i < selectedAgentsRegular.length ; i++) {
                                if (selectedAgentsRegular[i].id == agent_id) {
                                    selectedAgentsRegular.splice(i, 1)
                                }
                            }

                            $(this).closest('tr').removeClass('selected');
                        }
                    });


                    // set checked agents
                    for(let i=0 ;i < agents_selected.length ; i++) {
                        $("#selecting-agent-" + agents_selected[i]).prop("checked", true)
                        $("#list_agent-" + agents_selected[i]).addClass('selected');
                    }

                });
            })
        }

        // get type materials
        async function getTypeMaterials() {

            await fetch(`${HOST_API}/type_materials`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(data) {
                data.json().then(function(data) {
                    jQuery.each(data, function (i, val) {
                        if (val.active) {
                            $("#worksite-material-type").append(`<option value='${val.id}' data-name='${val.name}'>${val.name}</option>`)
                        }
                    })
                })
            })
        }

        // get regions
        async function getRegions() {
            await fetch(`${HOST_API}/regions`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default' 
            }).then(function(data) {
                data.json().then(function(data) {
                    jQuery.each(data, function (i, val) {
                        $("#worksite-material-region").append(`<option value='${val.id}' data-name='${val.name}'>${val.name}</option>`)

                    })
                })
            })
        }

        // get materials
        async function getMaterials() {
            await fetch(`${HOST_API}/materials`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default' 
            }).then(function(response) {
                response.json().then(function(data) {
                    let selecting;
                    jQuery.each(data, function (i, val) {
                        $("#worksite-material").append(`<option value='${val.id}' data-name='${val.job}' data-type_material='${val.type_material.id}' data-region='${val.region.id}' data-note='${val.note}'>${val.name}</option>`)
                        if (i == 0) {
                            $("#worksite-material-type").val(val.type_material.id).trigger("change")
                            $("#worksite-material-region").val(val.region.id).trigger("change")
                            $("#worksite-material-note").val(val.note)
                        }

                        selecting = `<div class="form-check d-flex align-items-center justify-content-center">
                                            <input type="checkbox" value="3" id="selecting-material-${val.id}" data-material_id='${val.id}'>
                                            <label for="selecting-material-${val.id}" class="no-padding no-margin"></label>
                                        </div>`
                        let newRow = tableListMaterials.row.add( [
                            selecting,
                            val.name,
                            val.type_material.name,
                            val.region.name
                        ] ).draw( false ).node();
                        
                        $(newRow).attr("id", `list_material-` + val.id);
                    })
                
                    jQuery.each(data, function (i, val) {
                        $("#worksite-planning_regular-materials").append(`<option value='${val.id}'>${val.name}</option>`)
                        $("#worksite-planning_regular-materials option:first").trigger("update")

                        $("#worksite-planning-materials").append(`<option value='${val.id}'>${val.name}</option>`)
                        $("#worksite-planning-materials option:first").trigger("update")
                    })
                    $("#worksite-planning_regular-materials").attr('disabled', false)
                    $("#worksite-planning-materials").attr('disabled', false)

                    // select material
                    $('#table-worksite-list_materials input[type=checkbox]').click(function() {
                        if ($(this).is(':checked')) {
                            let one_material = {
                                id: $(this).data("material_id"),
                                material_id: $(this).data("material_id"),
                                name: $(`#list_material-` + $(this).data("material_id") + " td:nth-child(2)").text(),
                                type_material: $(`#list_material-` + $(this).data("material_id") + " td:nth-child(3)").text(),
                                region: $(`#list_material-` + $(this).data("material_id") + " td:nth-child(4)").text(),
                                destroy: false
                            }
                            selectedMaterials.push(one_material)
                            
                            $(this).closest('tr').addClass('selected');
                        } else {
                            let material_id = $(this).data("material_id")

                            for(let i=0 ;i < selectedMaterials.length ; i++) {
                                if (selectedMaterials[i].material_id == material_id) {
                                    selectedMaterials.splice(i, 1)
                                }
                            }

                            $(this).closest('tr').removeClass('selected');
                        }
                    });
                })
            })
        }

        // get categories
        async function getCategories() {
            await fetch(`${HOST_API}/categories`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default' 
            }).then(function(response) {
                response.json().then(function(data) {
                    $("#worksite-category").append(`<option value=''>-</option>`)
                    $("#planning_regular-mission").append(`<option value=''>-</option>`)
                    $("#worksite-duplicate-category").append(`<option value=''>-</option>`)
                    
                    jQuery.each(data, function (i, val) {
                        $("#worksite-category, #planning_regular-mission").append(`<option value='${val.id}'>${val.name}</option>`)
                        $("#worksite-duplicate-category").append(`<option value='${val.id}'>${val.name}</option>`)
                    })

                    $("#worksite-category, #planning_regular-mission").select2()
                    $("#worksite-duplicate-category").select2()
                })
            })
        }

        // open edit planning
        $("#btn-edit-planning").on("click", function (e) {
            method = 'edit'
            showPlanningTab = true
            worksite_id = $(e.currentTarget).data("worksite_id")
            $('#modal-worksite').modal({
                backdrop: 'static',
                keyboard: false
            })

            //get($("#worksite_id").val(), 'planning')
        })

        async function get(id, tab) {

            if (showPlanningTab) {
                $("#modal-worksite .nav-item a").removeClass("active")
                $("#modal-worksite .tab-pane").removeClass("active")
                $("#modal-worksite .tab-planning").addClass("active")
                $("#modal-worksite #tab-planning").addClass("active")
            }

            let url = `${HOST_API}/worksites/all?filter_worksite_finished=${$("#filter-worksite_finished").prop("checked")}`

            if (id != null) {
                url = `${HOST_API}/worksites/${id}`
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-pulse'
                });

                $("#table-worksite-planning_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
                $("#table-worksite-contacts_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
                
            }
            else {
                $("#table-worksites_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
            }

            await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                response.json().then(function(data) {
                    if (id == undefined) {
                        tableWorksites.rows().clear().draw();
                        
                        jQuery.each(data, function (i, val) {
                            let edit_btn = ""
                            let delete_btn = ""
                            if (val.state == "in_progress" || val.state == 'canceled') {
                                edit_btn = `<a href="javascript:void(0)" id='btn-edit-worksite-${val.id}' data-worksite_id='${val.id}' data-toggle="modal" data-target="#modal-worksite" data-method='edit' backdrop="static" keyboard="false" class="dropdown-item">Modifier</a>`
                                delete_btn = `<a href="javascript:void(0)" class='btn-delete-worksite-${val.id}' data-worksite_id='${val.id}' class="dropdown-item">Supprimer</a>`
                            }
                            
                            let options = `<div class="dropdown">
                                    <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                        <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        ${edit_btn}
                                        <a href="javascript:void(0)" class='dropdown-item btn-duplicate-worksite' data-worksite_id='${val.id}'>Dupliquer</a>
                                        ${delete_btn}
                                    </div>
                                </div>`
                            
                            let newRow = tableWorksites.row.add( [
                                `<a href='/worksites/${val.id}' title='Voir le chantier en détail'>${val.name}</a>`,
                                val.category != null ? val.category.name : '',
                                val.address,
                                val.start_date != null ? moment(val.start_date).format("DD-MM-YYYY") : '',
                                val.end_date != null ? moment(val.end_date).format("DD-MM-YYYY") : '',
                                val.state != "finished" ? options : ''
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `worksite-` + val.id);

                            $(`#btn-edit-worksite-${val.id}`).on("click", function (e) {
                                method = 'edit'
                                if($(e.target).data('worksite_id') == undefined)
                                    worksite_id = $(e.currentTarget).data('worksite_id')
                                else
                                    worksite_id = $(e.target).data('worksite_id')
                                
                                $('#modal-worksite').modal({
                                    backdrop: 'static',
                                    keyboard: false  
                                })
                            });

                            if (val.rate != null) {
                                $(newRow).addClass("worksite_finished")
                            }

                            if (val.state == 'canceled') {
                                $(newRow).addClass("worksite_canceled")
                            }
                            
                            $(`.btn-delete-worksite-${val.id}`).on("click", function() {
                                Swal.fire({
                                    title: "Suppression",
                                    icon: "warning",
                                    text: `Voulez-vous vraiment supprimer ce chantier "${val.name}"?`,
                                    showCancelButton: true,
                                    cancelButtonText: 'Annuler',
                                    confirmButtonText: 'Oui',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        destroy(val.id)
                                    }
                                });
                            })

                            
                        })

                        $('.btn-duplicate-worksite').on("click", function(e) {
                            worksite_id_duplicate = $(e.currentTarget).data('worksite_id')
                           
                            $("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                            $('#modal-worksite-duplicate').modal({
                                backdrop: 'static',
                                keyboard: false  
                            })
                        })

                    }
                    else {

                        $("#table-worksite-planning_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
                        $("#table-worksite-materials_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
                        $("#table-worksite-materials_rent_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
                        $("#table-worksite-materials_sell_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
                        $("#table-worksite-contacts_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

                        disableFields(true)

                        // worksite info
                        $("#worksite-name").val(data.name)
                        $("#worksite-address").val(data.address)
                        if (data.category != null)
                            $("#worksite-category").val(data.category.id).trigger("change")
                        $("#worksite-note").val(data.note)
                        $("#worksite-latitude").val(data.lat)
                        $("#worksite-longitude").val(data.long)
                        //$("#worksite-besoin_client").val(data.besoin_client)

                        var latLng = new google.maps.LatLng(data.lat, data.long);
                        map.setCenter(latLng);
                        map.setZoom(15)

                        new google.maps.Marker({
                            position: { lat: data.lat, lng: data.long },
                            map
                        });
                        
                        // contacts
                        tableContacts.rows().clear().draw();
                        jQuery.each(data.contacts, function (i, val) {
                            let options = `<div class="dropdown">
                            <a id="card-settings-contact-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                <i class="card-icon card-icon-settings "></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                    <a href="javascript:void(0)" id='btn-delete-contact-${val.id}' class="dropdown-item">Supprimer</a>
                                </div>
                            </div>`
                        
                            let newRow = tableContacts.row.add( [
                                val.contact.name,
                                val.contact.phone,
                                val.contact.email,
                                val.contact.role,
                                options
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `contact-` + val.contact.id);

                            $(`#btn-edit-contact-${val.id}`).on("click", function (e) {
                                method_contact = 'edit'
                                contact_id = $(e.target).data('contact_id')

                                $('#modal-contact').modal({
                                    backdrop: 'static',
                                    keyboard: false  
                                })
                            });

                            let new_contact = {
                                id: val.contact.id,
                                name: val.name,
                                phone: val.phone,
                                email: val.email,
                                role: val.role,
                                destroy: false
                            }
        
                            selectedContacts.push(new_contact)
                            
                            $(`#btn-delete-contact-${val.id}`).on("click", function() {
                                selectedContactsDeleted.push(val.id)
                                tableContacts.row($(`#contact-` + val.contact.id)).remove().draw();
                            });
                        });

                        // planning punctual
                        
                        tablePlanning.rows().clear().draw();
                        jQuery.each(data.planning, function (i, val) {
                            let date = val.date
                            let day = moment(date).format("DD-MM-YYYY")
                            //let leader = val.leader.id
                            let leader = val.planning_leaders.map(lead => lead.worker_id).join(',')
                            let agents_id = [];
                            let listAgents = "";
                            let listAgentNames = [];
                            let drivers = [];
                            let leaders = [];
                            let suppr = "";
                            let materials = [];

                            jQuery.each(val.planning_workers, function (i, worker) {
                                agents_id.push(worker.worker.id)
                                listAgents += `-${worker.worker.name}<br> `
                                listAgentNames.push(worker.worker.name)
                                worker.driver ? drivers.push(worker.worker.id) : ''
                                worker.leader ? leaders.push(worker.worker.id) : ''
                            })
     
                            jQuery.each(val.planning_materials, function (i, mat) {
                                materials.push(mat.material_id)
                            })

                            listAgentNames = listAgentNames.map(name => `<span class='label'>${name}</span>`)

                            //if (val.pointing_status == 'all_not_pointed') {
                                suppr = `<a href="javascript:void(0)" id='btn-remove-working_day-${day}' data-working_day_id='${planningAgents.length}' data-type='delete' class="dropdown-item">Supprimer</a>`
                            //}
                            //else 
                            //    suppr = ""

                            let options = `<div class="dropdown">
                                <a id="card-settings-${day}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                    <i class="card-icon card-icon-settings "></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                    <a href="javascript:void(0)" data-working_day='${moment(date).format("DD/MM/YYYY")}' data-planning_id='${val.id}' data-toggle="modal" data-target="#modal-list_agents" backdrop="static" keyboard="false" data-leader_id='${leader}' data-agents_id='${agents_id.join(',')}' data-materials='${materials.join(',')}' data-workers_needed='${val.workers_needed}' data-method='edit_new' class="dropdown-item working_day-edit-${moment(date).format("DD-MM-YYYY")}">Modifier</a>
                                    ${suppr}
                                    <a href="javascript:void(0)" id='btn-duplicate-working_day-${day}' data-planning_id='${val.id}' class="dropdown-item">Dupliquer</a>
                                </div>
                            </div>`

                            let newRow = tablePlanning.row.add( [
                                moment(date).format("DD MMMM YYYY"),
                                date,
                                val.leaders,
                                listAgentNames.join(' '),
                                val.needs,
                                val.remark,
                                options
                            ] ).draw( false ).node();
                            $.fn.dataTable.moment( 'DD MMMM YYYY' );

                            $(newRow).attr("id", `working_day-` + day);
                            $(newRow).attr("data-planning_id", val.id);
                            $(newRow).attr("class", `working_day`);
                            $(newRow).attr("data-date", `${moment(date).format("DD/MM/YYYY")}`);
                            $(newRow).attr("data-leader_id", leader);
                            $(newRow).attr("data-agents_id", agents_id.join(','));
                            $(newRow).attr("data-drivers_id", drivers.join(','));
                            $(newRow).attr("data-leaders_id", leaders.join(','));
                            $(newRow).attr("data-materials", materials.join(','));
                            if (val.workers_needed != null)
                                $(newRow).attr("data-workers_needed", val.workers_needed);
                            else {
                                $(newRow).attr("data-workers_needed", agents_id.length);
                            }

                            $(`#btn-remove-working_day-${day}`).on("click", function() {
                                if ($(this).attr('data-type') == 'delete') {
                                    deletedPlanningWorkingDays.push(val.id)
                                    $(newRow).children().not(":last-child").addClass("deleted-row")
                                    $(this).text("Annuler suppression")
                                    $(this).attr('data-type', 'undelete')
                                    //tablePlanning.row($(`#working_day-${day}`)).remove().draw();
                                }
                                else {
                                    deletedPlanningWorkingDays.splice(deletedPlanningWorkingDays.indexOf(val.id), 1)
                                    $(newRow).children().not(":last-child").removeClass("deleted-row")
                                    $(this).text("suppression")
                                    $(this).attr('data-type', 'delete')
                                }
                            })

                            // duplicate
                            $(`#btn-duplicate-working_day-${day}`).on("click", function() {
                                planning_id_duplicate = val.id
                                working_day_duplicate = day
                                //$("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                                $('#modal-planning-duplicate').modal({
                                    backdrop: 'static',
                                    keyboard: false  
                                })
                            })
                        });

                        // planning regular

                        tablePlanningRegular.rows().clear().draw();
                        
                        jQuery.each(data.planning_regular, function (i, val) {
                            let date_start = val.date_start
                            let day_start = moment(date_start).format("DD-MM-YYYY")
                            let date_end = val.date_end
                            let day_end = moment(date_end).format("DD-MM-YYYY")
                            let materials = [];

                            let agents_id = [];
                            let listAgents = "";
                            let listAgentNames = [];
                            let suppr = "";

                            jQuery.each(val.planning_regular_workers, function (i, worker) {
                                agents_id.push(worker.worker.id)
                                listAgents += `-${worker.worker.name}<br> `
                                listAgentNames.push(worker.worker.name)
                            })

                            jQuery.each(val.planning_regular_materials, function (i, mat) {
                                materials.push(mat.material_id)
                            })

                            listAgentNames = listAgentNames.map(name => `<span class='label'>${name}</span>`)

                            suppr = `<a href="javascript:void(0)" id='btn-remove-working_day_start-${val.id}' data-working_day_id='${planningAgentsRegular.length}' data-type='delete' class="dropdown-item">Supprimer</a>`

                            let options = `<div class="dropdown">
                                <a id="card-settings-${day_start}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                    <i class="card-icon card-icon-settings "></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                    <a href="javascript:void(0)" data-working_day_regular_start='${moment(date_start).format("DD/MM/YYYY")}' data-working_day_regular_end='${moment(date_end).format("DD/MM/YYYY")}' data-planning_id='${val.id}' data-materials='${materials.join(',')}' data-toggle="modal" data-target="#modal-planning_regular" backdrop="static" keyboard="false" data-agents_regular_id='${agents_id.join(',')}' data-method='edit_new' class="dropdown-item working_day_regular-edit-${moment(date_start).format("DD-MM-YYYY")}">Modifier</a>
                                    ${suppr}
                                    <a href="javascript:void(0)" id='btn-duplicate-working_day_regular-${day_start}' data-planning_regular_id='${val.id}' class="dropdown-item">Dupliquer</a>
                                </div>
                            </div>`

                            let newRow = tablePlanningRegular.row.add( [
                                moment(date_start).format("DD MMMM YYYY"),
                                moment(date_end).format("DD MMMM YYYY"),
                                listAgentNames.join(' '),
                                val.needs,
                                val.remark,
                                val.category != null ? val.category.name : '',
                                options
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `${val.id}-working_day_regular-` + day_start);
                            $(newRow).attr("data-planning_regular_id", val.id);
                            $(newRow).attr("data-agents_regular_id", agents_id.join(','));
                            $(newRow).attr("class", `working_day_regular`);
                            $(newRow).attr("data-date_start", `${moment(date_start).format("DD/MM/YYYY")}`);
                            $(newRow).attr("data-date_end", `${moment(date_end).format("DD/MM/YYYY")}`);
                            $(newRow).attr("data-category_id", `${val.category != null ? val.category.id : ''}`);
                            $(newRow).attr("data-materials", `${materials.join(',')}`);
                            $(newRow).attr("data-workers_needed", val.workers_needed);

                            $(`#btn-remove-working_day_start-${val.id}`).on("click", function() {
                                if ($(this).attr('data-type') == 'delete') {
                                    deletedPlanningWorkingDaysRegular.push(val.id)
                                    $(newRow).children().not(":last-child").addClass("deleted-row")
                                    $(this).text("Annuler suppression")
                                    $(this).attr('data-type', 'undelete')
                                }
                                else {
                                    deletedPlanningWorkingDaysRegular.splice(deletedPlanningWorkingDaysRegular.indexOf(val.id), 1)
                                    $(newRow).children().not(":last-child").removeClass("deleted-row")
                                    $(this).text("suppression")
                                    $(this).attr('data-type', 'delete')
                                }
                            })

                            // duplicate
                            $(`#btn-duplicate-working_day_regular-${day_start}`).on("click", function() {
                                planning_regular_id_duplicate = val.id
                                working_day_duplicate_regular = day_start
                                //$("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                                $('#modal-planning-duplicate_regular').modal({
                                    backdrop: 'static',
                                    keyboard: false  
                                })
                            })
                        });

                        //materials
                        tableMaterials.rows().clear().draw();

                        jQuery.each(data.materials, function (i, val) {
                            let options = `<div class="dropdown">
                                        <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                            <i class="card-icon card-icon-settings "></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                            <a href="javascript:void(0)" id='btn-remove-material-${val.id}' data-material_id='${val.id}' data-type='delete' class="dropdown-item">Supprimer</a>
                                        </div>
                                    </div>`

                            let newRow = tableMaterials.row.add( [
                                val.material.name,
                                val.material.type_material.name,
                                val.material.region.name,
                                options
                            ] ).draw( false ).node();
                
                            $(newRow).attr("id", `material-` + val.id);

                            let one_material = {
                                id: val.id,
                                material_id: val.material.id,
                                name: val.material.name,
                                type_material: val.material.type_material.name,
                                region: val.material.region.name,
                                destroy: false
                            }
                            
                            selectedMaterials.push(one_material)
                         
                            $(`#btn-remove-material-${val.id}`).on("click", function() {
                                tableMaterials.row($(`#material-` + val.id)).remove().draw();

                                for(let i=0 ;i < selectedMaterials.length ; i++) {
                                    if (selectedMaterials[i].id == $(this).data('material_id')) {
                                        selectedMaterials.splice(i, 1)
                                    }
                                }
                            })
                        })

                        // materials rent 
                        tableMaterialsRent.rows().clear().draw();
                        
                        jQuery.each(data.materials_rents, function (i, val) {
                            let options = `<div class="dropdown">
                                        <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                        <i class="card-icon card-icon-settings "></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                            <a href="javascript:void(0)" data-material_rent_id='${val.id}' data-toggle="modal" data-target="#modal-material_rent" data-method='edit_new' class="dropdown-item">Modifier</a>
                                            <a href="javascript:void(0)" id='btn-remove-material_rent-${val.id}' data-material_rent_id='${val.id}' data-type='delete' class="dropdown-item">Supprimer</a>
                                        </div>
                                    </div>`
                                    
                            let newRow = tableMaterialsRent.row.add( [
                                val.name,
                                val.quantity,
                                val.price,
                                moment(val.date_start).format("LL"),
                                moment(val.date_end).format("LL"),
                                options
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `material_rent-` + val.id);
                            $(newRow).attr("data-date_start", val.date_start);
                            $(newRow).attr("data-date_end", val.date_end);

                            let one_material_rent = {
                                id: val.id,
                                name: val.name,
                                quantity: val.quantity,
                                date_start: val.date_start,
                                date_end: val.date_end,
                                price: val.price, 
                                method: ''
                            };

                            selectedMaterialRents.push(one_material_rent)
                            
                            $(`#btn-remove-material_rent-${val.id}`).on("click", function() {

                                if ($(this).attr('data-type') == 'delete') {
                                    deletedMaterialRents.push(val.id)
                                    $(newRow).children().not(":last-child").addClass("deleted-row")
                                    $(this).text("Annuler suppression")
                                    $(this).attr('data-type', 'undelete')
                                }
                                else {
                                    deletedMaterialRents.splice(deletedMaterialRents.indexOf(val.id), 1)
                                    $(newRow).children().not(":last-child").removeClass("deleted-row")
                                    $(this).text("suppression")
                                    $(this).attr('data-type', 'delete')
                                }

                                // tableMaterialsRent.row($(`#material_rent-` + val.id)).remove().draw();

                                // for(let i=0 ;i < selectedMaterialRents.length ; i++) {
                                //     if (selectedMaterialRents[i].id == $(this).data('material_rent_id')) {
                                //         selectedMaterialRents.splice(i, 1)
                                //     }
                                // }
                            })
                        });

                        // materials sell
                        tableMaterialsSell.rows().clear().draw();

                        jQuery.each(data.materials_sells, function (i, val) {
                            let options = `<div class="dropdown">
                                <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false" class='card-settings'>
                                <i class="card-icon card-icon-settings "></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                    <a href="javascript:void(0)" data-material_sell_id='${val.id}' data-toggle="modal" data-target="#modal-material_sell" data-method='edit_new' class="dropdown-item">Modifier</a>
                                    <a href="javascript:void(0)" id='btn-remove-material_sell-${val.id}' data-material_sell_id='${val.id}' data-type='delete' class="dropdown-item">Supprimer</a>
                                </div>
                            </div>`

                            let newRow = tableMaterialsSell.row.add( [
                                val.name,
                                val.quantity,
                                val.price,
                                options
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `material_sell-` + val.id);

                            let one_material_sell = {
                                id: val.id,
                                name: val.name,
                                quantity: val.quantity,
                                price: val.price
                            };

                            selectedMaterialSells.push(one_material_sell)

                            $("#modal-material_sell").modal("hide")

                            $(`#btn-remove-material_sell-${val.id}`).on("click", function() {

                                if ($(this).attr('data-type') == 'delete') {
                                    deletedMaterialSells.push(val.id)
                                    $(newRow).children().not(":last-child").addClass("deleted-row")
                                    $(this).text("Annuler suppression")
                                    $(this).attr('data-type', 'undelete')
                                }
                                else {
                                    deletedMaterialSells.splice(deletedMaterialSells.indexOf(val.id), 1)
                                    $(newRow).children().not(":last-child").removeClass("deleted-row")
                                    $(this).text("suppression")
                                    $(this).attr('data-type', 'delete')
                                }

                                // tableMaterialsSell.row($(`#material_sell-` + val.id)).remove().draw();

                                // for(let i=0 ;i < selectedMaterialSells.length ; i++) {
                                //     if (selectedMaterialSells[i].id == $(this).data('material_sell_id')) {
                                //         selectedMaterialSells.splice(i, 1)
                                //     }
                                // }

                            })
                        });

                        // photos
                        jQuery.each(data.worksite_photos, function (i, val) {
                            var mockFile = { name: `${HOST_API}/${val.photo.url}`, size: 12345, id: val.id };
                            myDropzone.options.addedfile.call(myDropzone, mockFile);
                            myDropzone.options.thumbnail.call(myDropzone, mockFile, `${HOST_API}/${val.photo.thumb.url}`);
                            
                        });
                        $(".dz-preview").addClass("dz-complete")
                        $(".dz-progress").remove()
                        
                        disableFields(false)

                        $("#form-worksite").validate().resetForm();

                        JsLoadingOverlay.hide();
                    }
                })
            })
                
            
        }

        function create() {
            $(".worksite-loader").removeClass("hidden")
        
            let data = {
                name: $("#worksite-name").val(),
                address: $("#worksite-address").val(),
                category_id: $("#worksite-category").val(),
                note: $("#worksite-note").val(),
                lat: $("#worksite-latitude").val(),
                long: $("#worksite-longitude").val(),
                //besoin_client: $("#worksite-besoin_client").val(),
                codePhoto: codePhoto,
                codePhotoPlanning: codePhotoPlanning,
                codePhotoPlanningRegular: codePhotoPlanningRegular
            };

            // planning punctual
            Array.prototype.forEach.call(document.getElementsByClassName('working_day'), function(item) {
                
                let planning_date = $(item).attr('data-date')
                let planning_leader_id = $(item).attr('data-leader_id')
                let planning_agents_id = $(item).attr('data-agents_id').split(',')
                let workers_needed = $(item).attr('data-workers_needed')
                let materials = $(item).attr('data-materials').split(',')
                let needs = $(`#working_day-${planning_date.replaceAll('/', '-')} td:nth-child(4)`).text()
                let remark = $(`#working_day-${planning_date.replaceAll('/', '-')} td:nth-child(5)`).text()

                let planning = {
                    date: planning_date,
                    leader_id: planning_leader_id,
                    agents: planning_agents_id,
                    needs: needs,
                    materials: materials,
                    remark: remark,
                    workers_needed: workers_needed
                }

                planningWorkingDays.push(planning)
            })

            // planning regular
            Array.prototype.forEach.call(document.getElementsByClassName('working_day_regular'), function(item) {
                
                let planning_date_start = $(item).attr('data-date_start')
                let planning_date_end = $(item).attr('data-date_end')
                let planning_agents_id = $(item).attr('data-agents_regular_id').split(',')
                
                let planning = {
                    date_start: planning_date_start,
                    date_end: planning_date_end,
                    needs: $(`#${item.id} td:nth-child(4)`).text(),
                    remark: $(`#${item.id} td:nth-child(5)`).text(),
                    category_id: $(item).attr('data-category_id'),
                    materials: $(item).attr('data-materials'),
                    agents: planning_agents_id
                }

                planningWorkingDaysRegular.push(planning)
            })

            if (planningWorkingDays.length > 0) {
                data['planning'] = planningWorkingDays
            }

            if (planningWorkingDaysRegular.length > 0) {
                data['planning_regular'] = planningWorkingDaysRegular
            }

            if (selectedContacts.length > 0) {
                data['contacts'] = selectedContacts
            }

            // if (planningWorkingDays.length > 0) {
            //     data['planning'] = planningWorkingDays
            // }
                
            if (selectedMaterials.length > 0) {
                data['materials'] = selectedMaterials
            }

            if (selectedMaterialRents.length > 0) {
                data['materials_rent'] = selectedMaterialRents
            }

            if (selectedMaterialSells.length > 0) {
                data['materials_sell'] = selectedMaterialSells
            }


            fetch(`${HOST_API}/worksites`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)

            }).then(function(response) {
                response.json().then(function(data) {
                    // let options = `<div class="dropdown">
                    //                 <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                    //                     <i class="card-icon card-icon-settings "></i>
                    //                 </a>
                    //                 <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                    //                     <a href="javascript:void(0)" id='btn-edit-worksite-${data.id}' data-worksite_id='${data.id}' data-toggle="modal" data-target="#modal-worksite" data-method='edit' backdrop="static" keyboard="false" class="dropdown-item">Modifier</a>
                    //                     <a href="javascript:void(0)" class='dropdown-item btn-duplicate-worksite-${data.id}' data-worksite_id='${data.id}'>Dupliquer</a>
                    //                     <a href="javascript:void(0)" id='btn-delete-worksite-${data.id}' class="dropdown-item">Supprimer</a>
                    //                 </div>
                    //             </div>`
                    // let newRow = tableWorksites.row.add( [
                    //     `<a href='/worksites/${data.id}' title='Voir le chantier en détail'>${data.name}</a>`,
                    //     data.category != null ? data.category.name : '',
                    //     data.address,
                    //     moment(data.start_date).format("LL"),
                    //     moment(data.end_date).format("LL"),
                    //     options
                    // ] ).draw( false ).node();

                    // $(newRow).attr("id" + `worksite-` + data.id);

                    // $(`#btn-edit-worksite-${data.id}`).on("click", function (e) {
                    //     method = 'edit'
                    //     if($(e.target).data('worksite_id') == undefined)
                    //         worksite_id = $(e.currentTarget).data('worksite_id')
                    //     else
                    //         worksite_id = $(e.target).data('worksite_id')
                        
                    //     $('#modal-worksite').modal({
                    //         backdrop: 'static',
                    //         keyboard: false  
                    //     })
                    // });

                    // $(`#btn-delete-worksite-${data.id}`).on("click", function() {
                    //     Swal.fire({
                    //         title: "Suppression",
                    //         icon: "warning",
                    //         text: `Voulez-vous vraiment supprimer ce chantier "${data.name}"?`,
                    //         showCancelButton: true,
                    //         cancelButtonText: 'Annuler',
                    //         confirmButtonText: 'Oui',
                    //     }).then(result => {
                    //         if (result.isConfirmed) {
                    //             destroy(data.id)
                    //         }
                    //     });
                    // })


                    // $(`.btn-duplicate-worksite-${data.id}`).on("click", function(e) {
                    //     worksite_id_duplicate = $(e.currentTarget).data('worksite_id')
                    //     $("#worksite-duplicate-name").val($(`#worksite-${worksite_id_duplicate} td:nth-child(1)`).text())
                    //     $('#modal-worksite-duplicate').modal({
                    //         backdrop: 'static',
                    //         keyboard: false  
                    //     })
                    // })
                    
                    tableWorksites.ajax.reload()

                    Swal.fire(
                        'Chantier créé!',
                        '',
                        'success'
                    )

                    $("#modal-worksite").modal("hide")

                    disableFields(false)

                })
            })

            

            $(".worksite-loader").addClass("hidden")
        }

        function update() {
            $(".worksite-loader").removeClass("hidden")

            let data = {
                name: $("#worksite-name").val(),
                address: $("#worksite-address").val(),
                category_id: $("#worksite-category").val(),
                note: $("#worksite-note").val(),
                lat: $("#worksite-latitude").val(),
                long: $("#worksite-longitude").val(),
                //besoin_client: $("#worksite-besoin_client").val(),
                removePhotos: removePhotos,
                removePhotosPlanning: removePhotosPlanning,
                removePhotosPlanningRegular: removePhotosPlanningRegular,
            };

            Array.prototype.forEach.call(document.getElementsByClassName('working_day'), function(item) {
                
                let planning_date = $(item).attr('data-date')
                let planning_leader_id = $(item).attr('data-leader_id')
                let planning_agents_id = $(item).attr('data-agents_id').split(',')
                let planning_drivers_id = $(item).attr('data-drivers_id').split(',')
                let planning_leaders_id = $(item).attr('data-leaders_id').split(',')
                let workers_needed = $(item).attr('data-workers_needed')
                let materials = $(item).attr('data-materials').split(',')
                let needs = $(`#working_day-${planning_date.replaceAll('/', '-')} td:nth-child(4)`).text()
                let remark = $(`#working_day-${planning_date.replaceAll('/', '-')} td:nth-child(5)`).text()
                let codePhotoPlanning
                if($(item).attr('data-code_photo') != undefined)
                    codePhotoPlanning = $(item).attr('data-code_photo').split(',')

                let planning = {
                    id: $(item).attr('data-planning_id'),
                    date: planning_date,
                    leader_id: planning_leader_id,
                    agents: planning_agents_id,
                    drivers: planning_drivers_id,
                    leaders: planning_leaders_id,
                    materials: materials,
                    needs: needs,
                    remark: remark,
                    codePhotoPlanning: codePhotoPlanning,
                    workers_needed: workers_needed
                }

                planningWorkingDays.push(planning)
            })

            Array.prototype.forEach.call(document.getElementsByClassName('working_day_regular'), function(item) {
                let planning_date_start = $(item).attr('data-date_start')
                let planning_date_end = $(item).attr('data-date_end')
                let planning_regular_agents_id = $(item).attr('data-agents_regular_id').split(',')
                let codePhotoPlanningRegular
                if($(item).attr('data-code_photo') != undefined)
                    codePhotoPlanningRegular = $(item).attr('data-code_photo').split(',')

                let planning = {
                    id: $(item).attr('data-planning_regular_id'),
                    date_start: planning_date_start,
                    date_end: planning_date_end,
                    needs: $(`#${item.id} td:nth-child(4)`).text(),
                    remark: $(`#${item.id} td:nth-child(5)`).text(),
                    category_id: $(item).attr('data-category_id'),
                    materials: $(item).attr('data-materials'),
                    agents: planning_regular_agents_id,
                    codePhotoPlanningRegular: codePhotoPlanningRegular
                }

                planningWorkingDaysRegular.push(planning)
            })

            if (planningWorkingDays.length > 0) {
                data['planning'] = planningWorkingDays
            }

            if (planningWorkingDaysRegular.length > 0) {
                data['planningRegular'] = planningWorkingDaysRegular
            }

            if(deletedPlanningWorkingDays.length > 0) {
                data['deletedPlanning'] = deletedPlanningWorkingDays
            }

            if(deletedPlanningWorkingDaysRegular.length > 0) {
                data['deletedPlanningRegular'] = deletedPlanningWorkingDaysRegular
            }
            
            if (selectedContacts.length > 0) {
                data['contacts'] = selectedContacts
            }
            
            if (selectedContactsDeleted.length > 0) {
                data['contacts_deleted'] = selectedContactsDeleted
            }

            if (selectedMaterials.length > 0) {
                data['materials'] = selectedMaterials
            }
        
            if (selectedMaterialRents.length > 0) {
                data['materials_rent'] = selectedMaterialRents
            }

            if (selectedMaterialSells.length > 0) {
                data['materials_sell'] = selectedMaterialSells
            }

            if(deletedMaterialRents.length > 0) {
                data['deletedMaterialRents'] = deletedMaterialRents
            }
            
            if(deletedMaterialSells.length > 0) {
                data['deletedMaterialSells'] = deletedMaterialSells
            }
            
            fetch(`${HOST_API}/worksites/${worksite_id}`, {
                method: 'PUT',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)

            }).then(function(response) {
                response.json().then(function(data) {

                    $(`#worksite-${worksite_id} td:nth-child(1)`).html(`<a href='/worksites/${data.id}' title='Voir le chantier en détail'>${data.name}</a>`);
                    $(`#worksite-${worksite_id} td:nth-child(2)`).text(data.category.name);
                    $(`#worksite-${worksite_id} td:nth-child(3)`).text(data.address);
                    //$(`#worksite-${worksite_id} td:nth-child(4)`).text(moment(data.created_at).format("LL"));
                    //$(`#worksite-${worksite_id} td:nth-child(5)`).text(moment(data.end_date).format("LL"));

                    $("#modal-worksite").modal("hide")


                    if ($("#worksite_id").length > 0) {
                        window.location.reload()
                    }

                    disableFields(false)
                })
            })
        }

        function destroy(id) {
            let url = `${HOST_API}/worksites/${id}`;

            $(`#card-settings-${id}`).remove()

            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        tableWorksites.row($(`#worksite-` + id)).remove().draw();

                        $('.page-content-wrapper').pgNotification({
                            style: 'simple',
                            message: "Chantier supprimé!",
                            position: "top-right",
                            timeout: 0,
                            type: "success",
                            timeout: 3000,
                        }).show();
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".progress").addClass("hidden")
                        $(".alert-list_worksites").text(data.errors)
                        $(".alert-list_worksites").removeClass("hidden")
                    })
                }
            }).catch(function(data) {
                console.log(data)
            });
        }
        
        $("#worksite-planning-workers_needed").on("change", function() {
            // total_ideo_workers_available = 0

            // $("#table-worksite-list_agents .list_agent").each(function (i, val) {
            //     let id = $(val).attr("id")

            //     if ($(`#${id} td:nth-child(4)`).text().includes('IDEO') && $(`#${id}`).not(".agent-absent")) { //&& ($(`#${id} td:nth-child(5)`).text() == "0" || $(`#${id} td:nth-child(5)`).text() == "1" || $(`#${id} td:nth-child(5)`).text() == "2")
            //         total_ideo_workers_available += 1
            //     }
            // })

            $("#worksite-planning-workers_remaining").val(total_ideo_workers_available - (parseInt($("#worksite-planning-workers_needed").val()) - current_ideo_workers_needed))


            // if ($("#worksite-planning-workers_remaining").val() <= 0) {
            //     $("#worksite-planning-workers_remaining").parent().addClass('worker_remaining_out')
            // }
            // else {
            //     $("#worksite-planning-workers_remaining").parent().removeClass('worker_remaining_out')
            // }
        })

        function getAffectation(planning_id) {
 
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse',
                'containerID': 'worksite-list_agents-content'
            });
            
            let date;
            if ($('#worksite-planning-date').val() != "") {
                date = moment($('#worksite-planning-date').datepicker("getDate")).format("DD-MM-YYYY")
            }

            if (date == undefined) {
                JsLoadingOverlay.hide();
                return
            }
            
            $(".progress-affectation").removeClass("hidden")
            $("#btn-save-agent").attr("disabled", true)

            fetch(`${HOST_API}/worksites/disponibility/${date}/${planning_id}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                $(".progress-affectation").addClass("hidden")
                $("#btn-save-agent").attr("disabled", false)
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        $(`.list_agent`).removeClass("agent-affection-1")
                        $(`.list_agent`).removeClass("agent-affection-2")
                        $(`.list_agent`).removeClass("agent-affection-3")
                        //$(`.selecting-agent`).attr('disabled', false)
                        total_ideo_workers_available = 0

                        jQuery.each(data.disponibilities, function (i, val) {
                            //$(`#${item.id} td:nth-child(6)`).text(6)

                            $(`#list_agent-${val.worker_id} td:nth-child(5)`).text(val.affectation)

                            if (val.absence == false) {
                                let name = $(`#list_agent-${val.worker_id} td:nth-child(2)`).text()
                                name = name.replace(' (absent)', '')
                                $(`#list_agent-${val.worker_id} td:nth-child(2)`).text(name)
                                $(`#list_agent-${val.worker_id}`).removeClass("agent-absent")
                                $(`#list_agent-${val.worker_id} .selecting-agent`).removeClass("agent-absent")

                                if (val.affectation == 1) {
                                    $(`#list_agent-${val.worker_id}`).addClass("agent-affection-1")
                                }
                                else if (val.affectation == 2) {
                                    $(`#list_agent-${val.worker_id}`).addClass("agent-affection-2")
                                }
                                else if (val.affectation >= 3) {
                                    $(`#list_agent-${val.worker_id}`).addClass("agent-affection-3")
                                    $(`#list_agent-${val.worker_id}`).removeClass("selected")
                                    // $(`#selecting-agent-${val.worker_id}`).prop('checked', false)
                                    // $(`#selecting-agent-${val.worker_id}`).attr('disabled', true)
                                }

                                // disable pointed worker
                                if(val.pointed){
                                    $(`#selecting-agent-${val.worker_id}`).attr('disabled', true)
                                }
                                else {
                                    $(`#selecting-agent-${val.worker_id}`).attr('disabled', false)
                                }
                              
                                if (val.ideo_worker ) {
                                    let dates_affected

                                    if (val.dates_affected != null) {
                                        dates_affected = val.dates_affected
                                    }

                                    // if (dates_affected != undefined) {
                                    //     if (dates_affected.includes(`${moment($("#worksite-planning-date").datepicker("getDate")).format("YYYY-MM-DD")}`) == false)
                                    //         total_ideo_workers_available += 1
                                    // }
                                }

                            }
                            else {
                                let name = $(`#list_agent-${val.worker_id} td:nth-child(2)`).text()
                                name = name.replace(' (absent)', '')
                                name = name + " (absent)"
                                $(`#list_agent-${val.worker_id} td:nth-child(2)`).text(name)
                                $(`#list_agent-${val.worker_id}`).addClass("agent-absent")
                                $(`#list_agent-${val.worker_id} .selecting-agent`).addClass("agent-absent")
                                $(`#selecting-agent-${val.worker_id}`).attr('disabled', true)
                                $(`#selecting-agent-${val.worker_id}`).prop("checked", false)
                                $(`#list_agent-${val.worker_id}`).removeClass("selected")
                            }
                        })
                        
                        //$("#worksite-planning-workers_remaining").val(total_ideo_workers_available - $("#worksite-planning-workers_needed").val())
                        total_ideo_workers_available = data.capacity_remaining
                        if (current_ideo_workers_needed == 0) {
                            if ($("#worksite-planning-workers_needed").val() != "")
                                $("#worksite-planning-workers_remaining").val(data.capacity_remaining - (parseInt($("#worksite-planning-workers_needed").val())))
                            else {
                                $("#worksite-planning-workers_remaining").val(data.capacity_remaining)
                            }
                        }
                        else 
                            $("#worksite-planning-workers_remaining").val(data.capacity_remaining)
                    })
                }
                
                JsLoadingOverlay.hide();
            })
        }

        function resetWorksiteFields() {
            $("#worksite-name").val("")
            $("#worksite-address").val("")
            $("#worksite-category").val("").trigger("change")
            $("#worksite-note").val("")
            $("#worksite-latitude").val("")
            $("#worksite-longitude").val("")
            //$("#worksite-besoin_client").val("")

            dropzone.removeAllFiles(true);
            dropzone_planning.removeAllFiles(true);
            dropzone_planning_regular.removeAllFiles(true);
            $(".dz-image-preview").remove()
            $("#worksite-photos").removeClass("dz-started")
            $("#planning_regulart-photos").removeClass("dz-started")

            worksite_id = null;
            planning_id = null;
            planningAgents = []
            planningWorkingDays = []
            selectedMaterialRents = []
            selectedMaterialSells = []
            deletedPlanningWorkingDays = []
            deletedMaterialRents = [];
            deletedMaterialSells = [];
            selectedMaterials = []
            selectedContacts = []
            selectedContactsDeleted = []
            planningAgentsRegular = []
            planningWorkingDaysRegular = []
            
            tableContacts.rows().clear().draw();
            tableMaterials.rows().clear().draw();
            tableMaterialsRent.rows().clear().draw();
            tableMaterialsSell.rows().clear().draw();
            tablePlanning.rows().clear().draw();
            tablePlanningRegular.rows().clear().draw();

            codePhoto = null;
            codePhotoPlanning = null;
            removePhotos = [];
            removePhotosPlanning = [];
            removePhotosPlanningRegular = [];
        
            $("#form-worksite a[data-toggle='tab'").removeClass('active')
            $("#form-worksite .tab-pane").removeClass("active")
            $("#form-worksite .tab-info").addClass("active")
            $("#form-worksite #tab-info").addClass("active")
            
        }

        function disableFields(disable) {
            if(disable) {
                $("#btn-save-worksite").attr("disabled", true)
                $("#worksite-name").attr("disabled", true)
                $("#worksite-category").attr("disabled", true)
                $("#worksite-address").attr("disabled", true)
                $("#worksite-note").attr("disabled", true)
                $("#worksite-latitude").attr("disabled", true)
                $("#worksite-longitude").attr("disabled", true)
                //$("#worksite-besoin_client").attr("disabled", true)
                $("#btn-add-contact").attr("disabled", true)
                $("#btn-add-date").attr("disabled", true)
                $("#btn-add-list_materials").attr("disabled", true)
                $("#btn-add-material_rent").attr("disabled", true)
                $("#btn-add-material_sell").attr("disabled", true)
                $("#btn-cancel-worksite").attr("disabled", true)
                $(".close").attr("disabled", true)
                $(".card-settings").addClass('hidden')

                //$("#modal-worksite .close").attr("data-dismiss", "")
                $(".worksite-loader").removeClass("hidden")
            }
            else {
                $("#btn-save-worksite").attr("disabled", false)
                $("#worksite-name").attr("disabled", false)
                $("#worksite-category").attr("disabled", false)
                $("#worksite-address").attr("disabled", false)
                $("#worksite-note").attr("disabled", false)
                $("#worksite-latitude").attr("disabled", false)
                $("#worksite-longitude").attr("disabled", false)
                //$("#worksite-besoin_client").attr("disabled", false)
                $("#btn-add-contact").attr("disabled", false)
                $("#btn-add-date").attr("disabled", false)
                $("#btn-add-list_materials").attr("disabled", false)
                $("#btn-add-material_rent").attr("disabled", false)
                $("#btn-add-material_sell").attr("disabled", false)
                $("#btn-cancel-worksite").attr("disabled", false)
                $(".close").attr("disabled", false)
                $(".card-settings").removeClass('hidden')

                //$("#modal-worksite .close").attr("data-dismiss", "modal")
                $(".worksite-loader").addClass("hidden")
            }
        }

        function annulerChantier(worksite_id) {
            fetch(`${HOST_API}/worksites/cancel/${worksite_id})}`, {
                method: 'PUT',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                window.location.reload()
            })
        }

        
        function initialize() {

            var markers = [];
            map = new google.maps.Map(document.getElementById("map-canvas"), {
                center: { lat: 46.4596, lng: 2.747729 },
                zoom: 5,
                mapTypeId: "roadmap",
            });

            // Create the search box and link it to the UI element.
            var input = document.getElementById('pac-input');
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
        
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });

            var searchBox = new google.maps.places.SearchBox(input);
            
            // Listen for the event fired when the user selects an item from the
            // pick list. Retrieve the matching places for that item.
            
            google.maps.event.addListener(searchBox, 'places_changed', function () {

                var places = searchBox.getPlaces();

                for (var i = 0, marker; marker = markers[i]; i++) {
                    marker.setMap(null);
                }
        
                markers = [];
                var bounds = new google.maps.LatLngBounds();
        
                for (var i = 0, place; place = places[i]; i++) {
        
                    // Create a marker for each place.
                    var marker = new google.maps.Marker({
                        map: map,
                        draggable:true,
                        title: place.name,
                        position: place.geometry.location
                    });
                    
                    var latInput = document.getElementsByName('latitude')[0];
                    var lngInput = document.getElementsByName('longitude')[0];
                    latInput.value = place.geometry.location.lat()
                    lngInput.value = place.geometry.location.lng();
                    google.maps.event.addListener(marker, 'dragend', function (e) {
                        latInput.value = e.latLng.lat();
                        lngInput.value = e.latLng.lng();
                    });
        
        
                    markers.push(marker);

                    bounds.extend(place.geometry.location);
                }
                
                map.fitBounds(bounds);
            });
        }
    
        $("#btn-add-date").on("click", function() {
            $(".agent-leader").each(function(){ this.checked = false; });
            $(".agent-driver").each(function(){ this.checked = false; });
        })
        
        async function getPlannigWorkerDetails(id, working_day) {
            if (tableListAgents.data().count() == 0) {
                $("#worksite-planning-date").attr('disabled', true)
                await getEquipe();
            }
            else {
                // set checked agents
                for(let i=0 ;i < agents_selected.length ; i++) {
                    $("#selecting-agent-" + agents_selected[i]).prop("checked", true)
                    $("#list_agent-" + agents_selected[i]).addClass('selected');
                }
            }
            
            $("#worksite-planning-date").attr('disabled', false)

            $(".agent-leader").each(function(){ this.checked = false; });
            $(".agent-driver").each(function(){ this.checked = false; })
            let code_photo = $(`#${working_day}`).data('code_photo')

            if (id != undefined) {
                // $(".agent-leader").attr("checked", false)
                // $(".agent-driver").attr("checked", false)

                
                fetch(`${HOST_API}/plannings/workers/${id}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': `Bearer ${$("#user_token").val()}`,
                        'Content-Type': 'application/json'
                    }),
                    mode: 'cors',
                    cache: 'default'
                }).then(function(response) {
               
                    if (response.ok) {
                        response.json().then(function(data) {
                            // workers
                            jQuery.each(data.planning_workers, function (i, worker) {
                                $(`.agent-leader-${worker.worker.id}`)[0].checked = worker.leader
                                $(`.agent-driver-${worker.worker.id}`)[0].checked = worker.driver
                            })

                            // photos
                            jQuery.each(data.planning_photos, function (i, val) {
                                var mockFile = { name: `${val.photo}`, size: 12345, id: val.id };
                                myDropzonePlanning.options.addedfile.call(myDropzonePlanning, mockFile);
                                myDropzonePlanning.options.thumbnail.call(myDropzonePlanning, mockFile, `${val.thumb}`);
                                
                            });
                            $(".dz-preview").addClass("dz-complete")
                            $(".dz-progress").remove()
                        
                            // photos planning temp
                            jQuery.each(data.t_photos, function (i, val) {
                                var mockFile = { name: `${val.photo}`, size: 12345, id: val.id };
                                myDropzonePlanning.options.addedfile.call(myDropzonePlanning, mockFile);
                                myDropzonePlanning.options.thumbnail.call(myDropzonePlanning, mockFile, `${HOST_API}/${val.thumb}`);
                                
                            });
                            $(".dz-preview").addClass("dz-complete")
                            $(".dz-progress").remove()

                            // // photos planning regular temp
                            // jQuery.each(data.t_photos, function (i, val) {
                            //     var mockFile = { name: `${HOST_API}/${val.photo.url}`, size: 12345, id: val.id };
                            //     myDropzonePlanningRegular.options.addedfile.call(myDropzonePlanningRegular, mockFile);
                            //     myDropzonePlanningRegular.options.thumbnail.call(myDropzonePlanningRegular, mockFile, `${HOST_API}/${val.photo.thumb.url}`);
                                
                            // });
                            // $(".dz-preview").addClass("dz-complete")
                            // $(".dz-progress").remove()

                            let drivers = [];
                            let leaders = [];

                            if ($(`#${working_day}`).attr("data-drivers_id") != "") {
                                if ($(`#${working_day}`).attr("data-drivers_id").toString().includes(','))
                                    drivers = $(`#${working_day}`).attr("data-drivers_id").split(',')
                                else
                                    drivers.push(parseInt($(`#${working_day}`).attr("data-drivers_id")))
                                }

                            if ($(`#${working_day}`).attr("data-leaders_id") != "") {
                                if ($(`#${working_day}`).attr("data-leaders_id").toString().includes(','))
                                    leaders = $(`#${working_day}`).attr("data-leaders_id").split(',')
                                else
                                    leaders.push(parseInt($(`#${working_day}`).attr("data-leaders_id")))
                            }

                            jQuery.each(drivers, function (i, worker) {
                                $(`.agent-driver-${parseInt(worker)}`)[0].checked = true
                            })
                            jQuery.each(leaders, function (i, worker) {
                                $(`.agent-leader-${parseInt(worker)}`)[0].checked = true
                            })

                            $("#worksite-planning-workers_needed").val($(`#${working_day}`).attr("data-workers_needed"))

                            current_ideo_workers_needed =  $(`#${working_day}`).attr("data-workers_needed")

                            if (Array.isArray($(`#${working_day}`).data('leader_id')))
                                $("#worksite-planning-leader").val($(`#${working_day}`).data('leader_id').map(val => parseInt(val))).trigger("change")
                            else
                                $("#worksite-planning-leader").val(String($(`#${working_day}`).data('leader_id')).split(',').map(val => parseInt(val))).trigger("change")
                            
                            getAffectation(id)
                        })
                    }
                })
            }
            else {
                getAffectation()
                
                if (working_day != null) {
                    let drivers = [];
                    let leaders = [];

                    if ($(`#${working_day}`).attr("data-drivers_id") != "") {
                        if ($(`#${working_day}`).attr("data-drivers_id").toString().includes(','))
                            drivers = $(`#${working_day}`).attr("data-drivers_id").split(',')
                        else
                            drivers.push(parseInt($(`#${working_day}`).attr("data-drivers_id")))
                        }

                    if ($(`#${working_day}`).attr("data-leaders_id") != "") {
                        if ($(`#${working_day}`).attr("data-leaders_id").toString().includes(','))
                            leaders = $(`#${working_day}`).attr("data-leaders_id").split(',')
                        else
                            leaders.push(parseInt($(`#${working_day}`).attr("data-leaders_id")))
                    }

                    jQuery.each(drivers, function (i, worker) {
                        $(`.agent-driver-${parseInt(worker)}`)[0].checked = true
                    })

                    jQuery.each(leaders, function (i, worker) {
                        $(`.agent-leader-${parseInt(worker)}`)[0].checked = true
                    })

                    $("#worksite-planning-workers_needed").val($(`#${working_day}`).attr('data-workers_needed'))
                    let total_ideo_workers_available = 0
                    $("#table-worksite-list_agents .list_agent").each(function (i, val) {
                        let id = $(val).attr("id")

                        if ($(`#${id} td:nth-child(4)`).text().includes('IDEO') && $(`#${id}`).not(".agent-absent")) { // && ($(`#${id} td:nth-child(5)`).text() == "0" || $(`#${id} td:nth-child(5)`).text() == "1" || $(`#${id} td:nth-child(5)`).text() == "2")
                            total_ideo_workers_available += 1
                        }
                    })

                    // $("#worksite-planning-workers_remaining").val(total_ideo_workers_available - $("#worksite-planning-workers_needed").val())

                    // if ($("#worksite-planning-workers_remaining").val() <= 0) {
                    //     $("#worksite-planning-workers_remaining").parent().addClass('worker_remaining_out')
                    // }
                    // else {
                    //     $("#worksite-planning-workers_remaining").parent().removeClass('worker_remaining_out')
                    // }

                    $.ajax({
                        url: `/photos/planning-photos/${code_photo}`,
                        type: 'GET',
                        suppressGlobalErrorHandler: true,
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                        },
                        dataType: "json",
                        success: function (data) {
                            // photos temp
                            jQuery.each(data, function (i, val) {
                                var mockFile = { name: `${val.photo.url}`, size: 12345, id: val.id };
                                myDropzonePlanning.options.addedfile.call(myDropzonePlanning, mockFile);
                                myDropzonePlanning.options.thumbnail.call(myDropzonePlanning, mockFile, `${val.photo.thumb.url}`);
                                
                            });
                            $(".dz-preview").addClass("dz-complete")
                            $(".dz-progress").remove()
                        }
                    })
                }
            }
        }

        function createContact() {
            $(".progress").removeClass("hidden")
            $("#btn-save-new-contact").attr("disabled", true)

            let url = `${HOST_API}/contacts`
            
            $.ajax({
                url: url,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#new-contact-name").val(),
                    email: $("#new-contact-email").val(),
                    phone: $("#new-contact-phone").val(),
                    role: $("#new-contact-role").val(),
                    company: $("#new-contact-company").val(),
                    function: $("#new-contact-function").val()
                },
                dataType: "json",
                success: function (data) {

                    var data = {
                        id: data.id,
                        text: data.name,
                        value: data.id
                    };
                    
                    var newOption = new Option(data.text, data.id, false, false);

                    $("#worksite-select_contact").append(newOption).trigger('change');

                    $(".progress").addClass("hidden")
                    $('#modal-new_contact').modal("hide")
                },
                error: function(data) {
                    $(".alert-contact").text(data)
                    $(".alert-contact").removeClass("hidden")
                }
            });
        }

        getCategories()
        //getEquipe()
        getRegions()
        getTypeMaterials()
        getMaterials()
        initialize();   
        //get()
    }
})