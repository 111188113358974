document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "type_materials") {
        let tableTypeMaterials = null;
        let method = "new"
        let type_material_id;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableTypeMaterials != null) {
                tableTypeMaterials.destroy();
            }
        })

        tableTypeMaterials = $('#table-type_materials').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": false,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 2,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-type_materials_wrapper > div > .col-sm-6:nth-child(1)").remove()

        // open add/edit type material
        $('#btn-add-type_material').on("click", function () {
            $('#modal-type_material').modal({
                backdrop: 'static',
                keyboard: false  
            })
        });

        // set method new or edit
        $("#modal-type_material").on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit') {
                method = $(e.relatedTarget).data('method')
                
                type_material_id = $(e.relatedTarget).data('type_material_id')
        
                $("#btn-save-type_material").attr("disabled", true)
                $("#type_material-name").attr("disabled", true)
                $("#modal-type_material .close").attr("data-dismiss", "")
                $(".type_material-left_zone").html("<img src='/assets/img/loading.svg' width='32'>")

                $(".subtitle-modal").text("Modifier un type de matériel")
                get(type_material_id)
            }
            else {
                method = "new"
                $(".subtitle-modal").text("Ajouter un nouveau type de matériel")
                $("#btn-save-type_material").data('method', 'new')
            }
        })


        // autofocus
        $("#modal-type_material").on('shown.bs.modal', function(e) { 
            $("#type_material-name").focus()
        });

        // close add/edit type material
        $("#modal-type_material").on("hidden.bs.modal", function() {
            resetFields()
        })

        // btn save
        $("#btn-save-type_material").on("click", function() {
            $("#form-type_material").validate()
            $('#form-type_material').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-type_material").valid()) {
                $(".progress").removeClass("hidden")
                $("#btn-save-type_material").attr("disabled", true)
                $("#type_material-name").attr("disabled", true)
                $("#modal-type_material .close").attr("data-dismiss", "")

                if (method == "new") {
                    create()
                }
                else {
                    update()
                }
            }
        })

        function get(id) {
            let url = `${HOST_API}/type_materials`
            $(".dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            if (id != undefined) {
                url += `/${id}`
            }

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    if (id == undefined) {
                        let canDestroy = '';
                        tableTypeMaterials.rows().clear().draw();
                        
                        jQuery.each(data, function (i, val) {
                            if (val.associated == false) {
                                canDestroy = `<a href="javascript:void(0)" id='btn-delete-type_material-${val.id}' class="dropdown-item">Supprimer</a>`
                            }
                            else {
                                canDestroy = ''
                            }
    
                            let options = `<div class="dropdown">
                                <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-type_material_id='${val.id}' data-toggle="modal" data-target="#modal-type_material" data-method='edit' class="dropdown-item">Modifier</a>
                                        ${canDestroy}
                                    </div>
                                </div>`
                            let newRow = tableTypeMaterials.row.add( [
                                val.id,
                                val.name,
                                options
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "type_material-" + val.id);
                            
                            $(`#btn-delete-type_material-${val.id}`).on("click", function() {
                                Swal.fire({
                                    title: "Suppression",
                                    icon: "warning",
                                    text: `Voulez-vous vraiment supprimer le type de matériel "${val.name}"?`,
                                    showCancelButton: true,
                                    cancelButtonText: 'Annuler',
                                    confirmButtonText: 'Oui',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        destroy(val.id)
                                    }
                                });
                            })
                            
                            $(".form-group > input").attr("disabled", false)
                            $("#dataTables_empty .dataTables_empty").html("")
                        });
                    }
                    else {
                        $("#btn-save-type_material").attr("disabled", false)
                        $("#type_material-name").attr("disabled", false)
                        $("#modal-type_material .close").attr("data-dismiss", "modal")
                        $(".type_material-left_zone").html("")

                        $("#type_material-name").val(data.name)
                    }
                },
                error: function(jqXHR, textStatus, error) {
                    $(".alert-list_type_materials").removeClass("hidden")
                    $(".alert-list_type_materials").text(textStatus)
                }
            });
        }

        function create() {
            $.ajax({
                url: `${HOST_API}/type_materials`,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#type_material-name").val()
                },
                dataType: "json",
                success: function (data) {
                    let options = `<div class="dropdown">
                        <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-type_material_id='${data.id}' data-toggle="modal" data-target="#modal-type_material" data-method='edit' class="dropdown-item">Modifier</a>
                                <a href="javascript:void(0)" id='btn-delete-type_material-${data.id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    let newRow = tableTypeMaterials.row.add( [
                        data.id,
                        data.name,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", "type_material-" + data.id);
                    
                    $(`#btn-delete-type_material-${data.id}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer le type de matériel "${data.name}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                destroy(data.id)
                            }
                        });
                    })
                    
                    $('#modal-type_material').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Nouveau type de matériel enregistré!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-type_material").text(data.responseJSON.error)
                    $(".alert-type_material").removeClass("hidden")
                }
            });
        }

        function update() {
            $.ajax({
                url: `${HOST_API}/type_materials/${type_material_id}`,
                type: 'PUT',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#type_material-name").val()
                },
                dataType: "json",
                success: function (data) {
                    $("#type_material-" + type_material_id + " td:nth-child(2)").text($("#type_material-name").val());
                    $('#modal-type_material').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Type de matériel modifié!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-type_material").text(data.responseJSON.error)
                    $(".alert-type_material").removeClass("hidden")
                }
            });
        }

        function destroy(id) {
            $(`#card-settings-${id}`).remove()

            $.ajax({
                url: `${HOST_API}/type_materials/${id}`,
                type: 'DELETE',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableTypeMaterials.row($("#type_material-" + id)).remove().draw();

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Type de matériel supprimé!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    $(".progress").addClass("hidden")
                    $(".alert-type_material").text(data.responseJSON.error)
                    $(".alert-type_material").removeClass("hidden")
                }
            });
        }

        
        function resetFields() {
            $(".progress").addClass("hidden")
            $("#btn-save-type_material").attr("disabled", false)
            $("#type_material-name").attr("disabled", false)
            $("#modal-type_material .close").attr("data-dismiss", "modal")
            $("#type_material-name").val("")

            $(".alert-type_material").addClass("hidden")
            $(".alert-type_material").text("")

            type_material_id = null
        }

        function disableFields(disable){
            if(disable) {
                $("#btn-save-type_material").attr("disabled", true)
                $("#type_material-name").attr("disabled", true)
                $("#modal-type_material .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")
            }
            else {
                $("#btn-save-type_material").attr("disabled", false)
                $("#type_material-name").attr("disabled", false)
                $("#modal-type_material .close").attr("data-dismiss", "modal")
                $(".progress").addClass("hidden")
            }
        }

        get()
    }
})