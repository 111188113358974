document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "categories") {
        let tableCategories = null;
        let method = "new"
        let category_id;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableCategories != null) {
                tableCategories.destroy();
            }
        })

        tableCategories = $('#table-categories').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": true,
            'width' : [[0, '1']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 2,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-categories_wrapper > div > .col-sm-6:nth-child(1)").remove()

        // open category
        $('#btn-add-category').on("click", function () {
            $('#modal-category').modal('show')
        });

        // opened modal 
        $("#modal-category").on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit') {
                method = $(e.relatedTarget).data('method')
                
                category_id = $(e.relatedTarget).data('category_id')
                
                disableFields(true)

                $(".category_id-left_zone").html("<img src='/assets/img/loading.svg' width='32'>")
                $(".subtitle-modal").text("Modifier un type de prestation")

                get(category_id)
            }
            else {
                method = "new"
                $("#category-password").attr("required", "")
                $(".subtitle-modal").text("Ajouter un nouveau type de prestation")
                $("#btn-save-category").data('method', 'new')
            }
        })

        // autofocus
        $("#modal-category").on('shown.bs.modal', function(e) { 
            $("#category-name").focus()
        });

        // close modal
        $("#modal-category").on("hidden.bs.modal", function() {
            resetFields()

            $("#form-category").validate().resetForm();
            $(".error").html('');
            $(".error").removeClass("error");
            $(".has-error").removeClass("has-error");

        })

        // btn save
        $("#btn-save-category").on("click", function() {
            $("#form-category").validate()
            $('#form-category').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-category").valid()) {
                $(".progress").removeClass("hidden")
                $("#btn-save-category").attr("disabled", true)

                disableFields(true)

                if (method == "new") {
                    create()
                }
                else {
                    update()
                }
            }
        })


        function get(id) {

            let url = `${HOST_API}/categories`
            $(".dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            if (id != null) {
                url = `${HOST_API}/categories/${id}`
            }

            fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        if (id == undefined) {
                            let delete_category;

                            tableCategories.rows().clear().draw();
    
                            jQuery.each(data, function (i, val) {
                                if (!val.associated) {
                                    delete_category = `<a href='javascript:void(0)' id='btn-delete-category-${val.id}' class='dropdown-item'>Supprimer</a>`
                                }
                                else 
                                    delete_category = ""
                                let options = `<div class="dropdown">
                                    <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                        <i class="card-icon card-icon-settings "></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                            <a href="javascript:void(0)" data-category_id='${val.id}' data-toggle="modal" data-target="#modal-category" data-method='edit' class="dropdown-item">Modifier</a>
                                            ${delete_category}
                                        </div>
                                    </div>`
                                
                                let newRow = tableCategories.row.add( [
                                    val.id,
                                    val.name,
                                    options
                                ] ).draw( false ).node();
                                
                                $(newRow).attr("id", `category-` + val.id);

                                $(`#btn-delete-category-${val.id}`).on("click", function() {
                                    Swal.fire({
                                        title: "Suppression",
                                        icon: "warning",
                                        text: `Voulez-vous vraiment supprimer ce type de prestation "${val.name}"?`,
                                        showCancelButton: true,
                                        cancelButtonText: 'Annuler',
                                        confirmButtonText: 'Oui',
                                    }).then(result => {
                                        if (result.isConfirmed) {
                                            destroy(val.id)
                                        }
                                    });
                                })
                                
                                $(".form-group > input").attr("disabled", false)
                                $("#dataTables_empty .dataTables_empty").html("")

                            })

                        }
                        else {
                            disableFields(false)
    
                            $(".category-left_zone").html("")
                            
                            $("#category-name").val(data.name)
    
                            $(".category-created_at_zone").removeClass("hidden")
                            $(".category_created_at").text("Créé le: " + moment(data.created_at, 'YYYY-MM-DD HH:mm').format('LLL'))
                            $(".category_updated_at").text("Modifié le: " + (data.created_at != data.updated_at ? moment(data.updated_at, 'YYYY-MM-DD HH:mm').format('LLL') : '-'))
                        }
                    })
                }
            })
        }

        function create() {
            let url;

            url = `${HOST_API}/categories`
            
            $.ajax({
                url: url,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#category-name").val()
                },
                dataType: "json",
                success: function (data) {
                    let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"

                    let options = `<div class="dropdown">
                        <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-category_id='${data.id}' data-toggle="modal" data-target="#modal-category" data-method='edit' class="dropdown-item">Modifier</a>
                                <a href="javascript:void(0)" id='btn-delete-category-${data.id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    let newRow = tableCategories.row.add( [
                        data.id,
                        data.name,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `category-` + data.id);
                    
                    if(data.active == false) {
                        $(newRow).css("background", "rgb(251 243 230)")
                    }

                    $(`#btn-delete-category-${data.id}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer le type de prestation "${data.name}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                destroy(data.id)
                            }
                        });
                    })
                    
                    $('#modal-category').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Nouveau type de prestation enregistré!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-category").text(data.responseJSON.error)
                    $(".alert-category").removeClass("hidden")
                }
            });
        }

        function update() {

            let url = `${HOST_API}/categories/${category_id}`
          
            let data = {
                name: $("#category-name").val()
            }

            $.ajax({
                url: url,
                type: 'PUT',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: data,
                dataType: "json",
                success: function (data) {
                    let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"

                    $(`#category-` + category_id + " td:nth-child(2)").text(data.name);

                    if(data.active == false) {
                        $(`#category-` + category_id).css("background", "rgb(251 243 230)")
                    }
                    else {
                        $(`#category-` + category_id).css("background", "")
                    }

                    $('#modal-category').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Type de prestation modifié!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-category").text(data.responseJSON.error)
                    $(".alert-category").removeClass("hidden")
                }
            });
        }

        function destroy(id) {
            let url = `${HOST_API}/categories/${id}`;

            $(`#card-settings-${id}`).remove()

            $.ajax({
                url: url,
                type: 'DELETE',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableCategories.row($(`#category-` + id)).remove().draw();

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Type de prestation supprimé!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    $(".progress").addClass("hidden")
                    $(".alert-category").text(data.responseJSON.error)
                    $(".alert-category").removeClass("hidden")
                }
            });
        }

        function resetFields() {

            disableFields(false)
            $("#category-name").val("")

            $(".alert-category").addClass("hidden")
            $(".category-created_at_zone").addClass("hidden")

            category_id = null;
        }

        function disableFields(disable){
            if(disable) {
                $("#btn-save-category").attr("disabled", true)
                $("#category-name").attr("disabled", true)

                $("#modal-category .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")
            }
            else {
                $("#btn-save-category").attr("disabled", false)
                $("#category-name").attr("disabled", false)

                $("#modal-category .close").attr("data-dismiss", "modal")
                $(".progress").addClass("hidden")
            }
        }

        get()
    }
})