document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "notifications") {

        let tableNotifications = null;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableNotifications != null) {
                tableNotifications.destroy();
            }
        })

        tableNotifications = $('#table-notifications').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSort": false,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": true,
            'width' : [[0, '300']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 3,
                    'className': 'dt-body-right'
                },
                {
                    'targets': [0],
                    'visible': false
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-notifications_wrapper > div > .col-sm-6:nth-child(1)").remove()
        $("#table-notifications").attr("style", '')


        $(".notification-set_seen").on("click", function(e) {
            let notification_id = $(this).data('notification_id')

            $(`.notification-${notification_id}`).removeClass('unread')
            $(`.notification-${notification_id}`).removeClass('notif_not_seen')
            $(`.notification-${notification_id}`).addClass('notif_seen')

            fetch(`${HOST_API}/notifications/seen`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify({id: notification_id}),
            }).then(function(response) {
                let total = parseInt($('.notification_total').text()) - 1
                $('.notification_total').text(total)
                $('.bubble').text(total)

                if (total == 0)
                    $(".bubble").addClass("hidden")
            })
        })
    }
})