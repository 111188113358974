import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
    connected() {
        console.log("connected!")

        getNotifications()
    },

    disconnected() {
    },

    received(data) {
        if(data.new) {
            $(".bubble").removeClass("hidden")

            $(".notification-content").prepend(`
                <div class="notification-item unread clearfix notification-${data.id}">
                    <div class="heading pt-2 pb-2">
                        <div class="thumbnail-wrapper d24 circular b-white m-r-5 b-a b-white m-r-10">
                            <img width="30" height="30" data-src-retina="/assets/img/default-user.png" data-src="/assets/img/default-user.png" alt="" src="/assets/img/default-user.png">
                        </div>
                        <a href="#" class="text-complete">
                            <span class="bold">${data.notif_text}</span>
                        </a>
                        <span class="pull-left time">Il y a ${data.created_at}</span>
                    </div>
                    <div class="option">
                        <a href="#" class="mark mark-${data.id}" title='Marquer comme lu'></a>
                    </div>
                </div>
            `)

            let total = parseInt($('.notification_total').text()) + 1
            $('.notification_total').text(total)
            $('.bubble').text(total)

            $(`.mark-${data.id}`).on("click", function(e){
                let notification_id = data.id
                $(`.notification-${notification_id}`).removeClass('unread')

                fetch(`${HOST_API}/notifications/seen`, {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': `Bearer ${$("#user_token").val()}`,
                        'Content-Type': 'application/json'
                    }),
                    mode: 'cors',
                    cache: 'default',
                    body: JSON.stringify({id: notification_id}),
                }).then(function(response) {
                    let total = parseInt($('.notification_total').text()) - 1
                    $('.notification_total').text(total)
                    $('.bubble').text(total)

                    if (total == 0)
                        $(".bubble").addClass("hidden")
                })
            })
        }

        else if(!data.new) {
            getNotifications()
        }

    }

    
})

function getNotifications() {
    fetch(`${HOST_API}/notifications`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${$("#user_token").val()}`,
            'Content-Type': 'application/json'
        }),
        mode: 'cors',
        cache: 'default'
    }).then(function(response) {
   
        if (response.ok) {
  
            response.json().then(function(data) {
                if (data.total > 0)
                    $(".bubble").removeClass("hidden")
                
                $(".notification_total").text(data.total)
                $(".bubble").text(data.total)

                jQuery.each(data.notifications, function (i, val) {
                    let unread = !val.seen ? 'unread' : ''

                    $(".notification-content").append(`
                        <div class="notification-item ${unread} clearfix notification-${val.id}">
                            <div class="heading pt-2 pb-2">
                                <div class="thumbnail-wrapper d24 circular b-white m-r-5 b-a b-white m-r-10">
                                    <img width="30" height="30" data-src-retina="/assets/img/default-user.png" data-src="/assets/img/default-user.png" alt="" src="/assets/img/default-user.png">
                                </div>
                                <a href="#" class="text-complete">
                                    <span class="bold">${val.notif_text}</span>
                                </a>
                                <span class="pull-left time">Il y a ${val.created_at}</span>
                            </div>
                            <div class="option">
                                <a href="#" class="mark mark-${val.id}" data-notification_id='${val.id}' title='Marquer comme lu'></a>
                            </div>
                        </div>
                    `)

                    $(`.mark-${val.id}`).on("click", function(e){
                        let notification_id = $(e.currentTarget).data('notification_id')
                        $(`.notification-${notification_id}`).removeClass('unread')

                        fetch(`${HOST_API}/notifications/seen`, {
                            method: 'POST',
                            headers: new Headers({
                                'Authorization': `Bearer ${$("#user_token").val()}`,
                                'Content-Type': 'application/json'
                            }),
                            mode: 'cors',
                            cache: 'default',
                            body: JSON.stringify({id: val.id}),
                        }).then(function(response) {
                            let total = parseInt($('.bubble').text()) - 1
                            $('.bubble').text(total)
                            $('.notification_total').text(total)
                            if (total == 0)
                                $(".bubble").addClass("hidden")
                        })
                    })
                })

               $(".seen_all").on("click", function() {
                    $(".notification-item").removeClass("unread")
                    fetch(`${HOST_API}/notifications/seen`, {
                        method: 'POST',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`,
                            'Content-Type': 'application/json'
                        }),
                        mode: 'cors',
                        cache: 'default'
                    }).then(function(response) {
                        $('.notification_total').text(0)
                        $('.bubble').text(0)
                        $(".bubble").addClass("hidden")
                    })
               })
            })
        }
    })
}