const { get } = require("jquery");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "reports") {
        let tableReports = null;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableReports != null) {
                tableReports.destroy();
            }
        })

        tableReports = $('#table-reports').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 25,
            "bAutoWidth": false,
            'width' : [[0, '10']],
            'order' : [[4,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-reports_wrapper > div > .col-sm-6:nth-child(1)").remove()

        $("#table-reports_filter").append("<span class='filter-planning_passed'>" +
            "<div class='form-group' style='width:400px; display: table-cell; text-align: left;'>" + 
                "<select id='report-worksite' class='full-width' name='report-worksite' data-init-plugin='select2' required>" +
                    "<option value='-'>Tous les chantiers</option>" + 
                "</select>" + 
            "</div>" + 
            "<div class='input-daterange input-group' id='datepicker-range' style='width:300px'>" + 
                "<input type='text' class='input-sm form-control form-group-default' placeholder='Date début' name='start' />" +
                "<div class='input-group-addon pt-2 pl-1 '> au </div>" + 
                "<input type='text' class='input-sm form-control form-group-default' placeholder='Date fin' name='end' />" + 
            "</div>" + 
        "</span>")

        // $("#table-reports_filter").append("<span class='filter-user_type'>" +
        //     `<div class="form-group form-group-default form-group-default-select2" style='width:400px; display: table-cell; text-align: left;'>
        //         <label>Chantier</label>
        //         <select id='report-worksite' class="full-width" data-placeholder="Sélectionner un chantier" name='report-worksite' data-init-plugin="select2" required>
        //             <option value='-'>Tous</option>
        //         </select>
        //     </div>
        //     <div class="input-daterange input-group" id="datepicker-range" >
        //         <input type="text" class="input-sm form-control" name="start" />
        //         <div class="input-group-addon">to</div>
        //         <input type="text" class="input-sm form-control" name="end" />
        //     </div>` + 
        // "</span>")

        $('#datepicker-range').datepicker({
            format: 'dd/mm/yyyy'
        });

        $("#datepicker-range > input[name='start'], #datepicker-range > input[name='end']").on("changeDate", function() {
            get($("#report-worksite").val())
        })
        
        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            }).on('select2:open', function () {

            });
        });

        $("#btn_download_pdf").on("click", function() {
            var doc = new jsPDF();
            
            // Source HTMLElement or a string containing HTML.
            var elementHTML = document.querySelector("#content");
        
            doc.html(elementHTML, {
                callback: function(doc) {
                    // Save the PDF
                    doc.save('document-html.pdf');
                },
                orientation: "landscape",
                margin: [10, 10, 10, 10],
                //autoPaging: true,
                format: [7, 1],
                x: -5,
                y: 10,
                width: 200, //target width in the PDF document
                windowWidth: 800 //window width in CSS pixels
            });
        })

        function get(id) {

            let start_date = ''
            let end_date = ''

            if ($("#datepicker-range > input[name='start']").datepicker("getDate") != null)
                start_date = moment($("#datepicker-range > input[name='start']").datepicker("getDate")).format('YYYY-MM-DD')
            if ($("#datepicker-range > input[name='end']").datepicker("getDate") != null)
                end_date = moment($("#datepicker-range > input[name='end']").datepicker("getDate")).format('YYYY-MM-DD')

            $("#table-reports_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            let url = 'reports'

            if (id != undefined){ 
                url = 'reports/worksites/' + id
            }

            fetch(`${HOST_API}/${url}?start=${start_date}&end=${end_date}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                $("#table-reports_wrapper .dataTables_empty").html("")
                if (response.ok) {
                    response.json().then(function(data) {

                        tableReports.rows().clear().draw();
                        jQuery.each(data.reports, function (i, val) {
                            
                            let dates = []
                            let all_dates = []
                            all_dates = val.dates.split(',')
                          
                            for(let j=0 ; j < all_dates.length ; j++) {
                                dates.push(all_dates[j])
                            }

                            dates = dates.map(ddd => `<span class='label'>${ddd}</span>`)

                            let newRow = tableReports.row.add( [
                                val.worksite.name,
                                val.type_report,
                                val.template, 
                                dates.join(' '),
                                val.created_at
                            ] ).draw( false ).node();

                            $(newRow).attr("id", "report-" + val.id);
                            $(newRow).attr("data-report_id", val.id);
                            $(newRow).attr("data-planning_type", val.planning_type);
                            $(newRow).attr("class", 'worksite-report');
                            
                            $(`#report-` + val.id).on("click", function(e) {
                                window.open('/worksites/report/' + val.id + "?planning_type=" + val.planning_type, '_blank')
                            })
                        })

                        jQuery.each(data.client_reports, function (i, val) {
                            
                            let dates = []
                            let all_dates = []
                            all_dates = val.dates.split(',')
                          
                            for(let j=0 ; j < all_dates.length ; j++) {
                                dates.push(all_dates[j])
                            }

                            dates = dates.map(ddd => `<span class='label'>${ddd}</span>`)

                            let newRow = tableReports.row.add( [
                                val.worksite.name,
                                val.type_report,
                                val.template, 
                                dates.join(' '),
                                val.created_at
                            ] ).draw( false ).node();

                            $(newRow).attr("id", "client_report-" + val.id);
                            $(newRow).attr("data-client_report_id", val.id);
                            $(newRow).attr("data-client_report_id", val.id);
                            $(newRow).attr("data-type_report", 'client');
                            $(newRow).attr("class", 'worksite-report');

                            $(`#client_report-` + val.id).on("click", function(e) {
                                window.open('/reports/client-report/' + val.uniq_id, '_blank')
                            })
                        })
                    })
                }
            })
        }

        function get_worksites() {
            fetch(`${HOST_API}/worksites`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        jQuery.each(data, function (i, val) {
                            $("#report-worksite").append(`<option value='${val.id}'>${val.name}</option>`)
                        })
                    })

                    $("#report-worksite").on("select2:select", function(e) {
                        if ($(this).val() != '-')
                            get($(this).val())
                        else 
                            get()
                    })
                }
            })
        }
        
        get()
        //get_worksites()
    }
})