import * as SMS from './send_sms'

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "plannings") {
        let tablePlannings = null;
        let tableAgents;
        let planning_id;
        let workers_id;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tablePlannings != null) {
                tablePlannings.destroy();
            }

            if (tableAgents != null) {
                tableAgents.destroy();
            }

            SMS.initSms()
        })

        tableAgents = $('#table-planning_agents').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-planning_agents_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tablePlannings = $('#table-plannings').DataTable({
            'processing': true,
            'serverSide': true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "ajax": {
                url: $('#table-plannings').data('source'),
                data: function(e) {
                    e.workers = $("#planning-workers").val();
                    e.date_start = $("#planning-date_start").val();
                    e.date_end = $("#planning-date_end").val();
                }
            },
            "columns": [
                {"data": "date"},
                {"data": "date"},
                {"data": "name",
                "render": function(data, display, record) {
                    return `<a href='/worksites/${record.worksite_id}' title='Voir le chantier en détail'>${record.name}</a>`
                }},
                {"data": "category"},
                {"data": "needs"},
                {"data": "note"},
                {"data": "workers",
                "render": function(data, display, record) {
                    //console.log(record.workers.replaceAll('&quot;', "'"))
                    let workers = []
                    workers = record.workers.replaceAll('&quot;', "'").split(',');
                    return workers.map(x => `<div class='label mt-1 badge-success'>${x}</div>`).join(' ')
                }},
                {"data": "camions"},
                {"data": "total_prix_rent",
                "render": function(data, display, record) {
                    return `${data != "" ? data + '€' : '0 €'}`
                }},
                {"data": "total_prix_sell", 
                "render": function(data, display, record) {
                    return `${data != "" ? data + '€' : '0 €'}`
                }},
                {"data": "edited_bill",
                "render": function(data, display, record) {
                    return `<div class="form-check checkbox-circle complete justify-content-center">
                        <input type="checkbox" value="3" class='selecting-planning' id="selecting-planning-${record.id}" data-planning_id='${record.id}' ${data == 'true' ? 'checked' : ''}>
                        <label for="selecting-planning-${record.id}" class="no-padding no-margin"></label>
                    </div>`
                }},
                {"data": "actions",
                "render": function(data, display, record) {
                    let pointing_status
                    let sms_status
                    let sms_title
                    if (record.pointing_status == 'partial_pointed') {
                        pointing_status = 'pointing-partial'
                    }
                    else if (record.pointing_status == 'all_not_pointed'){
                        pointing_status = "pointing-all_not"
                    }
                    else if (record.pointing_status == 'all_pointed') {
                        pointing_status = 'pointing-all'
                    }

                    if (record.sms_status == 'partial_error') {
                        sms_status = 'sms-partial-error'
                        sms_title = "Il y a des erreurs d'envoi!"
                    }
                    else if (record.sms_status == 'all_error'){
                        sms_status = 'sms-error'
                        sms_title = "Tous les sms n'ont pas encore été envoyés!"
                    }
                    else if (record.sms_status == 'ok') {
                        sms_status = 'sms-sent'
                        sms_title = "Tous les sms ont été envoyés avec succès"
                    }

                    return `<a href='javascript:void(0)' title='Voir le pointage des agents'
                                type='button' id='btn-show-agents-${record.id}' class='btn-show-agents' 
                                data-planning_id='${record.id}'><span data-planning_id='${record.id}' class='iconify-inline ${pointing_status}' data-icon='bi:person-check-fill' data-width='20' data-height='20'></span></a>
                                <a href='javascript:void(0)' title="${sms_title}" data-toggle='modal' data-target='#modal-send_sms' data-planning_id='${record.id}' backdrop='static' keyboard='false'><i class='pg-icon ${sms_status}'>mail</i></a>`
                }},
            ],
            "fnDrawCallback": function( row, data, index ) {
                $(".selecting-planning").on("click", function(e) {
                    
                    let is_checked = $(this).prop('checked')
                    data = {
                        id: $(e.currentTarget).data('planning_id'),
                        edited_bill: is_checked
                    }

                    fetch(`${HOST_API}/plannings/edited_bill/${$(e.currentTarget).data('planning_id')}`, {
                        method: 'PUT',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`,
                            'Content-Type': 'application/json'
                        }),
                        mode: 'cors',
                        cache: 'default', 
                        body: JSON.stringify(data)
                    }).then(function(data) {

                        if (is_checked)
                            $(`#worksite-` + $(e.currentTarget).data('planning_id')).addClass('edited_bill')
                        else 
                            $(`#worksite-` + $(e.currentTarget).data('planning_id')).removeClass('edited_bill')
                    })
                })

                $('.btn-show-agents').on("click", function (e) {
                    $('#modal-worksite-agents').modal({
                        backdrop: 'static',
                        keyboard: false  
                    })
        
                    showAgents($(e.currentTarget).data('planning_id'))
                });
            },
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'width' : [[2, '1']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': [1, 2, 5, 6, 7, 8],
                    'className': 'dt-body-center'
                },
                {
                    'targets': [0],
                    'visible': false
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-plannings_wrapper > div > .col-sm-6:nth-child(1)").remove()
        $("#table-plannings").attr("style", '')

        // $("#table-plannings_filter").append("<span class='filter-planning_passed'>" +
        //     "<div class='form-check checkbox-circle complete filter-passed'>" + 
        //         "<input type='checkbox' id='filter-planning_passed' checked>" + 
        //         "<label for='filter-planning_passed'>Afficher les plannings passés</label>" +
        //     "</div>" + 
        // "</span>")

        $("#table-plannings_filter").append("<span class='filter-planning_passed'>" +
            "<div class='input-daterange input-group' id='datepicker-range' style='width:300px'>" + 
                "<input type='text' id='planning-date_start' class='input-sm form-control form-group-default' placeholder='Date début' name='start' />" +
                "<div class='input-group-addon pt-2 pl-1 '> au </div>" + 
                "<input type='text' id='planning-date_end' class='input-sm form-control form-group-default' placeholder='Date fin' name='end' />" + 
            "</div>" + 
        "</span>")

        $('#datepicker-range').datepicker({
            format: 'dd/mm/yyyy'
        });

        // filter inactive
        $("#filter-material_inactive, #filter-planning_passed").on("change", function() {
            //get()
            tablePlannings.ajax.reload()
        })

        $("#datepicker-range > input[name='start'], #datepicker-range > input[name='end']").on("changeDate", function() {
            //get()
            tablePlannings.ajax.reload()
        })

        // open send sms modal
        $('#modal-send_sms').on('show.bs.modal', function(e) { 
            planning_id = $(e.relatedTarget).data('planning_id')
            SMS.setPlanningId(planning_id)
            SMS.showSMSAgents(planning_id, 'plannings')
            
            //SMS.calcMessageSmsLength()

            initMessage('plannings')
        });

        // close send sms modal
        $('#modal-send_sms').on('hide.bs.modal', function(e) { 
            SMS.resetTableSMS()
           
        })

        // hidden send sms modal
        $('#modal-send_sms').on('hidden.bs.modal', function(e) { 
            $("#send_sms-text").attr('disabled', true)
            $("#send_sms-text").val("Chargement de message...")
        })

        // open list agents
        $('.btn-show-agents').on("click", function (e) {
            $('#modal-worksite-agents').modal({
                backdrop: 'static',
                keyboard: false  
            })

            showAgents($(e.currentTarget).data('planning_id'))
        });

        $("#planning-workers").select2({
            minimumResultsForSearch: -1,
            placeholder: "Agents"
        });

        $("#planning-workers").on("change", function() {
            
            // let workers = $("#planning-workers").val()
            // workers_id = workers.join(',')

            // get();
            tablePlannings.ajax.reload()
        })

        function get(id) {

            let start_date = ''
            let end_date = ''
            
            if ($("#datepicker-range > input[name='start']").datepicker("getDate") != null)
                start_date = moment($("#datepicker-range > input[name='start']").datepicker("getDate")).format('YYYY-MM-DD')
            if ($("#datepicker-range > input[name='end']").datepicker("getDate") != null)
                end_date = moment($("#datepicker-range > input[name='end']").datepicker("getDate")).format('YYYY-MM-DD')

            let url = `/plannings/get?start=${start_date}&end=${end_date}`

            if (id != null) {
                url = `/plannings/get/${id}`
            }

            tablePlannings.rows().clear().draw();
            $("#table-plannings_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    filter_planning_passed: $("#filter-planning_passed").prop("checked"),
                    workers_id: workers_id
                },
                dataType: "json",
                success: function (data) {
                    tablePlannings.rows().clear().draw();
                    jQuery.each(data, function (i, val) {
                        //let agents = []
                        let sms_status = 'sms-queue'
                        let sms_title = 'Envoyer SMS'
                        let pointing_status;
                        // for(let j=0 ; j < val.planning_workers.length ; j++) {
                        //     agents.push(val.planning_workers[j].worker.name)   
                        // }

                        //let see_more = `...<a href='javascript:void(0)' type='button' id='btn-show-agents-${val.id}' class='btn-show-agents' data-planning_id='${val.id}'>Voir plus</a>`

                        if (val.sms_status == 'partial_error'){
                            sms_status = 'sms-partial-error'
                            sms_title = "Il y a des erreurs d'envoi!"
                        }
                        else if(val.sms_status == 'all_error'){
                            sms_status = 'sms-error'
                            sms_title = "Tous les sms n'ont pas encore été envoyés!"
                        }
                        else if (val.sms_status == 'ok') {
                            sms_status = 'sms-sent'
                            sms_title = "Tous les sms ont été envoyés avec succès"
                        }

                        if (val.pointing_status == 'partial_pointed'){
                            pointing_status = 'pointing-partial'
                        }
                        else if(val.pointing_status == 'all_not_pointed') {
                            pointing_status = "pointing-all_not"
                        }
                        else if (val.pointing_status == 'all_pointed')  {
                            pointing_status = 'pointing-all'
                        }

                        let pointage = `<a href='javascript:void(0)' title='Voir le pointage des agents' type='button' id='btn-show-agents-${val.id}' class='btn-show-agents' data-planning_id='${val.id}'><span data-planning_id='${val.id}' class='iconify-inline ${pointing_status}' data-icon='bi:person-check-fill' data-width='20' data-height='20'></span></a>`
                        let needs = "";
                        let client_note = "";

                        if (val.besoin_client != null && val.besoin_client != "") {
                            if (val.besoin_client.length > 50) {
                                needs = `<div class='tooltip-${val.id}'>${val.besoin_client != null ? val.besoin_client.replace("\n", "<br>").substring(0,50) : ''}...</div>`;
                            }
                            else {
                                needs = `<div class='tooltip-${val.id}'>${val.besoin_client != null ? val.besoin_client.replace("\n", "<br>") : ''}</div>`;
                            }
                        }

                        if (val.note_client != null) {
                            if (val.note_client.note.length > 50) {
                                client_note = val.note_client.note.replace("\n", "<br>").substring(0,50) + "..."
                            }
                            else 
                                client_note = val.note_client.note 
                        }
                        
                        let workers = [];
                        val.workers.forEach (function(value, key) {
                            workers.push(`<div class="label mt-1 badge-success">${value}</div> `)
                        })

                        let newRow = tablePlannings.row.add( [
                            val.date,
                            moment(val.date).format("DD-MM-YYYY"),
                            `<a href='/worksites/${val.worksite_id}' class='btn-edit-worksite' data-worksite_id='${val.worksite_id}' title='Voir le chantier en détail'>${val.worksite_name}</a>`,
                            val.type.name,
                            needs,
                            workers.join(' '),
                            val.camions,
                            val.total_prix_rent != null ? `${val.total_prix_rent} €` : '0 €',
                            val.total_prix_sell != null ? `${val.total_prix_sell} €` : '0 €',
                            `<div class="form-check checkbox-circle complete justify-content-center">
                                <input type="checkbox" value="3" class='selecting-planning' id="selecting-planning-${val.id}" data-planning_id='${val.id}' ${val.edited_bill ? 'checked' : ''}>
                                <label for="selecting-planning-${val.id}" class="no-padding no-margin"></label>
                            </div>`,
                            `${pointage}
                            <a href='javascript:void(0)' title="${sms_title}" data-toggle='modal' data-target='#modal-send_sms' data-planning_id='${val.id}' backdrop='static' keyboard='false'><i class='pg-icon ${sms_status}'>mail</i></a>
                            `
                        ] ).draw( false ).node();
                        
                        $(newRow).attr("id", `worksite-` + val.id);

                        if (moment(val.date) < moment()) {
                            $(newRow).addClass('planning-passed')
                        }
                        if (val.besoin_client != null && val.besoin_client != "") {
                            if (val.besoin_client.length > 50) {
                                $(`.tooltip-${val.id}`).jBox('Tooltip', {
                                    getContent: 'title',
                                    trigger: 'mouseenter',
                                    position: {
                                        x: 'center',
                                        y: 'top'
                                    },
                                    responsiveWidth: true,
                                    responsiveHeight: true,
                                    content: `${val.besoin_client != null ? val.besoin_client.replace("\n", "<br>") : ''}`,
                                    outside: 'y',
                                    pointer: true
                                });
                            }
                        }

                        if (val.note_client != null) {
                            if (val.note_client.note.length > 50) {
                                $(`.tooltip-note-${val.id}`).jBox('Tooltip', {
                                    getContent: 'title',
                                    trigger: 'mouseenter',
                                    position: {
                                        x: 'center',
                                        y: 'top'
                                    },
                                    responsiveWidth: true,
                                    responsiveHeight: true,
                                    content:  val.note_client.note.replace("\n", "<br>"),
                                    outside: 'y',
                                    pointer: true
                                });
                            }
                        }

                        if (val.edited_bill)
                            $(newRow).addClass('edited_bill')
                        else
                            $(newRow).removeClass('edited_bill')

                        // open list agents
                        $('#btn-show-agents-' + val.id).on("click", function (e) {
                            $('#modal-worksite-agents').modal({
                                backdrop: 'static',
                                keyboard: false  
                            })

                            showAgents($(e.currentTarget).data('planning_id'))
                        });

                        // open edit planning
                        // $("#table-plannings tbody").on("click", ".btn-edit-worksite", function (e) {
                        //     method = 'edit'
                        //     showPlanningTab = true
                        //     worksite_id = $(e.currentTarget).data("worksite_id")
                        //     $('#modal-worksite').modal({
                        //         backdrop: 'static',
                        //         keyboard: false
                        //     })
                        // })
                    })
                    
                    
                    $(".selecting-planning").on("click", function(e) {
                        let is_checked = $(this).prop('checked')
                        data = {
                            id: $(e.currentTarget).data('planning_id'),
                            edited_bill: is_checked
                        }

                        fetch(`${HOST_API}/plannings/edited_bill/${$(e.currentTarget).data('planning_id')}`, {
                            method: 'PUT',
                            headers: new Headers({
                                'Authorization': `Bearer ${$("#user_token").val()}`,
                                'Content-Type': 'application/json'
                            }),
                            mode: 'cors',
                            cache: 'default', 
                            body: JSON.stringify(data)
                        }).then(function(data) {

                            if (is_checked)
                                $(`#worksite-` + $(e.currentTarget).data('planning_id')).addClass('edited_bill')
                            else 
                                $(`#worksite-` + $(e.currentTarget).data('planning_id')).removeClass('edited_bill')
                        })

                   })
                }
            })
        }

        function showAgents(id) {
           
            tableAgents.rows().clear().draw();
            $("#table-planning_agents_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
            
            $.ajax({
                url: `${HOST_API}/worksites/plannings`,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                   id: id
                },
                dataType: "json",
                success: function (data) {
                    $(".planning-date").text(moment(data.date).format("LL"))

                    $(".total-personne").text(data.planning_workers.length)

                    jQuery.each(data.planning_workers, function (i, val) {
                        let pointing;
                        if (val.pointing != null) {
                            pointing = `<span class='badge badge-success'>Validé (${moment(val.pointing.created_at).format("HH:mm:ss")})</span>`
                        }
                        else {
                            pointing = `<a href='javascript:void(0)' class='agent-validate-pointing agent-pointing-${val.worker.id}' data-agent_id='${val.worker.id}' title='Valider le pointage de cet agent'><span class='badge'>Pas encore validé</span></a>`
                            //pointing = `<span class='badge'>Pas encore validé</span>`
                        }

                        let newRow = tableAgents.row.add( [
                            val.worker.name,
                            pointing
                        ] ).draw( false ).node();
                        
                        $(newRow).attr("id", `agent-` + val.id);
                    })

                    $(".agent-validate-pointing").on("click", function(e) {
                        pointing($(this).data("agent_id"), data.planning_workers[0].planning_id)
                    })
                }
            })
        }

        function pointing(agent_id, planning_id) {
            Swal.fire({
                title: "Pointage",
                icon: "warning",
                text: "Voulez-vous valider le pointage de cet agent?",
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    $(".agent-pointing-" + agent_id).html("<img src='/assets/img/loading.svg' class='worksite-loader' width='32'>")
                    $.ajax({
                        url: `${HOST_API}/plannings/pointing`,
                        type: 'POST',
                        data: {
                            planning_id: planning_id,
                            worker_id: agent_id
                        },
                        suppressGlobalErrorHandler: true,
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                        },
                        dataType: "json",
                        success: function (data) {
                            $(".agent-pointing-" + agent_id).html(`<span class='badge badge-success'>Validé (${moment(data.created_at).format("HH:mm:ss")})</span>`)
                            $(".agent-pointing-" + agent_id).off( "click", "");
                        }
                    })
                }
            });
        }

        function initMessage(url) {
            fetch(`${HOST_API}/${url}/${planning_id}/message_sms`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#send_sms-text").attr('disabled', false)
                        $("#send_sms-text").val(data.message)
                    })
                }
            })
        }

        SMS.initSms()
        //get()
    }
})