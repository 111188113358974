document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "users") {
        let tableUsers = null;
        let tableAbsences = null;
        let tablePrestations = null;
        let method = "new"
        let user_id;
        let filter_user = 'all'
        let removed_skills = []
        let absences = []
        let removed_absence = []
        
        document.addEventListener("turbolinks:before-cache", function() {
            if (tableUsers != null) {
                tableUsers.destroy();
            }
            if (tableAbsences != null) {
                tableAbsences.destroy();
            }
        })

        tableUsers = $('#table-users').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 20,
            "bAutoWidth": false,
            'width' : [[0, '10']],
            'order' : [[0,'desc'], [7,'desc']],
            'columnDefs': [
                {
                    'targets': 9,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-users_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableAbsences = $('#table-absences').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 20,
            "bAutoWidth": false,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 2,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-absences_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tablePrestations = $('#table-prestations').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": false,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 20,
            "bAutoWidth": false,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-prestations_wrapper > div > .col-sm-6:nth-child(1)").remove()
        
        $("#table-users_filter").append("<span class='filter-user_type'>" +
            "<select class='cs-select cs-skin-slide dropdown-default' data-init-plugin='cs-select' id='filter-users' >" + 
                "<option value='all' selected>Tous les plateformes</option>" + 
                "<option value='desktop'>Desktop</option>" + 
                "<option value='mobile'>Mobile</option>" +
            "</select>" +
            `<div class="form-group form-group-default-select2 filter-skills pl-4">
                <select class="full-width" id='filter-user-skills' data-init-plugin="select2" multiple>
                </select>
            </div>` +
            "<div class='form-check checkbox-circle complete filter-inactive'>" + 
                "<input type='checkbox' id='filter-inactive' checked>" + 
                "<label for='filter-inactive'>Inactive</label>" +
            "</div>" + 
        "</span>")

        $('select[data-init-plugin="cs-select"]').each(function () {
			var el = $(this).get(0);
			$(el).wrap('<div class="cs-wrapper"></div>');
			new SelectFx(el);
		});

        // filter users / inactive
        $("#filter-users, #filter-inactive").on("change", function() {
            filter_user = $("#filter-users").val()
            get()
        })

        // open add/edit user
        $('#btn-add-user').on("click", function () {
            $('#modal-user').modal({
                backdrop: 'static',
                keyboard: false  
            })
        });

        // open absence
        $('#btn-add-absence').on("click", function () {
          
            $('#modal-absence').modal({
                backdrop: 'static',
                keyboard: false  
            })
            $(".modal-backdrop").last().css("z-index", "1050")
        });

        $('#datepicker-range').datepicker({
            format: 'dd/mm/yyyy',
            autoclose: true,
            language: 'fr'
        });

        // opened modal user
        $("#modal-user").on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit') {
                method = $(e.relatedTarget).data('method')
                
                user_id = $(e.relatedTarget).data('user_id')
                type_user = $(e.relatedTarget).data('type_user')

                $("#user-password").removeAttr("required")
                
                disableFields(true)

                $(".user-left_zone").html("<img src='/assets/img/loading.svg' width='32'>")
                $(".subtitle-modal").text("Modifier un utilisateur")

                get(user_id, type_user)
            }
            else {
                method = "new"
                $("#user-password").attr("required", "")
                $(".subtitle-modal").text("Ajouter un nouvel utilisateur")
                $("#btn-save-user").data('method', 'new')

                //validator = $("#form-user").validate()
            }
        })

        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            }).on('select2:open', function () {

            });
        });
        
        $('#filter-user-skills').select2({
            placeholder: "Compétences",
            allowClear: true
        });

        //$(".filter-skills input.select2-search__field").attr('placeholder', "Compétences")
        
        // autofocus
        $("#modal-user").on('shown.bs.modal', function(e) { 
            $("#user-name").focus()
        });

        // close add/edit user
        $("#modal-user").on("hidden.bs.modal", function() {
            resetFields()

            $("#form-user").validate().resetForm();
            $(".error").html('');
            $(".error").removeClass("error");
            $(".has-error").removeClass("has-error");
            
        })

        // change role
        $("#user-role").on("change", function() {
            
            if ($(this).val() == 'team_leader' || $(this).val() == 'agent' || $(this).val() == 'extern') {
                $(".form-user-phone").removeClass("hidden")
                $("#user-phone").attr("required", "")
                $(".user-skills-content").removeClass('hidden')
                
            }
            else {
                $(".form-user-phone").addClass("hidden")
                $("#user-phone").removeAttr("required")
                $(".user-skills-content").addClass('hidden')
                
            }

            if ($(this).val() == 'agent') {
                $(".user-type_agent-content").removeClass("hidden") 
            }
            else {
                $(".user-type_agent-content").addClass("hidden")
            }
        })

        // generate password
        $("#user-generate_pwd").on("click", function() {
            if (!$("#user-password").attr("disabled")) {
                var length = 12,
                    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#!$",
                    retVal = "";
                for (var i = 0, n = charset.length; i < length; ++i) {
                    retVal += charset.charAt(Math.floor(Math.random() * n));
                }

                $("#user-password").val(retVal)
            }
        })

        // unselect skill
        $("#user-skills").on("select2:unselect", function(e) {
            if (method == 'edit'){
                removed_skills.push(e.params.data.id)
            }
        })

        // select skill
        $("#user-skills").on("select2:select", function(e) {
            if (method == 'edit'){
                if (removed_skills.indexOf(e.params.data.id) != -1) {
                    removed_skills.splice(removed_skills.indexOf(e.params.data.id), 1)
                }
            }
        })

        // select filter skills
        $("#filter-user-skills").on("change", function(e) {
            get()
        })  

        // btn add absence
        $("#btn-save-absence").on("click", function() {
            $("#form-absence").validate()
            $('#form-absence').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-absence").valid()) {
                let date_start = $("#datepicker-range > input[name='start']").datepicker("getUTCDate")
                let date_end = $("#datepicker-range > input[name='end']").datepicker("getUTCDate")
                let motif = $("#absence-motif").val()

                let absence = {
                    id: absences.length + 1,
                    date_start: date_start,
                    date_end: date_end,
                    motif: motif
                }

                let options = `<div class="dropdown">
                                <a id="card-settings-${absence.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                    <i class="card-icon card-icon-settings "></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                    <a href="javascript:void(0)" id='btn-delete-absence-${absence.id}' class="dropdown-item">Supprimer</a>
                                </div>
                            </div>`

                let newRow = tableAbsences.row.add( [
                    `de ${moment(date_start).format('LL')} à ${moment(date_end).format('LL')}`,
                    motif,
                    options
                ] ).draw( false ).node();
                
                $(newRow).attr("id", `absence-${absence.id}`);

                absences.push(absence)

                $("#btn-delete-absence-" + absence.id).on("click", function() {
                    //absences.splice(absences.indexOf(absence.id), 1)
                    jQuery.each(absences, function (i, val) {
                        if (val.id == absence.id) {
                            absences.splice(i, 1)
                            return false
                        }
                    })
                    tableAbsences.row($("#absence-" + absence.id)).remove().draw();
                })
                
                $('#modal-absence').modal("hide")

                $("#absence-motif").val("")
                $("#datepicker-range > input[name='start']").datepicker("setDate", '')
                $("#datepicker-range > input[name='end']").datepicker("setDate", '')
            }
        })

        // btn save
        $("#btn-save-user").on("click", function() {
            $("#form-user").validate()
            $('#form-user').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-user").valid()) {
                $("#user-password").val($("#user-password").val().toString().trim())

                if (method == "new") {
                    disableFields(true)
                    create()
                }
                else {
                    if($("#user-password").val() != "") {
                        Swal.fire({
                            title: "Changement de mot de passe",
                            icon: "warning",
                            text: `Le mot de passe va être modifier, un email sera envoyer à l'utilisateur. Voulez-vous enregistrer ?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                disableFields(true)
                                update()
                            }
                        });
                    }
                    else {
                        disableFields(true)
                        update()
                    }
                }
            }
        })

        function get(id, type_user) {
            let url = `${HOST_API}/users`

            if (id != null) {
             
                if (type_user == 'user') {
                    url += `/${id}`
                }
                else if (type_user == 'worker'){
                    url = `${HOST_API}/workers/${id}`
                }
            }
            else {
                tableUsers.rows().clear().draw();
                $(".dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
            }

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    filter_user: filter_user,
                    filter_inactive: $("#filter-inactive").prop("checked"),
                    filter_skills: $("#filter-user-skills").val()
                },
                dataType: "json",
                success: function (data) {
                    let active;
                    let role;
                    let type_user;
                    let btn_delete_user;
                    let btn_delete_worker;
                    let code_user;
                    if (id == undefined) {
                        tableUsers.rows().clear().draw();
                        jQuery.each(data, function (i, val) {
                            active = val.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"
                            if (val.role == "admin")
                                role = "Administrateur"
                            else if (val.role == "operator")
                                role = "Opérateur"
                            else if (val.role == "team_leader")
                                role = "Chef-équipe"
                            else if (val.role == "agent")
                                role = "Agent"
                            else if (val.role == "extern")
                                role = "Externe"

                            if (val.platform == 'Desktop'){
                                type_user = 'user'
                                code_user = 'USR'
                            }
                            else if (val.platform == 'Mobile'){
                                type_user = 'worker'
                                code_user = 'WRK'
                            }
                            
                            if($("#user_token").data("id") != val.id || type_user != 'user') {
                                if (val.platform == 'Mobile'){
                                    if(val.associated) {
                                        btn_delete_user = ""
                                    }
                                    else {
                                        btn_delete_user = `<a href="javascript:void(0)" id='btn-delete-user-${code_user}-${val.id}' data-user_id='${val.id}' class="dropdown-item">Supprimer</a>`
                                    }
                                }
                                else
                                    if(val.associated){
                                        btn_delete_user = ""
                                    }
                                    else {
                                        btn_delete_user = `<a href="javascript:void(0)" id='btn-delete-user-${code_user}-${val.id}' data-user_id='${val.id}' class="dropdown-item">Supprimer</a>`
                                    }
                            }
                            else {
                                btn_delete_user = ""
                            }

                            let skills = []
                            jQuery.each(val.skills, function (j, skill) {
                                skills.push(`<span class='badge'>${skill.skill.name}</span>`)
                            })

                      
                            let options = `<div class="dropdown">
                                <a id="card-settings-${code_user}-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-user_id='${val.id}' data-toggle="modal" data-target="#modal-user" data-type_user="${type_user}" data-method='edit' class="dropdown-item">Modifier</a>
                                        ${btn_delete_user}
                                    </div>
                                </div>`
                            
                            
                            let newRow = tableUsers.row.add( [
                                code_user + val.id.toString().padStart(4, '0'),
                                val.name,
                                skills.join(' '),
                                val.job,
                                role,
                                val.note != 0 && val.note != null ? `${val.note}/5` : '',
                                val.platform,
                                val.type_agent,
                                active,
                                options
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `${type_user}-` + val.id);

                            if(val.active == false) {
                                $(newRow).css("background", "rgb(251 243 230)")
                            }

                            $(`#btn-delete-user-${code_user}-${val.id}`).on("click", function() {
                                Swal.fire({
                                    title: "Suppression",
                                    icon: "warning",
                                    text: `Voulez-vous vraiment supprimer l'utilisateur "${val.name}"?`,
                                    showCancelButton: true,
                                    cancelButtonText: 'Annuler',
                                    confirmButtonText: 'Oui',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        destroy(val.id, val.platform)
                                    }
                                });
                            })
                            
                            $(".form-group > input").attr("disabled", false)
                            $("#dataTables_empty .dataTables_empty").html("")
                        });
                    }
                    else {
                        let skills_id = []
                        if (data.worker != null) {
                            jQuery.each(data.worker.skill_workers, function (i, val) {
                                skills_id.push(val.skill.id)
                            })
                        }

                        disableFields(false)

                        if (data.worker != null) {
                            if (data.worker.platform == 'Mobile') {
                                $(".user-skills-content").removeClass('hidden')
                                $(".tab-absences").removeClass("hidden")
                                $(".tab-prestation").removeClass("hidden")
                            }
                            else {
                                $(".user-skills-content").removeClass('hidden')
                                $(".tab-absences").addClass("hidden")
                                $(".tab-prestation").addClass("hidden")
                            }
                        }

                        $("#user-email").attr("disabled", true)
                        $("#user-role").attr("disabled", true)

                        $(".user-left_zone").html("")
                        if (data.worker != null) {
                            $("#user-name").val(data.worker.name)
                            $("#user-job").val(data.worker.job)
                            $("#user-role").val(data.worker.role).trigger("change")
                            $("#user-skills").val(skills_id).trigger("change")
                            $("#user-email").val(data.worker.email)
                            $("#user-phone").val(data.worker.phone)
                            $("#user-type_agent").val(data.worker.type_agent).trigger('change')
                            $("#user-active").prop("checked", data.worker.active)
                            if($("#user_token").data("id") == data.worker.id && data.worker.role == 'admin') {
                                $("#user-active").attr("disabled", true)
                            }

                            // absences
                            tableAbsences.rows().clear().draw();

                            jQuery.each(data.worker.worker_absences, function (i, val) {
                                let options = `<div class="dropdown">
                                    <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                        <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" id='btn-delete-absence-${val.id}' class="dropdown-item">Supprimer</a>
                                    </div>
                                </div>`
                                
                                let newRow = tableAbsences.row.add( [
                                    `de ${moment(val.date_start).format('LL')} à ${moment(val.date_end).format('LL')}`,
                                    val.motif,
                                    options
                                ]).draw( false ).node();
                                
                                $(newRow).attr("id", `absence-` + val.id);

                                let absence = {
                                    id: val.id,
                                    date_start: val.date_start,
                                    date_end: val.date_end,
                                    motif: val.motif
                                }

                                absences.push(absence)

                                $("#btn-delete-absence-" + val.id).on("click", function() {
                                    removed_absence.push(val.id)
                                    tableAbsences.row($("#absence-" + val.id)).remove().draw();
                                })

                            })

                            // prestations
                            tablePrestations.rows().clear().draw();

                            jQuery.each(data.prestations, function (i, val) {
                                let newRow = tablePrestations.row.add( [
                                    val.name,
                                    (val.note != null && val.note != '') ? `${val.note}/5` + (val.remark_client != '' ? ` - ${val.remark_client}` : '') : ''
                                ]).draw( false ).node();

                                $(newRow).attr("id", `prestation-` + val.id);

                            })

                            $(".user-created_at_zone").removeClass("hidden")
                            $(".user_created_at").text("Créé le: " + moment(data.worker.created_at, 'YYYY-MM-DD HH:mm').format('LLL'))
                            $(".user_updated_at").text("Modifié le: " + (data.worker.created_at != data.worker.updated_at ? moment(data.worker.updated_at, 'YYYY-MM-DD HH:mm').format('LLL') : '-'))
                            $(".user_current_signin").text("Connexion actuelle à: " + (data.worker.current_sign_in_at == null ? '-' : moment(data.worker.current_sign_in_at, 'YYYY-MM-DD HH:mm').format('LLL')))
                            $(".user_last_signin").text("Dernière connexion à: " + (data.worker.last_sign_in_at == null ? '-' : moment(data.worker.last_sign_in_at, 'YYYY-MM-DD HH:mm').format('LLL')))

                        }
                        else {
                            $("#user-name").val(data.name)
                            $("#user-job").val(data.job)
                            $("#user-role").val(data.role).trigger("change")
                            $("#user-email").val(data.email)
                            $("#user-active").prop("checked", data.active)
                        }

                       
                    }
                },
                error: function(jqXHR, textStatus, error) {
                    $(".alert-list_users").removeClass("hidden")
                    $(".alert-list_users").text(textStatus)
                }
            });
        }

        function create() {
            $(".progress").removeClass("hidden")
            $("#btn-save-user").attr("disabled", true)

            let url;

            if ($("#user-role").val() == "admin" || $("#user-role").val() == "operator") {
                url = `/users`
            }
            else {
                url = `${HOST_API}/workers`
            }
            
            $.ajax({
                url: url,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#user-name").val(),
                    job: $("#user-job").val(),
                    role: $("#user-role").val(),
                    email: $("#user-email").val(),
                    phone: $("#user-phone").val(),
                    password: $("#user-password").val(),
                    active: $("#user-active").prop("checked"),
                    skills_id: $("#user-skills").val(),
                    type_agent: $("#user-type_agent").val()
                },
                dataType: "json",
                success: function (data) {
                    let type_user;
                    let role;
                    let code_user;
                    let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"

                    if (data.role == "admin")
                        role = "Administrateur"
                    else if (data.role == "operator")
                        role = "Opérateur"
                    else if (data.role == "team_leader")
                        role = "Chef-équipe"
                    else if (data.role == "agent")
                        role = "Agent"
                    else if (data.role == "extern")
                        role = "Externe"
                    
                    if (data.platform == 'Desktop'){
                        type_user = 'user'
                        code_user = 'USR'

                        $("#user-skills").val('').trigger("on")
                    }
                    else if (data.platform == 'Mobile'){
                        type_user = 'worker'
                        code_user = 'WRK'
                    }

                    let skills = []
                    jQuery.each(data.skill_workers, function (j, skill) {
                        skills.push(`<span class='badge'>${skill.skill.name}</span>`)
                    })

                    let options = `<div class="dropdown">
                        <a id="card-settings-${code_user}-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-user_id='${data.id}' data-toggle="modal" data-target="#modal-user" data-type_user="${type_user}" data-method='edit' class="dropdown-item">Modifier</a>
                                <a href="javascript:void(0)" id='btn-delete-user-${code_user}-${data.id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    let newRow = tableUsers.row.add( [
                        code_user + data.id.toString().padStart(4, '0'),
                        data.name,
                        skills.join(' '),
                        data.job,
                        role,
                        '',
                        data.platform,
                        $("#user-type_agent option:selected").text(),
                        active,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `${type_user}-` + data.id);
                    
                    if(data.active == false) {
                        $(newRow).css("background", "rgb(251 243 230)")
                    }

                    $(`#btn-delete-user-${code_user}-${data.id}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer l'utilisateur "${data.name}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                destroy(data.id, data.platform)
                            }
                        });
                    })
                    
                    $('#modal-user').modal("hide")

                    // Swal.fire(
                    //     'Utilisateur créé!',
                    //     '',
                    //     'success'
                    // )

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Nouvel utilisateur enregistré!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-user").text(data)
                    $(".alert-user").removeClass("hidden")
                }
            });
        }

        function update() {
            
            $(".progress").removeClass("hidden")
            $("#btn-save-user").attr("disabled", true)

            let url;

            if ($("#user-role").val() == "admin" || $("#user-role").val() == "operator") {
                url = `/users/${user_id}`
            }
            else {
                url = `${HOST_API}/workers/${user_id}`
            }
          
            let data = {
                name: $("#user-name").val(),
                job: $("#user-job").val(),
                role: $("#user-role").val(),
                phone: $("#user-phone").val(),
                active: $("#user-active").prop("checked"),
                skills_id: $("#user-skills").val(),
                type_agent: $("#user-type_agent").val(),
                removed_skills: removed_skills,
                absences: absences,
                removed_absence: removed_absence
            }

            if ($("#user-password").val() != "")
                data['password'] = $("#user-password").val();

            fetch(url, {
                method: 'PUT',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            }).then(function(response) {
                response.json().then(function(data) {
                    let type_user;
                    let role;
                    let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"
                    if (data.role == "admin")
                        role = "Administrateur"
                    else if (data.role == "operator")
                        role = "Opérateur"
                    else if (data.role == "team_leader")
                        role = "Chef-équipe"
                    else if (data.role == "agent")
                        role = "Agent"
                    else if (data.role == "extern")
                        role = "Externe"

                    if (data.platform == 'Desktop') {
                        type_user = 'user'
                    }
                    else if(data.platform == 'Mobile') {
                        type_user = 'worker'
                    }

                    let skills = []
                    jQuery.each(data.skill_workers, function (j, skill) {
                        skills.push(`<span class='badge'>${skill.skill.name}</span>`)
                    })

                    $(`#${type_user}-` + user_id + " td:nth-child(2)").text(data.name);
                    $(`#${type_user}-` + user_id + " td:nth-child(3)").html(skills.join(' '));
                    $(`#${type_user}-` + user_id + " td:nth-child(4)").text(data.job);
                    $(`#${type_user}-` + user_id + " td:nth-child(5)").text(role);
                    $(`#${type_user}-` + user_id + " td:nth-child(7)").text(data.platform);
                    $(`#${type_user}-` + user_id + " td:nth-child(8)").text($("#user-type_agent option:selected").text());
                    $(`#${type_user}-` + user_id + " td:nth-child(9)").html(active);

                    if(data.active == false) {
                        $(`#${type_user}-` + user_id).css("background", "rgb(251 243 230)")
                    }
                    else {
                        $(`#${type_user}-` + user_id).css("background", "")
                    }

                    $('#modal-user').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Utilisateur modifié!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                })
            })

            // $.ajax({
            //     url: url,
            //     type: 'PUT',
            //     beforeSend: function (xhr) {
            //         xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
            //     },
            //     data: data,
            //     dataType: "json",
            //     success: function (data) {
            //         let type_user;
            //         let role;
            //         let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"
            //         if (data.role == "admin")
            //             role = "Administrateur"
            //         else if (data.role == "operator")
            //             role = "Opérateur"
            //         else if (data.role == "team_leader")
            //             role = "Chef-équipe"
            //         else if (data.role == "agent")
            //             role = "Agent"
            //         else if (data.role == "extern")
            //             role = "Externe"

            //         if (data.platform == 'Desktop') {
            //             type_user = 'user'
            //         }
            //         else if(data.platform == 'Mobile') {
            //             type_user = 'worker'
            //         }

            //         $(`#${type_user}-` + user_id + " td:nth-child(2)").text(data.name);
            //         $(`#${type_user}-` + user_id + " td:nth-child(4)").text(data.job);
            //         $(`#${type_user}-` + user_id + " td:nth-child(5)").text(role);
            //         $(`#${type_user}-` + user_id + " td:nth-child(6)").text(data.platform);
            //         $(`#${type_user}-` + user_id + " td:nth-child(7)").html(active);

            //         if(data.active == false) {
            //             $(`#${type_user}-` + user_id).css("background", "rgb(251 243 230)")
            //         }
            //         else {
            //             $(`#${type_user}-` + user_id).css("background", "")
            //         }

            //         $('#modal-user').modal("hide")

            //         $('.page-content-wrapper').pgNotification({
            //             style: 'simple',
            //             message: "Utilisateur modifié!",
            //             position: "top-right",
            //             timeout: 0,
            //             type: "success",
            //             timeout: 3000,
            //         }).show();
            //     },
            //     error: function(data) {
            //         disableFields(false)
            //         $(".alert-user").text(data.responseJSON.error)
            //         $(".alert-user").removeClass("hidden")
            //     }
            // });
        }

        function destroy(id, type_user) {
            let url;
            let type;

            if(type_user == 'Desktop'){
                url = `/users/${id}`
                type = 'user'

                $(`#card-settings-USR-${id}`).remove()
            }
            else if(type_user == 'Mobile') {
                url = `${HOST_API}/workers/${id}`
                type = 'worker'
                $(`#card-settings-WRK-${id}`).remove()
            }

            $.ajax({
                url: url,
                type: 'DELETE',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableUsers.row($(`#${type}-` + id)).remove().draw();

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Utilisateur supprimé!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    $(".progress").addClass("hidden")
                    $(".alert-list_users").text(data.statusText)
                    $(".alert-list_users").removeClass("hidden")
                }
            });
        }

        function getSkills() {
            
            fetch(`${HOST_API}/skills`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        jQuery.each(data, function (i, val) {
                            $("#user-skills").append(`<option value='${val.id}'>${val.name}</option>`)
                            $("#filter-user-skills").append(`<option value='${val.id}'>${val.name}</option>`)
                        })
                    })
                }
            })
            
        }

        function resetFields() {

            disableFields(false)
            $("#user-name").val("")
            $("#user-job").val("")
            $("#user-role").val("admin").trigger("change")
            $("#user-email").val("")
            $("#user-skills").val("").trigger("change")
            $("#user-phone").val("")
            $("#user-email").val("")
            $("#user-password").val("")
            $("#user-active").prop("checked", false)
            $(".alert-user").addClass("hidden")
            $(".user-created_at_zone").addClass("hidden")

            user_id = null;
            removed_skills = []
            absences = []
            removed_absence = []

            $("#modal-user .nav-item a").removeClass('active')
            $("#modal-user .tab-info").addClass('active')
            $("#modal-user .tab-pane").removeClass('active')
            $("#modal-user #tab-info").addClass('active')
            $("#modal-user .tab-absences").addClass("hidden")
            $("#modal-user .tab-prestation").addClass("hidden")
        }

        function disableFields(disable) {
            if(disable) {
                $("#btn-save-user").attr("disabled", true)
                $("#user-name").attr("disabled", true)
                $("#user-job").attr("disabled", true)
                $("#user-role").attr("disabled", true)
                $("#user-email").attr("disabled", true)
                $("#user-skills").attr("disabled", true)
                $("#user-phone").attr("disabled", true)
                $("#user-email").attr("disabled", true)
                $("#user-password").attr("disabled", true)
                $("#user-active").attr("disabled", true)
                $("#modal-user .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")
            }
            else {
                $("#btn-save-user").attr("disabled", false)
                $("#user-name").attr("disabled", false)
                $("#user-job").attr("disabled", false)
                $("#user-role").attr("disabled", false)
                $("#user-skills").attr("disabled", false)
                $("#user-email").attr("disabled", false)
                $("#user-phone").attr("disabled", false)
                $("#user-email").attr("disabled", false)
                $("#user-password").attr("disabled", false)
                $("#user-active").attr("disabled", false)
                $("#modal-user .close").attr("data-dismiss", "modal")
                $(".progress").addClass("hidden")
            }
        }

        getSkills()
        get()
    }
})