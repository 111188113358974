document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "contacts") {
        let tableContacts = null;
        let method = "new"
        let material_id;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableContacts != null) {
                tableContacts.destroy();
            }
        })

        tableContacts = $('#table-contacts').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": true,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 7,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-contacts_wrapper > div > .col-sm-6:nth-child(1)").remove()

        // open add/edit contact
        $('#btn-add-contact').on("click", function () {
            $('#modal-contact').modal({
                backdrop: 'static',
                keyboard: false  
            })
        });

        $("#modal-contact").on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit') {
                method = $(e.relatedTarget).data('method')
                
                contact_id = $(e.relatedTarget).data('contact_id')
                
                disableFields(true)

                $(".contact-left_zone").html("<img src='/assets/img/loading.svg' width='32'>")
                $(".subtitle-modal").text("Modifier un Contact")

                get(contact_id) 
            }
            else {
                method = "new"
                $(".subtitle-modal").text("Ajouter un nouveau Contact")
                $("#btn-save-contact").data('method', 'new')
            }
        })

        $("#modal-contact").on('hidden.bs.modal', function(e) { 
            $("#contact-name").val("")
            $("#contact-email").val("")
            $("#contact-phone").val("")
            $("#contact-role").val("")
            $("#contact-company").val("")
            $("#contact-function").val("")
        })

        $("#btn-save-contact").on("click", function() {
            $("#form-contact").validate()
            $('#form-contact').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-contact").valid()) {
                disableFields(true)
                if (method == "new") {
                    create()
                }
                else {
                    update()
                }
            }
        })

        function get(id) {
            let url = `${HOST_API}/contacts`

            if (id != null) {
                url = `${HOST_API}/contacts/${id}`
            }
            else {
                tableContacts.rows().clear().draw();
                $(".dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
            }

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    let btn_delete;
                    if (id == undefined) {
                        tableContacts.rows().clear().draw();
                        jQuery.each(data, function (i, val) {
                                                      
                            btn_delete = `<a href="javascript:void(0)" id='btn-delete-contact-${val.id}' data-contact_id='${val.id}' class="dropdown-item">Supprimer</a>`
                      
                            let options = `<div class="dropdown">
                                <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-contact_id='${val.id}' data-toggle="modal" data-target="#modal-contact" data-method='edit' class="dropdown-item">Modifier</a>
                                        ${btn_delete}
                                    </div>
                                </div>`
                            
                            
                            let newRow = tableContacts.row.add( [
                                val.id,
                                val.name,
                                val.phone,
                                val.email,
                                val.role,
                                val.company,
                                val.function,
                                options
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `contact-` + val.id);

                            $(`#btn-delete-contact-${val.id}`).on("click", function() {
                                Swal.fire({
                                    title: "Suppression",
                                    icon: "warning",
                                    text: `Voulez-vous vraiment supprimer le Contact "${val.name}"?`,
                                    showCancelButton: true,
                                    cancelButtonText: 'Annuler',
                                    confirmButtonText: 'Oui',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        destroy(val.id)
                                    }
                                });
                            })
                            
                            $(".form-group > input").attr("disabled", false)
                            $("#dataTables_empty .dataTables_empty").html("")
                        });
                    }
                    else {
                        
                        disableFields(false)

                        $(".contact-left_zone").html("")

                        $("#contact-name").val(data.name)
                        $("#contact-phone").val(data.phone)
                        $("#contact-email").val(data.email)
                        $("#contact-role").val(data.role)
                        $("#contact-company").val(data.company)
                        $("#contact-function").val(data.function)
                      
                    }
                },
                error: function(jqXHR, textStatus, error) {
                    $(".alert-list_contacts").removeClass("hidden")
                    $(".alert-list_contacts").text(textStatus)
                }
            });
        }

        function create() {
            $(".progress").removeClass("hidden")
            $("#btn-save-contact").attr("disabled", true)

            let url = `${HOST_API}/contacts`
            
            $.ajax({
                url: url,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#contact-name").val(),
                    email: $("#contact-email").val(),
                    phone: $("#contact-phone").val(),
                    role: $("#contact-role").val(),
                    company: $("#contact-company").val(),
                    function: $("#contact-function").val()
                },
                dataType: "json",
                success: function (data) {
        
                    let options = `<div class="dropdown">
                        <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-contact_id='${data.id}' data-toggle="modal" data-target="#modal-contact" data-method='edit' class="dropdown-item">Modifier</a>
                                <a href="javascript:void(0)" id='btn-delete-contact-${data.id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    let newRow = tableContacts.row.add( [
                        data.id,
                        data.name,
                        data.phone,
                        data.email,
                        data.role,
                        data.company,
                        data.function,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `contact-${data.id}`);

                    $(`#btn-delete-contact-${data.id}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer le contact "${data.name}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                destroy(data.id, data.platform)
                            }
                        });
                    })
                    
                    $('#modal-contact').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Nouveau Contact enregistré!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-contact").text(data)
                    $(".alert-contact").removeClass("hidden")
                }
            });
        }

        function update() {

            $(".progress").removeClass("hidden")
            $("#btn-save-contact").attr("disabled", true)

            let url = `${HOST_API}/contacts/${contact_id}`
          
            let data = {
                name: $("#contact-name").val(),
                phone: $("#contact-phone").val(),
                email: $("#contact-email").val(),
                role: $("#contact-role").val(),
                company: $("#contact-company").val(),
                function: $("#contact-function").val()
            }

            fetch(url, {
                method: 'PUT',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            }).then(function(response) {
                response.json().then(function(data) {
                    $(`#contact-` + contact_id + " td:nth-child(2)").text(data.name);
                    $(`#contact-` + contact_id + " td:nth-child(3)").text(data.phone);
                    $(`#contact-` + contact_id + " td:nth-child(4)").text(data.email);
                    $(`#contact-` + contact_id + " td:nth-child(5)").text(data.role);
                    $(`#contact-` + contact_id + " td:nth-child(6)").text(data.company);
                    $(`#contact-` + contact_id + " td:nth-child(7)").text(data.function);

                    $('#modal-contact').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Contact modifié!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                })
            })
        }

        function destroy(id) {
            let url;
            let type;

            url = `${HOST_API}/contacts/${id}`

            $.ajax({
                url: url,
                type: 'DELETE',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableContacts.row($(`#contact-` + id)).remove().draw();

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Contact supprimé!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    $(".progress").addClass("hidden")
                    $(".alert-list_contacts").text(data.statusText)
                    $(".alert-list_contacts").removeClass("hidden")
                }
            });
        }

        function disableFields(disable) {
            if(disable) {
                $("#btn-save-contact").attr("disabled", true)
                $("#contact-name").attr("disabled", true)
                $("#contact-email").attr("disabled", true)
                $("#contact-phone").attr("disabled", true)
                $("#contact-role").attr("disabled", true)
                $("#contact-company").attr("disabled", true)
                $("#contact-function").attr("disabled", true)

                $("#modal-contact .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")
            }
            else {
                $("#btn-save-contact").attr("disabled", false)
                $("#contact-name").attr("disabled", false)
                $("#contact-email").attr("disabled", false)
                $("#contact-phone").attr("disabled", false)
                $("#contact-role").attr("disabled", false)
                $("#contact-company").attr("disabled", false)
                $("#contact-function").attr("disabled", false)
                $("#modal-contact .close").attr("data-dismiss", "modal")
                $(".progress").addClass("hidden")
            }
        }

        get()
    }
})