import * as SMS from './send_sms'

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "planning_regulars") {
        let tablePlannings = null;
        let tableAgents;
        let planning_id;
        let worksite_id;
        let workers_id;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tablePlannings != null) {
                tablePlannings.destroy();
            }

            if (tableAgents != null) {
                tableAgents.destroy();
            }

            SMS.initSms()
        })

        tableAgents = $('#table-agents').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-planning_agents_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tablePlannings = $('#table-plannings').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 30,
            "bAutoWidth": false,
            'width' : [[2, '1']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': [6, 7],
                    'className': 'dt-body-center'
                },
                {
                    'targets': [0],
                    'visible': false
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-plannings_wrapper > div > .col-sm-6:nth-child(1)").remove()
        $("#table-plannings").attr("style", '')

        $("#table-plannings_filter").append("<span class='filter-planning_passed'>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-planning_passed' checked>" + 
                "<label for='filter-planning_passed'>Afficher les plannings passés</label>" +
            "</div>" + 
        "</span>")
        
        $("#table-plannings_filter").append("<span class='filter-planning_passed'>" +
            "<div class='input-daterange input-group' id='datepicker-range' style='width:300px'>" + 
                "<input type='text' class='input-sm form-control form-group-default' placeholder='Date début' name='start' />" +
                "<div class='input-group-addon pt-2 pl-1 '> au </div>" + 
                "<input type='text' class='input-sm form-control form-group-default' placeholder='Date fin' name='end' />" + 
            "</div>" + 
        "</span>")

        $('#datepicker-range').datepicker({
            format: 'dd/mm/yyyy'
        });

        $("#datepicker-range > input[name='start'], #datepicker-range > input[name='end']").on("changeDate", function() {
            get()
        })

        // filter inactive
        $("#filter-material_inactive, #filter-planning_passed").on("change", function() {
            get()
        })

        $('#modal-change_state').on('show.bs.modal', function(e) {
            $("#planning_regular-state").select2().val($(e.relatedTarget).data('state')).trigger('change')
            planning_id = $(e.relatedTarget).data('planning_id')
        });

        $('#modal-change_mission').on('show.bs.modal', function(e) {
            $("#planning_regular-mission").select2().val($(e.relatedTarget).data('mission')).trigger('change')
            planning_id = $(e.relatedTarget).data('planning_id')
            worksite_id = $(e.relatedTarget).parent().parent().data('worksite_id')
        });

        // open send sms modal
        $('#modal-send_sms').on('show.bs.modal', function(e) { 
            planning_id = $(e.relatedTarget).data('planning_id')
            SMS.setPlanningId(planning_id)
            SMS.showSMSAgents(planning_id, 'plannings-regular')

            //SMS.calcMessageSmsLength()

            initMessage('planning_regulars')
        });

        // close send sms modal
        $('#modal-send_sms').on('hide.bs.modal', function(e) { 
            SMS.resetTableSMS()
        })

        // hidden send sms modal
        $('#modal-send_sms').on('hidden.bs.modal', function(e) { 
            $("#send_sms-text").attr('disabled', true)
            $("#send_sms-text").val("Chargement de message...")
        })

        $('#modal-agents').on('show.bs.modal', function(e) {
            planning_id = $(e.relatedTarget).data('planning_id')
            $.ajax({
                url: `${HOST_API}/planning_regulars/workers/${planning_id}`,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableAgents.rows().clear().draw();
                    jQuery.each(data.workers, function (i, val) {
                        
                        let skills = []
                        jQuery.each(val.worker.skill_workers, function (i, skill) {
                            skills.push(`<span class='label'>${skill.skill.name}</span>`)
                        })
                        

                        let newRow = tableAgents.row.add( [
                            val.worker.name,
                            skills,
                            val.worker.job,
                            val.worker.role
                        ] ).draw( false ).node();
                        
                        $(newRow).attr("id", `worker-` + val.id);

                    })
                }
            })
        })

        $("#btn-save-state").on("click", function(e) {

            $.ajax({
                url: `${HOST_API}/worksites/${planning_id}`,
                type: 'PUT',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    state: $('#planning_regular-state').val(),
                },
                dataType: "json",
                success: function (data) {
                    let badge = 'default'
                        if (data.state == 'terminé')
                            badge = 'info'
                        else if (data.state == 'facturé')
                            badge = 'success'

                    $(`#planning-` + planning_id + " td:nth-child(7) a").html(`<span class='badge badge-${badge}'>${data.state}</span>`);
                    
                    $('#modal-change_state').modal("hide")
                }
            })

        })

        $("#btn-save-mission").on("click", function(e) {

            $.ajax({
                url: `${HOST_API}/planning_regulars/${planning_id}`,
                type: 'PUT',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    category_id: $('#planning_regular-mission').val(),
                },
                dataType: "json",
                success: function (data) {
                    $(`#planning-${planning_id} td:nth-child(4) a`).attr('data-mission', data.category.id);
                    $(`#planning-${planning_id} td:nth-child(4) a`).data('mission', data.category.id);
                    $(`#planning-${planning_id} td:nth-child(4) a`).html(`${data.category.name}`);

                    $('#modal-change_mission').modal("hide")
                }
            })

        })

        $("#planning-workers").select2({
            minimumResultsForSearch: -1,
            placeholder: "Agents"
        });

        $("#planning-workers").on("change", function() {
            
            let workers = $("#planning-workers").val()
            workers_id = workers.join(',')

            get();
        })

        function get(id) {

            let start_date = ''
            let end_date = ''
            
            if ($("#datepicker-range > input[name='start']").datepicker("getDate") != null)
                start_date = moment($("#datepicker-range > input[name='start']").datepicker("getDate")).format('YYYY-MM-DD')
            if ($("#datepicker-range > input[name='end']").datepicker("getDate") != null)
                end_date = moment($("#datepicker-range > input[name='end']").datepicker("getDate")).format('YYYY-MM-DD')

            let url = `${HOST_API}/planning_regulars?start=${start_date}&end=${end_date}`

            if (id != null) {
                url = `${HOST_API}/planning_regulars/${id}`
            }

            tablePlannings.rows().clear().draw();
            $("#table-plannings_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    filter_planning_passed: $("#filter-planning_passed").prop("checked"),
                    workers_id: workers_id
                },
                dataType: "json",
                success: function (data) {
                    tablePlannings.rows().clear().draw();
            
                    jQuery.each(data, function (i, val) {
                        let sms_status = 'sms-queue'
                        let sms_title = 'Envoyer SMS'
                        let needs = '';
                        let badge = 'default'

                        if (val.sms_status == 'partial_error'){
                            sms_status = 'sms-partial-error'
                            sms_title = "Il y a des erreurs d'envoi!"
                        }
                        else if(val.sms_status == 'all_error'){
                            sms_status = 'sms-error'
                            sms_title = "Tous les sms n'ont pas encore été envoyés!"
                        }
                        else if (val.sms_status == 'ok') {
                            sms_status = 'sms-sent'
                            sms_title = "Tous les sms ont été envoyés avec succès"
                        }

                        if (val.state == 'terminé')
                            badge = 'info'
                        else if (val.state == 'facturé')
                            badge = 'success'
                        
                        if (val.needs != null) {
                            if (val.needs.length > 50) {
                                needs = `<div class='tooltip-${val.id}'>${val.needs != null ? val.needs.replace("\n", "<br>").substring(0,50) : ''}...</div>`;
                            }
                            else {
                                needs = `<div class='tooltip-${val.id}'>${val.needs != null ? val.needs.replace("\n", "<br>") : ''}</div>`;
                            }
                        }
                    
                        let mission = `<a href='javascript:' data-toggle='modal' data-mission='' data-planning_id='${val.id}' data-target='#modal-change_mission' class='btn-change-mission'>N/A</a>`
                        if (val.type != null) {
                            mission = `<a href='javascript:' data-toggle='modal' data-mission='${val.og_mission}' data-planning_id='${val.id}' data-target='#modal-change_mission' class='btn-change-mission'>${val.type.name}</a>`
                        }
                        
                        let newRow = tablePlannings.row.add( [
                            val.date_start,
                            moment(val.date_start).format("DD-MM-YYYY"),
                            moment(val.date_end).format("DD-MM-YYYY"),
                            `<a href='/worksites/${val.worksite_id}' title='Voir le chantier en détail'>${val.worksite_name}</a>`,
                            `${mission}`,
                            needs,
                            val.note_client,
                            `<a href='javascript:' data-toggle='modal' data-target='#modal-agents' data-planning_id='${val.id}'><span class='badge badge-primary'><i class='fa-regular fa-list-check'></i> voir la liste</span></a>`,
                            `<a href='javascript:' data-toggle='modal' data-state='${val.og_state}' data-planning_id='${val.id}' data-target='#modal-change_state'><span class='badge badge-${badge}'>${val.state}</span></a>`,
                            val.remark,
                            `<a href='javascript:void(0)' title="${sms_title}" data-toggle='modal' data-target='#modal-send_sms' data-planning_id='${val.id}' backdrop='static' keyboard='false'><i class='pg-icon ${sms_status}'>mail</i></a>`
                        ] ).draw( false ).node();
                        
                        $(newRow).attr("id", `planning-` + val.id);
                        $(newRow).attr("data-worksite_id", val.worksite_id);

                        if (moment(val.date_end) < moment()) {
                            $(newRow).addClass('planning-passed')
                        }
                        if (val.besoin_client != null) {
                            if (val.besoin_client.length > 50) {
                                $(`.tooltip-${val.id}`).jBox('Tooltip', {
                                    getContent: 'title',
                                    trigger: 'mouseenter',
                                    position: {
                                        x: 'center',
                                        y: 'top'
                                    },
                                    responsiveWidth: true,
                                    responsiveHeight: true,
                                    content: `${val.besoin_client != null ? val.besoin_client.replace("\n", "<br>") : ''}`,
                                    outside: 'y',
                                    pointer: true
                                });
                            }
                        }
                        
                        // if (val.note_client != null) {
                        //     if (val.note_client.note.length > 50) {
                        //         $(`.tooltip-note-${val.id}`).jBox('Tooltip', {
                        //             getContent: 'title',
                        //             trigger: 'mouseenter',
                        //             position: {
                        //                 x: 'center',
                        //                 y: 'top'
                        //             },
                        //             responsiveWidth: true,
                        //             responsiveHeight: true,
                        //             content:  val.note_client.note.replace("\n", "<br>"),
                        //             outside: 'y',
                        //             pointer: true
                        //         });
                        //     }
                        // }

                        if (val.edited_bill)
                            $(newRow).addClass('edited_bill')
                        else
                            $(newRow).removeClass('edited_bill')

                        // open list agents
                        $('#btn-show-agents-' + val.id).on("click", function (e) {
                            $('#modal-worksite-agents').modal({
                                backdrop: 'static',
                                keyboard: false  
                            })

                            showAgents($(e.currentTarget).data('planning_id'))
                        });
                    })
                    
                    $(".selecting-planning").on("click", function(e) {
                        let is_checked = $(this).prop('checked')
                        data = {
                            id: $(e.currentTarget).data('planning_id'),
                            edited_bill: is_checked
                        }

                        fetch(`${HOST_API}/plannings/edited_bill/${$(e.currentTarget).data('planning_id')}`, {
                            method: 'PUT',
                            headers: new Headers({
                                'Authorization': `Bearer ${$("#user_token").val()}`,
                                'Content-Type': 'application/json'
                            }),
                            mode: 'cors',
                            cache: 'default', 
                            body: JSON.stringify(data)
                        }).then(function(data) {

                            if (is_checked)
                                $(`#worksite-` + $(e.currentTarget).data('planning_id')).addClass('edited_bill')
                            else 
                                $(`#worksite-` + $(e.currentTarget).data('planning_id')).removeClass('edited_bill')
                        })

                   })
                }
            })
        }

        function initMessage(url) {
            fetch(`${HOST_API}/${url}/${planning_id}/message_sms`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#send_sms-text").attr('disabled', false)
                        $("#send_sms-text").val(data.message)
                    })
                }
            })
        }

        SMS.initSms()
        get()
    }
})