document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "users" && $("body").data("action") == 'show') {
    
        // save profile
        $("#btn-save-profile").on("click", function() {
            $("#form-profile").validate()
            $('#form-profile').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-profile").valid()) {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-pulse'
                });
                
                $.ajax({
                    url: `${HOST_API}/users/${$('#user_token').data('id')}`,
                    type: 'PUT',
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                    },
                    data: {
                        name: $('#profile-name').val(),
                        job: $('#profile-job').val()
                    },
                    dataType: "json",
                    success: function (data) {
                        
                        $(".alert-profile-success").removeClass("hidden")
                        
                        JsLoadingOverlay.hide();
                    },
                    error: function() {

                    }
                })
            }
        })

        function init() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse'
            });

            fetch(`${HOST_API}/users/${$('#user_token').data('id')}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#profile-name").val(data.name)
                        $("#profile-job").val(data.job)
                        $("#profile-role").val(data.role).trigger("change")
                        $("#profile-email").val(data.email)
                    })

                    JsLoadingOverlay.hide();
                }
            })
        }

        init()
    }
})