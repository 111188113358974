document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "materials") {
        let tableMaterials = null;
        let method = "new"
        let material_id;
        let filter_type = 'all';

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableMaterials != null) {
                tableMaterials.destroy();
            }
        })

        tableMaterials = $('#table-materials').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": true,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 6,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-materials_wrapper > div > .col-sm-6:nth-child(1)").remove()

        $("#table-materials_filter").append("<span class='filter-user_type'>" +
            "<select class='cs-select cs-skin-slide' data-init-plugin='cs-select' id='filter-type'>" + 
                "<option value='all'>Tous les types</option>" + 
            "</select>" +
            "<div class='form-check checkbox-circle complete filter-inactive'>" + 
                "<input type='checkbox' id='filter-material_inactive' checked>" + 
                "<label for='filter-material_inactive'>Inactive</label>" +
            "</div>" + 
        "</span>")

        // filter type
        $("#filter-type").on("change", function() {
            filter_type = $("#filter-type").val()
            get()
        })

        // filter inactive
        $("#filter-material_inactive, #filter-inactive").on("change", function() {
            get()
        })

        // open material
        $('#btn-add-material').on("click", function () {
            $('#modal-material').modal('show')
        });

        // opened modal material
        $("#modal-material").on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit') {
                method = $(e.relatedTarget).data('method')
                
                material_id = $(e.relatedTarget).data('material_id')
                
                disableFields(true)

                $(".material_id-left_zone").html("<img src='/assets/img/loading.svg' width='32'>")
                $(".subtitle-modal").text("Modifier un matériel")

                get(material_id)
            }
            else {
                method = "new"
                $("#material-password").attr("required", "")
                $(".subtitle-modal").text("Ajouter un nouveau matérial")
                $("#btn-save-material").data('method', 'new')

                //validator = $("#form-user").validate()
            }
        })

        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            }).on('select2:open', function () {

            });
        });

        // autofocus
        $("#modal-material").on('shown.bs.modal', function(e) { 
            $("#material-name").focus()
        });

        // close modal material
        $("#modal-material").on("hidden.bs.modal", function() {
            resetFields()

            $("#form-material").validate().resetForm();
            $(".error").html('');
            $(".error").removeClass("error");
            $(".has-error").removeClass("has-error");

        })

        // btn save
        $("#btn-save-material").on("click", function() {
            $("#form-material").validate()
            $('#form-material').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-material").valid()) {
                $(".progress").removeClass("hidden")
                $("#btn-save-material").attr("disabled", true)

                disableFields(true)

                if (method == "new") {
                    create()
                }
                else {
                    update()
                }
            }
        })

        function get(id) {
            let url = `${HOST_API}/materials`
            $(".dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            if (id != null) {
                url = `${HOST_API}/materials/${id}`
            }

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    filter_type: filter_type,
                    filter_inactive: $("#filter-material_inactive").prop("checked")
                },
                dataType: "json",
                success: function (data) {
                    let active;
                    let delete_material;

                    if (id == undefined) {
                        tableMaterials.rows().clear().draw();

                        jQuery.each(data, function (i, val) {
                            active = val.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"
                            delete_material = ""
                            if (!val.associated) {
                                delete_material = `<a href='javascript:void(0)' id='btn-delete-material-${val.id}' class='dropdown-item'>Supprimer</a>`
                            }
                            
                            let options = `<div class="dropdown">
                                <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-material_id='${val.id}' data-toggle="modal" data-target="#modal-material" data-method='edit' class="dropdown-item">Modifier</a>
                                        ${delete_material}
                                    </div>
                                </div>`
                            
                            let newRow = tableMaterials.row.add( [
                                val.id,
                                val.name,
                                val.type_material.name,
                                val.last_used_worksite,
                                val.note,
                                val.region.name,
                                active,
                                options
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `material-` + val.id);

                            if(val.active == false) {
                                $(newRow).css("background", "rgb(251 243 230)")
                            }

                            $(`#btn-delete-material-${val.id}`).on("click", function() {
                                Swal.fire({
                                    title: "Suppression",
                                    icon: "warning",
                                    text: `Voulez-vous vraiment supprimer ce matériel "${val.name}"?`,
                                    showCancelButton: true,
                                    cancelButtonText: 'Annuler',
                                    confirmButtonText: 'Oui',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        destroy(val.id)
                                    }
                                });
                            })
                            
                            $(".form-group > input").attr("disabled", false)
                            $("#dataTables_empty .dataTables_empty").html("")
                        });
                    }
                    else {
                        disableFields(false)

                        $(".material-left_zone").html("")
                        
                        $("#material-name").val(data.name)
                        $("#material-type").val(data.type_material.id).trigger("change")
                        $("#material-region").val(data.region.id).trigger("change")
                        $("#material-note").val(data.note)
                        $("#material-active").prop("checked", data.active)

                        $(".material-created_at_zone").removeClass("hidden")
                        $(".material_created_at").text("Créé le: " + moment(data.created_at, 'YYYY-MM-DD HH:mm').format('LLL'))
                        $(".material_updated_at").text("Modifié le: " + (data.created_at != data.updated_at ? moment(data.updated_at, 'YYYY-MM-DD HH:mm').format('LLL') : '-'))
                    }
                },
                error: function(jqXHR, textStatus, error) {
                    $(".alert-list_materials").removeClass("hidden")
                    $(".alert-list_materials").text(textStatus)
                }
            });
        }

        async function getTypeMaterials() {
            await $.ajax({
                url: `${HOST_API}/type_materials`,
                type: 'GET',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    jQuery.each(data, function (i, val) {
                       $("#filter-type").append(`<option value='${val.id}'>${val.name}</option>`)
                    })

                    $('select[data-init-plugin="cs-select"]').each(function () {
                        var el = $(this).get(0);
                        $(el).wrap('<div class="cs-wrapper"></div>');
                        new SelectFx(el);
                    });
                }
            });
        }

        function create() {
            let url;

            url = `${HOST_API}/materials`
            
            $.ajax({
                url: url,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#material-name").val(),
                    type_material_id: $("#material-type").val(),
                    region_id: $("#material-region").val(),
                    note: $("#material-note").val(),
                    active: $("#material-active").prop("checked")
                },
                dataType: "json",
                success: function (data) {
                    let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"

                    let options = `<div class="dropdown">
                        <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-material_id='${data.id}' data-toggle="modal" data-target="#modal-material" data-method='edit' class="dropdown-item">Modifier</a>
                                <a href="javascript:void(0)" id='btn-delete-material-${data.id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    let newRow = tableMaterials.row.add( [
                        data.id,
                        data.name,
                        data.type_material.name,
                        data.last_used_worksite,
                        data.note,
                        data.region.name,
                        active,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", `material-` + data.id);
                    
                    if(data.active == false) {
                        $(newRow).css("background", "rgb(251 243 230)")
                    }

                    $(`#btn-delete-material-${data.id}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer le matériel "${data.name}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                destroy(data.id)
                            }
                        });
                    })
                    
                    $('#modal-material').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Nouveau matériel enregistré!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-material").text(data.responseJSON.error)
                    $(".alert-material").removeClass("hidden")
                }
            });
        }

        function update() {

            let url = `${HOST_API}/materials/${material_id}`
          
            let data = {
                name: $("#material-name").val(),
                type_material_id: $("#material-type").val(),
                region_id: $("#material-region").val(),
                note: $("#material-note").val(),
                active: $("#material-active").prop("checked")
            }

            $.ajax({
                url: url,
                type: 'PUT',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: data,
                dataType: "json",
                success: function (data) {
                    let active = data.active ? "<span class='badge badge-success'>active</span>" : "<span class='badge badge-warning'>inactive</span>"

                    $(`#material-` + material_id + " td:nth-child(2)").text(data.name);
                    $(`#material-` + material_id + " td:nth-child(3)").text(data.type_material.name);
                    $(`#material-` + material_id + " td:nth-child(4)").text(data.last_used_worksite);
                    $(`#material-` + material_id + " td:nth-child(5)").text(data.note);
                    $(`#material-` + material_id + " td:nth-child(6)").text(data.region.name);
                    $(`#material-` + material_id + " td:nth-child(7)").html(active);

                    if(data.active == false) {
                        $(`#material-` + material_id).css("background", "rgb(251 243 230)")
                    }
                    else {
                        $(`#material-` + material_id).css("background", "")
                    }

                    $('#modal-material').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Matériel modifié!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-material").text(data.responseJSON.error)
                    $(".alert-material").removeClass("hidden")
                }
            });
        }

        function destroy(id) {
            let url = `${HOST_API}/materials/${id}`;

            $(`#card-settings-${id}`).remove()

            $.ajax({
                url: url,
                type: 'DELETE',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableMaterials.row($(`#material-` + id)).remove().draw();

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Matériel supprimé!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    $(".progress").addClass("hidden")
                    $(".alert-material").text(data.responseJSON.error)
                    $(".alert-material").removeClass("hidden")
                }
            });
        }
        
        function resetFields() {

            disableFields(false)
            $("#material-name").val("")
            $("#material-type").val(0).trigger("change")
            $("#material-region").val(0).trigger("change")
            $("#material-note").val("")
            $("#material-active").prop("checked", false)
            $(".alert-material").addClass("hidden")
            $(".material-created_at_zone").addClass("hidden")

            material_id = null;
        }

        function disableFields(disable){
            if(disable) {
                $("#btn-save-material").attr("disabled", true)
                $("#material-name").attr("disabled", true)
                $("#material-type").attr("disabled", true)
                $("#material-region").attr("disabled", true)
                $("#material-note").attr("disabled", true)
                $("#material-active").attr("disabled", true)

                $("#modal-material .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")
            }
            else {
                $("#btn-save-material").attr("disabled", false)
                $("#material-name").attr("disabled", false)
                $("#material-type").attr("disabled", false)
                $("#material-region").attr("disabled", false)
                $("#material-note").attr("disabled", false)
                $("#material-active").attr("disabled", false)

                $("#modal-material .close").attr("data-dismiss", "modal")
                $(".progress").addClass("hidden")
            }
        }

        getTypeMaterials()
        get()
    }
})