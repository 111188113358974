document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "photos") {

        let tablePhotos = $('#table-photos').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      //return ($(node).data('related')) ? true : false;
                      return ($(node).data('type') === 'old') ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
                
            },
            'processing': true,
            'serverSide': true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "ajax": {
                url: $('#table-photos').data('source'),
                data: function(e) {
                    e.agents = $("#photos-workers").val();
                    e.worksites = $("#photos-worksites").val();
                    e.dates = $("#photos-dates").val();
                }
            },
            "columns": [
                {"data": "id"},
                {"data": "created_at",
                    "render": function(data) {
                        return `${moment(data).format("LLL")}`
                    }
                },
                { "data": "photo_before" ,
                    "render": function ( data) {
                        return `<div class='worksite-photos-info'><div class="worksite-photos-info"><a href="${data}" class="glightbox gallery-images" data-type="image" data-gallery="gallery-photo_<%= photo.id %>">
                            <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${data}' data-photo='${data}' alt='photo' class='image-responsive-height'>
                                <div class="overlayer bottom-left full-width">
                                    <div class="overlayer-wrapper item-info ">
                                    
                                    </div>
                                </div>
                            </div>
                        </a></div></div>`
                    }
                },
                {"data": "photo_after",
                "render": function ( data) {
                    return `<div class="worksite-photos-info"><a href="${data}" class="glightbox gallery-images" data-type="image" data-gallery="gallery-photo_<%= photo.id %>">
                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${data}' data-photo='${data}' alt='photo' class='image-responsive-height'>
                            <div class="overlayer bottom-left full-width">
                                <div class="overlayer-wrapper item-info ">
                                
                                </div>
                            </div>
                        </div>
                    </a></div>`
                }
                },
                {"data": "worker_id"},
                {"data": "planning_id"}
            ],
            // initComplete: function () {
            //     lightbox = GLightbox({
            //         touchNavigation: true,
            //         loop: true,
            //         autoplayVideos: true
            //     });
            // },
            // createdRow: function (row, data, index) {
            //     lightbox = GLightbox({
            //         touchNavigation: true,
            //         loop: true,
            //         autoplayVideos: true
            //     });
            // },
            "fnDrawCallback": function( oSettings ) {
                lightbox = GLightbox({
                    touchNavigation: true,
                    loop: true,
                    autoplayVideos: true
                });
            },
            "pagingType": "full_numbers",
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        // 'selectRow': true,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                   }
                }
             ],
            'select': {
                'style': 'multi'
            },
            "pageLength": 50,
            "bPaginate": true,
            "bFilter": true,
            "searching": false,
            "bInfo": false,
            "bLengthChange": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 10,
            "bAutoWidth": true,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
      
            "oLanguage": {
                "sSearch": "Rechercher :",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        //$("#table-photos_wrapper > div > .col-sm-6:nth-child(1)").remove()
        tablePhotos.rows( {order:'index', search:'removed'} ).nodes();

        $("#photos-workers").select2({
            minimumResultsForSearch: -1,
            placeholder: "Agents"
        });

        $("#photos-worksites").select2({
            minimumResultsForSearch: -1,
            placeholder: "Chantiers"
        });

        let lightbox;
        
        $("#photos-dates").datepicker({
            format: 'dd/mm/yyyy',
            autoclose: false,
            multidate: true,
            // beforeShowDay: function(date){
            //     if (enableDays.indexOf(formatDate(date)) < 0)
            //       return {
            //         enabled: false
            //       }
            //     else
            //       return {
            //         enabled: true
            //     }
            // }
        })
        
        $("#photos-dates").on("hide", function() {
            //getPhotos()
            tablePhotos.ajax.reload()
        })

        $("#photos-workers, #photos-worksites").on("change", function() {
            //getPhotos()
            tablePhotos.ajax.reload()
        })
        
        function getPhotos() {
            tablePhotos.rows().clear().draw();
            
            $("#table-photos_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            let dates = $("#photos-dates").datepicker("getDates")
            dates = dates.map(d => moment(d).format('YYYY-MM-DD')) 

            let workers = $("#photos-workers").val()
            let worksites = $("#photos-worksites").val()

            let data = {};
            if (dates.length > 0)
                data['dates'] = dates
            if (workers.length != "")
                data['workers'] = workers
            if (worksites.length != "")
                data['worksites'] = worksites

            $.ajax({
                url: `/photos`,
                type: 'GET',
                data: data,
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    $("#table-photos_wrapper .dataTables_empty").html("")

                    let photo_before = null
                    let photo_after = null

                    jQuery.each(data, function (i, val) { 
                        if (val.camion == false) {
                          
                            photo_before = `<div class='worksite-photos-info'>
                                <a href='${val.photo_after}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                    <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_after}' data-photo='${val.photo_after}' alt='photo' class='image-responsive-height'>
                                        <div class="overlayer bottom-left full-width">
                                            <div class="overlayer-wrapper item-info ">
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href='${val.photo_before}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                    <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_before}' data-photo='${val.photo_before}' alt='photo' class='image-responsive-height'>
                                        <div class="overlayer bottom-left full-width">
                                            <div class="overlayer-wrapper item-info ">
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>`

                            let newRow = tablePhotos.row.add( [
                                val.id,
                                moment(val.created_at).format("LLL"),
                                photo_before,
                                val.worker.name,
                                val.worksite,
                            ] ).draw( false ).node();
                        }
                        else {

                            photo_after = `<div class='worksite-photos-info'>
                                <a href='${val.photo_after}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                    <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_after}' data-photo='${val.photo_after}' alt='photo' class='image-responsive-height'>
                                        <div class="overlayer bottom-left full-width">
                                            <div class="overlayer-wrapper item-info ">
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>`

                            let newRow = tablePhotos.row.add( [
                                val.id,
                                moment(val.created_at).format("LLL"),
                                photo_after,
                                val.worker.name,
                                val.worksite,
                            ] ).draw( false ).node();
                        }

                        //refresh_selected_elements()
                        tablePhotos.column().checkboxes.deselectAll()
                    })

                    lightbox = GLightbox({
                        touchNavigation: true,
                        loop: true,
                        autoplayVideos: true
                    });
                }
            })

        }

        function refresh_selected_elements() {
            rows_selected = tablePhotos.column(0).checkboxes.selected();
            
            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
          
            let txt = rows_selected.length > 1 ? `${rows_selected.length} éléments sélectionnés` : `${rows_selected.length} élément sélectionné`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        $(`#export-selected-elements`).on("click", function(e) {
            window.open('/photos/export/?ids=' + rows_selected.join(','), '_blank')
        })
    }
})