
import { data, get } from 'jquery';
import * as SMS from './send_sms'

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "worksites" && $("body").data("action") == 'show' ) {
      
        let tableAgents;
        let tableReportPhotos;
        let tableReportPhotosCamion;
        let tableReportPhotosRegular;
        let tableReportPhotosCamionRegular;
        let tableReports;
        let planning_id;
        let map
        let type_report

        document.addEventListener("turbolinks:before-cache", function() {
            SMS.initSms()
            if (tableAgents != null) {
                tableAgents.destroy();
            }
            if (tableReportPhotos != null) {
                tableReportPhotos.destroy();
            }
            if (tableReportPhotosCamion != null) {
                tableReportPhotosCamion.destroy();
            }
            if (tableReports != null) {
                tableReports.destroy();
            }
        })

        tableAgents = $('#table-agents').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-agents_wrapper > div > .col-sm-6:nth-child(1)").remove()

        tableReports = $('#table-reports').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[3,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-reports_wrapper > div > .col-sm-6:nth-child(1)").remove()
        
        tableReportPhotos = $('#table-report_photos').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[3,'asc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-report_photos_wrapper > div > .col-sm-6:nth-child(1)").remove()
        
        tableReportPhotosCamion = $('#table-report_photos_camion').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-report_photos_camion_wrapper > div > .col-sm-6:nth-child(1)").remove()
        
        tableReportPhotosRegular = $('#table-report_photos_regular').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[3,'asc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-report_photos_regular_wrapper > div > .col-sm-6:nth-child(1)").remove()
        
        tableReportPhotosCamionRegular = $('#table-report_photos_camion_regular').DataTable({
            "bPaginate": false,
            "bFilter": false,
            "bSearching": false,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-report_photos_regular_camion_wrapper > div > .col-sm-6:nth-child(1)").remove()

        var enableDays = $("#planning-days").val().split(', ')
        var enableDaysRegular = $("#planning-days").val().split(', ')
        var enableCurrentMonth = $("#planning-current_month").val().split(', ')
        var enableCurrentMonthRegular = $("#planning-current_month_regular").val().split(', ')

        function formatDate(d) {
            var day = String(d.getDate())
            if (day.length == 1)
              day = '0' + day
            var month = String((d.getMonth()+1))
            if (month.length == 1)
              month = '0' + month
            return day + "-" + month + "-" + d.getFullYear()
        }

        $("#worksite-planning-dates, #report-photo-dates").datepicker({
            format: 'dd/mm/yyyy',
            autoclose: false,
            multidate: true,
            clearBtn: true,
            beforeShowDay: function(date){
                if (enableDays.indexOf(formatDate(date)) < 0)
                  return {
                    enabled: false
                  }
                else
                  return {
                    enabled: true
                }
            }
        })

        $("#worksite-planning-dates_regular").datepicker({
            format: 'dd/mm/yyyy',
            autoclose: false,
            multidate: true,
            clearBtn: true
            // beforeShowDay: function(date){
            //     if (enableDaysRegular.indexOf(formatDate(date)) < 0)
            //       return {
            //         enabled: false
            //       }
            //     else
            //       return {
            //         enabled: true
            //     }
            // }
        })

        $("#worksite-planning-dates, #report-photo-dates").datepicker("setDates", enableCurrentMonth)
        $("#worksite-planning-dates_regular").datepicker("setDates", enableCurrentMonthRegular)

        $("#worksite-planning-dates").on("hide", function() {
            getReportPhotos()
        })

        $("#worksite-planning-dates_regular").on("hide", function() {
            getReportPhotosRegular()
        })

        //$(".glightbox").gLightbox()
        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
            autoplayVideos: true
        });


        // $('.gallery-item > img').on('click', function(e) {

        //     $(".item-slideshow > div").css({
        //         'background-image': 'url(' + $(this).attr('data-photo') + ')',
        //         'background-size': 'cover'
        //     })

        //     var dlg = new DialogFx($('#itemDetails').get(0));
        //     dlg.toggle();
        // });

        $("#btn-worksite-state").on("click", function(e) {
            let text;
            if ($(e.target).data('state') == 'in_progress') {
                text = `Voulez-vous vraiment changer l'etat de ce chantier de 'En cours' vers 'Terminé'? (Une fois terminé, vous ne pouvez plus changer l'etat)`

                Swal.fire({
                    title: "Chantier",
                    icon: "warning",
                    text: text,
                    showCancelButton: true,
                    cancelButtonText: 'Annuler',
                    confirmButtonText: 'Oui',
                }).then(result => {
                    if (result.isConfirmed) {
                        if ($(e.target).data('state') == 'in_progress') {
                            $(e.target).attr("data-state", "finished")
                            $(e.target).data("state", "finished")
                            changeState($("#worksite_id").val(), 1)
                            $("#btn-worksite-state").html("<span data-state='finished' class='badge badge-success'>Terminé</span>")
                        }
                    }
                });
            }
            
        })
        
        // open list agents
        $('.btn-show-agents').on("click", function (e) {
            $('#modal-worksite-agents').modal({
                backdrop: 'static',
                keyboard: false  
            })

            showAgents($(e.currentTarget).data('planning_id'), $(e.currentTarget).data('planning_type'))
        });

        
        // open send sms modal
        $('#modal-send_sms').on('show.bs.modal', function(e) { 
            $(".sms-loading").removeClass("hidden")
            planning_id = $(e.relatedTarget).data('planning_id')
            SMS.setPlanningId(planning_id)
            SMS.showSMSAgents($(e.relatedTarget).data('planning_id'), 'plannings')
            
            //SMS.calcMessageSmsLength()

            initMessage()
        });

        // open send sms modal
        $('#modal-send_sms').on('hide.bs.modal', function(e) { 
            $("#btn-send_sms").attr("disabled", true)
        })

        // select agent/planning rapport photo
        $("#worksite-photo-agent").on("change", function(e) {
            // tableReportPhotos.rows().clear().draw();
            //tableReportPhotosCamion.rows().clear().draw();
            getReportPhotos()
        })

        $("#worksite-photo-agent_regular").on("change", function(e) {
            getReportPhotosRegular()
        })

        // open generate report photo
        $("#btn-report-photo").on("click", function() {
            $('#modal-generate-photo').modal({
                backdrop: 'static',
                keyboard: false  
            })

            $("#report-photo-dates").datepicker("setDates", $("#worksite-planning-dates").datepicker("getDates"))

            let selected_agents = $("#worksite-photo-agent").select2('data')
            let selected_agents_ids = []
            jQuery.each(selected_agents, function (i, val) {
                selected_agents_ids.push(val.id)
            })

            type_report = 'punctual'

            $("#report-photo-agents").val(selected_agents_ids).trigger('change')
        })

        $("#btn-report-photo_regular").on("click", function() {
            $('#modal-generate-photo').modal({
                backdrop: 'static',
                keyboard: false  
            })

            $("#report-photo-dates").datepicker("setDates", $("#worksite-planning-dates_regular").datepicker("getDates"))

            let selected_agents = $("#worksite-photo-agent_regular").select2('data')
            let selected_agents_ids = []
            jQuery.each(selected_agents, function (i, val) {
                selected_agents_ids.push(val.id)
            })

            type_report = 'regular'

            $("#report-photo-agents").val(selected_agents_ids).trigger('change')
        })

        $("input[name='report-photo-type']").on("click", function() {
            if ($(this).val() == 'Prestation')
                $("#report-photo_camion").attr("disabled", false)
            else 
                $("#report-photo_camion").attr("disabled", true)
        })

        $("#modal-generate-photo").on('shown.bs.modal', function(e) { 
            $("input[name='planning_type']").val(type_report)
        })

        $("#modal-generate-photo").on('hide.bs.modal', function(e) { 
            // $.ajax({
            //     url: `${HOST_API}/worksites/reports/${$('#worksite_id').val()}`,
            //     type: 'GET',
            //     suppressGlobalErrorHandler: true,
            //     beforeSend: function (xhr) {
            //         xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
            //     },
            //     dataType: "json",
            //     success: function (data) {
            //         tableReports.rows().clear().draw();

            //         jQuery.each(data, function (i, val) {
            //             let newRow = tableReports.row.add( [
            //                 val.type_report,
            //                 val.dates.split(',').map(date => `<span class='label'>${date}</span>`).join(' '),
            //                 moment(val.created_at).format("LLL"),
            //             ] ).draw( false ).node();

            //             $(newRow).attr("id", `worksite-report-` + val.id);
            //             $(newRow).attr("data-report_id", val.id);
            //             $(newRow).attr("class", 'worksite-report');

            //             $(`#worksite-report-` + val.id).on("click", function(e) {
            //                 window.open('/worksites/report/' + val.id, '_blank')
            //             })
            //         })
                    
            //     }
            // })
        })

        $("#btn-generate-public-report").on("click", function() {
            $("#form-report-photo").validate()
            $('#form-report-photo').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-report-photo").valid()) {

                $("#btn-generate-public-report").prepend("<img src='/assets/img/loading2.svg' width='32'>")
                $("#btn-generate-public-report").attr('disabled', true)

                let dates = $("#report-photo-dates").datepicker("getDates")
                dates = dates.map(d => moment(d).format('YYYY-MM-DD')) 

                let workers = '?workers=' + $("#report-photo-agents").val().join(',')

                let formData = new FormData();
                formData.append('worksite_id', $("#worksite_id").val());
                formData.append('dates', dates);
                formData.append('workers_id', $("#report-photo-agents").val().join(','));
                formData.append('type', $("input[name='report-photo-type']:checked").val());
                formData.append('include_truck', $("#report-photo_camion").prop('checked'));
                formData.append('show_dates_photo', $("#report-photo-show_date").prop('checked'));
                formData.append('num_quote', $("#report-bill_number").val());
                formData.append('template', $("input[name='report-photo-template']:checked").val());
                formData.append('dates', dates);
                formData.append('message', $("#report-photo-message").val());
                formData.append('planning_type', type_report);

                fetch(`/reports`, {
                    method: 'POST',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $("#btn-generate-public-report img").remove()
                            $("#btn-generate-public-report").attr('disabled', false)

                            let newRow = tableReports.row.add( [
                                type_report,
                                data.type_report,
                                data.template,
                                data.dates.split(',').map(date => `<span class='label'>${date}</span>`).join(' '),
                                moment(data.created_at).format("LLL"),
                            ] ).draw( false ).node();
    
                            $(newRow).attr("id", `worksite-report-` + data.id);
                            $(newRow).attr("data-report_id", data.id);
                            $(newRow).attr("class", 'worksite-report');
                            $(newRow).attr("data-report_uniq_id", data.uniq_id);
                            
                            let link;

                            if (type_report == 'punctual') {
                                $(`#worksite-report-` + data.id).on("click", function(e) {
                                    window.open('/reports/client-report/' + $(this).data('report_uniq_id'), '_blank')
                                })
    
                                link = '/reports/client-report/' + data.uniq_id
                            }
                            else {
                                $(`#worksite-report-` + data.id).on("click", function(e) {
                                    window.open('/reports/client-report-regular/' + $(this).data('report_uniq_id'), '_blank')
                                })
    
                                link = '/reports/client-report-regular/' + data.uniq_id
                            }

                            window.open(link, '_blank')
                        })
                    }
                })
            }
        })

        $("#btn-generate-report-photo").on("click", function() {
      
            $("#form-report-photo").validate()
            $('#form-report-photo').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-report-photo").valid()) {
                let worker_ids = [];
                for(a=0 ; a<$("#worksite-photo-agent").select2('data').length ; a++) {
                    worker_ids.push($("#worksite-photo-agent").select2('data')[a].id)
                }
    
                let dates = $("#worksite-planning-dates").datepicker("getDates")
                dates = dates.map(d => moment(d).format('YYYY-MM-DD')) 
    
                let planning_id = $("#worksite-planning option:selected").val()
                let show_date = $("#report-photo-show_date").attr("checked")

                let data = {};
                if (dates.length != 0)
                    data['dates'] = dates
                if (planning_id != "")
                    data['planning_id'] = planning_id
    
                data['worker_ids'] = worker_ids
                data['show_date'] = show_date
                
                $.redirect(`${HOST_API}/worksites/report_photo/${$("#worksite_id").val()}`,
                    {
                        user_name: "khan",
                        city : "Meerut",
                        country : "country"
                    });

                // $.ajax({
                //     url: `${HOST_API}/worksites/report_photo/${$("#worksite_id").val()}`,
                //     type: 'POST',
                //     data: data,
                //     suppressGlobalErrorHandler: true,
                //     beforeSend: function (xhr) {
                //         xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                //     },
                //     dataType: "json",
                //     success: function (data) {

                //     }
                // })
            }
        })

        $(".worksite-report").on("click", function(e) {
            if ($(this).data('planning_type') == 'ponctuel') {
                window.open('/reports/client-report/' + $(this).data('report_uniq_id'), '_blank')
            }
            else if($(this).data('planning_type') == 'régulier') {
                window.open('/reports/client-report-regular/' + $(this).data('report_uniq_id'), '_blank')
            }
            
        })

        $(".btn-delete-prestation").on("click", function() {
            deletePrestation($(this).data("id"))
        })

        $(".btn-delete-prestation_regular").on("click", function() {
            deletePrestationRegular($(this).data("id"))
        })

        async function InitPhotos() {
            await  FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImageTransform, FilePondPluginImageResize, FilePondPluginFileRename, FilePondPluginImageCrop);
     
            $(".upload_photo_before").each(function(e){ 
                let id = $(this).attr("id")
                let url = '/plannings/upload-photo'

                if ($(`#${id}`).parent().data('planning_type') == 'regular')
                    url = '/planning_regulars/upload-photo'
              
                var photo_before = FilePond.create(document.querySelector(`#${$(this).attr("id")}`), {
                    acceptedFileTypes: ['image/*'],
                    labelIdle: "Photo pas encore uploader!<br>Choisir une photo",
                    allowProcess: true,
                    instantUpload: true,
                    imageResizeMode: 'cover',
                    allowImageResize: false,
                    imageResizeTargetWidth: 128,
                    imageResizeTargetHeight: 128,
                    name: 'worksite[upload_photo_before]',
                    storeAsFile: true,
                    credits: {label: "", url: ""} ,
                    maxFileSize: 5000000,
                    allowImageCrop: false,
                    imageCropAspectRatio: '1:1',
                    server: {
                        url: `${HOST_API}`,
                        process: {
                            url: url,
                            method: 'POST',
                            withCredentials: false,
                            headers: new Headers({
                                'Authorization': `Bearer ${$("#user_token").val()}`
                            }),
                            timeout: 7000,
                            onload: null,
                            onerror: null,
                            ondata: (formData) => {
                                formData.append('photo_id', $(`#${id}`).parent().data('photo_id'));
                                formData.append('type_photo', "photo_before");
                                return formData;
                            },
                        },
                    }
                });
            })

            $(".upload_photo_after").each(function(e){ 
                console.log($(this).attr("id"))
                let id = $(this).attr("id")
                let url = '/plannings/upload-photo'

                if ($(`#${id}`).parent().data('planning_type') == 'regular')
                    url = '/planning_regulars/upload-photo'

                //$(this).parent().data("photo_id")

                var photo_after = FilePond.create(document.querySelector(`#${$(this).attr("id")}`), {
                    acceptedFileTypes: ['image/*'],
                    labelIdle: "Photo pas encore uploader!<br>Choisir une photo",
                    allowProcess: true,
                    instantUpload: true,
                    imageResizeMode: 'cover',
                    allowImageResize: false,
                    imageResizeTargetWidth: 128,
                    imageResizeTargetHeight: 128,
                    name: 'worksite[upload_photo_after]',
                    storeAsFile: true,
                    credits: {label: "", url: ""} ,
                    maxFileSize: 5000000,
                    allowImageCrop: false,
                    imageCropAspectRatio: '1:1',
                    server: {
                        url: `${HOST_API}`,
                        process: {
                            url: url,
                            method: 'POST',
                            withCredentials: false,
                            headers: new Headers({
                                'Authorization': `Bearer ${$("#user_token").val()}`
                            }),
                            timeout: 7000,
                            onload: null,
                            onerror: null,
                            ondata: (formData) => {
                                formData.append('photo_id', $(`#${id}`).parent().data('photo_id'));
                                formData.append('type_photo', "photo_after");
                                return formData;
                            },
                        },
                    }
                });
            })
        }
      

        function deletePrestation(id) {
            let url = `${HOST_API}/worksites/delete-prestation/${id}`;

            Swal.fire({
                text: "Supprimer cette préstation ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`
                        }),
                    }).then(function(response) {
                        if (response.ok) {
                            //$(`#report_photo-${id}`).remove()
                            
                            tableReportPhotos.row($(`tr[id='report_photo-${id}']`)).remove().draw();
                        }
                    })
                }
            })
        }

        function deletePrestationRegular(id) {
            let url = `${HOST_API}/worksites/delete-prestation-regular/${id}`;

            Swal.fire({
                text: "Supprimer cette préstation ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`
                        }),
                    }).then(function(response) {
                        if (response.ok) {
                            //$(`#report_photo-${id}`).remove()
                            
                            tableReportPhotosRegular.row($(`tr[id='report_photo-${id}']`)).remove().draw();
                        }
                    })
                }
            })
        }

        $(".btn-delete-camion").on("click", function() {
            deleteCamion($(this).data("id"))
        })

        $(".btn-delete-camion_regular").on("click", function() {
            deleteCamionRegular($(this).data("id"))
        })

        function deleteCamion(id) {
            let url = `${HOST_API}/worksites/delete-camion/${id}`;

            Swal.fire({
                text: "Supprimer cet camion ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`
                        }),
                    }).then(function(response) {
                        if (response.ok) {
                            tableReportPhotosCamion.row($(`tr[id='report_photo-${id}']`)).remove().draw();
                        }
                    })
                }
            })
        }

        function deleteCamionRegular(id) {
            let url = `${HOST_API}/worksites/delete-camion-regular/${id}`;

            Swal.fire({
                text: "Supprimer cet camion ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`
                        }),
                    }).then(function(response) {
                        if (response.ok) {
                            console.log(id)
                            tableReportPhotosCamionRegular.row($(`tr[id='report_photo_regular-${id}']`)).remove().draw();
                        }
                    })
                }
            })
        }

        function showAgents(id, planning_type, date) {

            let url = `${HOST_API}/worksites/plannings`

            if (planning_type == 'regular')
                url = `${HOST_API}/worksites/plannings-regular`
            
            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    id: id
                },
                dataType: "json",
                success: function (data) {

                    tableAgents.rows().clear().draw();

                    if (planning_type == 'regular') {

                        $(".total-personne").text(data.planning_regular_workers.length)
                    
                        jQuery.each(data.planning_regular_workers, function (i, val) {
                            let pointing;
                         
                            if (val.pointing.length > 0) {
                                jQuery.each(val.pointing, function (i, point) {
                                    if (point.date == date) {
                                        pointing = `<span class='badge badge-success'>Validé (${moment(point.created_at).format("HH:mm:ss")})</span>`
                                    
                                        return false
                                    }
                                    else{
                                        pointing = `<a href='javascript:void(0)' class='agent-validate-pointing agent-pointing-${val.worker.id}' data-planning_type='${planning_type}' data-agent_id='${val.worker.id}' title='Valider le pointage de cet agent'><span class='badge'>Pas encore validé</span></a>`
                                    }
                                })
                            }
                            else {
                                pointing = `<a href='javascript:void(0)' class='agent-validate-pointing agent-pointing-${val.worker.id}' data-planning_type='${planning_type}' data-agent_id='${val.worker.id}' title='Valider le pointage de cet agent'><span class='badge'>Pas encore validé</span></a>`
                            }

                            // table agents
                            let newRow = tableAgents.row.add( [
                                val.worker.name,
                                pointing
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `agent-` + val.id);
                        })

                        $(".agent-validate-pointing").on("click", function(e) {
                            pointing($(this).data("agent_id"), data.planning_regular_workers[0].planning_regular_id, planning_type, date)
                        })
                    }
                    else {
                        $(".total-personne").text(data.planning_workers.length)
                       
                        jQuery.each(data.planning_workers, function (i, val) {
                            let pointing;
                            if (val.pointing != null) {
                                pointing = `<span class='badge badge-success'>Validé (${moment(val.pointing.created_at).format("HH:mm:ss")})</span>`
                            }
                            else {
                                pointing = `<a href='javascript:void(0)' class='agent-validate-pointing agent-pointing-${val.worker.id}' data-planning_type='${planning_type}' data-agent_id='${val.worker.id}' title='Valider le pointage de cet agent'><span class='badge'>Pas encore validé</span></a>`
                            }
            
                            // table agents
                            let newRow = tableAgents.row.add( [
                                val.worker.name,
                                pointing
                            ] ).draw( false ).node();
                            
                            $(newRow).attr("id", `agent-` + val.id);
                        })

                        $(".agent-validate-pointing").on("click", function(e) {
                            pointing($(this).data("agent_id"), data.planning_workers[0].planning_id, planning_type, null)
                        })
                    }
                    

                    $(".planning-date").text(moment(date).format("LL"))
                    

                }
            })
        }

        function pointing(agent_id, planning_id, planning_type, date) {
     
            Swal.fire({
                title: "Pointage",
                icon: "warning",
                text: "Voulez-vous valider le pointage de cet agent?",
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    let url = `${HOST_API}/plannings/pointing`
                    if (planning_type == 'regular')
                        url = `${HOST_API}/planning_regulars/pointing`

                    $(".agent-pointing-" + agent_id).html("<img src='/assets/img/loading.svg' class='worksite-loader' width='32'>")
                    
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: {
                            planning_id: planning_id,
                            worker_id: agent_id,
                            date: date
                        },
                        suppressGlobalErrorHandler: true,
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                        },
                        dataType: "json",
                        success: function (data) {
                            $(".agent-pointing-" + agent_id).html(`<span class='badge badge-success'>Validé (${moment(data.created_at).format("HH:mm:ss")})</span>`)
                            $(".agent-pointing-" + agent_id).off( "click", "");
                        }
                    })
                }
            });
        }

        function getReportPhotos() {
            tableReportPhotos.rows().clear().draw();
            tableReportPhotosCamion.rows().clear().draw();
            $("#table-report_photos_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
            $("#table-report_photos_camion_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            let worker_ids = [];
            for(let a=0 ; a<$("#worksite-photo-agent").select2('data').length ; a++) {
                worker_ids.push($("#worksite-photo-agent").select2('data')[a].id)
            }

            let dates = $("#worksite-planning-dates").datepicker("getDates")
            dates = dates.map(d => moment(d).format('YYYY-MM-DD')) 

            let planning_id = $("#worksite-planning option:selected").val()
            
            let data = {};
            if (dates.length != 0)
                data['dates'] = dates
            if (planning_id != "")
                data['planning_id'] = planning_id

            data['worker_ids'] = worker_ids

            $.ajax({
                url: `${HOST_API}/plannings/report_photos/${$("#worksite_id").val()}`,
                type: 'GET',
                data: data,
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableReportPhotos.rows().clear().draw();
                    tableReportPhotosCamion.rows().clear().draw();
                    let photo_before = null
                    let photo_after = null
                    jQuery.each(data, function (i, val) { 
                        if (!val.camion) {
                            if (val.photo_before != null) {
                                photo_before = `<div class='worksite-photos-info'>
                                    <a href='${val.photo_before}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_before}' data-photo='${val.photo_before}' alt='photo' class='image-responsive-height'>
                                            <div class="overlayer bottom-left full-width">
                                                <div class="overlayer-wrapper item-info ">
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`
                            }
                            else {
                                //photo_before = "<b>Photo pas encore uploader</b>"
                                photo_before = `<div style='width: 100%;' data-photo_id="${val.id}" data-planning_type='punctual'>
                                              <input type='file' name='worksite[upload_photo_before_${val.id}]' id="worksite_upload_photo_before_${val.id}" class="upload_photo_before"></div>`
                            }

                            if (val.photo_after != null) {
                                photo_after = `<div class='worksite-photos-info'>
                                    <a href='${val.photo_after}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_after}' data-photo='${val.photo_after}' alt='photo' class='image-responsive-height'>
                                            <div class="overlayer bottom-left full-width">
                                                <div class="overlayer-wrapper item-info ">
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`
                            }
                            else {
                                //photo_after = "<b>Photo pas encore uploader</b>"
                                photo_after = `<div style='width: 100%;' data-photo_id="${val.id}" data-planning_type='punctual'>
                                              <input type='file' name='worksite[upload_photo_after_${val.id}]' id="worksite_upload_photo_after_${val.id}" class="upload_photo_after"></div>`
                                              
                            }
                            
                            let newRow = tableReportPhotos.row.add( [
                                val.worker.name,
                                photo_before,
                                photo_after,
                                moment(val.created_at).format("LLL"),
                                `<div class="form-check checkbox-circle complete">
                                    <input type="checkbox" name="worksite-photo_inverse" id="worksite-photo_inverse-${val.id}" data-planning_photo_id="${val.id}" ${val.inverse == true ? 'checked' : ''} >
                                    <label for="worksite-photo_inverse-${val.id}"></label>
                                </div>`,
                                `<div class="form-check checkbox-circle complete">
                                    <input type="checkbox" name="worksite-photo_include" id="worksite-photo_include-${val.id}" data-planning_photo_id="${val.id}" ${val.no_include == true ? 'checked' : ''} >
                                    <label for="worksite-photo_include-${val.id}"></label>
                                </div>`,
                                `<a href='javascript:void(0)' title='Supprimer' class='btn-delete-prestation' data-id='${val.id}'><span class="iconify" data-icon="mdi:trash-can" data-width="18" data-height="18"></span>`
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `report_photo-` + val.id);
                            
                            
                            InitPhotos()

                            $(".btn-delete-prestation").on("click", function(e) {
                                deletePrestation($(e.currentTarget).data('id'))
                            })

                            $("#worksite-photo_inverse-" + val.id).on("click", function() {
                                $.ajax({
                                    url: `${HOST_API}/worksites/photo_feature/${val.id}`,
                                    type: 'PUT',
                                    data: {
                                        inverse: $(this).prop("checked")
                                    },
                                    suppressGlobalErrorHandler: true,
                                    beforeSend: function (xhr) {
                                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                                    },
                                    dataType: "json",
                                    success: function (data) {
                                    }
                                })
                            })
                    
                            $("#worksite-photo_include-" + val.id).on("click", function() {
                                $.ajax({
                                    url: `${HOST_API}/worksites/photo_feature/${val.id}`,
                                    type: 'PUT',
                                    data: {
                                        no_include: $(this).prop("checked")
                                    },
                                    suppressGlobalErrorHandler: true,
                                    beforeSend: function (xhr) {
                                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                                    },
                                    dataType: "json",
                                    success: function (data) {
                        
                                    }
                                })
                            })
                            
                        }
                        else {
                            let photo_after = ''
                            
                            if (val.photo_after != null) {
                                photo_after = `<div class="worksite-photos-info">
                                    <a href='${val.photo_after}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_after}' data-photo='${val.photo_after}' alt='photo' class='image-responsive-height'>
                                            <div class="overlayer bottom-left full-width">
                                                <div class="overlayer-wrapper item-info ">
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`
                            }

                            let newRow = tableReportPhotosCamion.row.add( [
                                val.worker.name,
                                photo_after,
                                moment(val.created_at).format("LL"),
                                `<div class="form-check checkbox-circle complete">
                                    <input type="checkbox" name="worksite-photo_include" id="worksite-photo_include-${val.id}" data-planning_photo_id="${val.id}" ${val.no_include == true ? 'checked' : ''} >
                                    <label for="worksite-photo_include-${val.id}"></label>
                                </div>`,
                                `<a href='javascript:void(0)' title='Supprimer' class='btn-delete-camion' data-id='${val.id}'><span class="iconify" data-icon="mdi:trash-can" data-width="18" data-height="18"></span>`
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `report_photo-` + val.id);

                            $(".btn-delete-camion").on("click", function() {
                                deletePrestation(val.id)
                            })

                            $("#worksite-photo_include-" + val.id).on("click", function() {
                                $.ajax({
                                    url: `${HOST_API}/worksites/photo_feature/${val.id}`,
                                    type: 'PUT',
                                    data: {
                                        no_include: $(this).prop("checked")
                                    },
                                    suppressGlobalErrorHandler: true,
                                    beforeSend: function (xhr) {
                                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                                    },
                                    dataType: "json",
                                    success: function (data) {
                        
                                    }
                                })
                            })
                        }

                        const lightbox = GLightbox({
                            touchNavigation: true,
                            loop: true,
                            autoplayVideos: true
                        });
                    })
                }
            })

           
        }

        function getReportPhotosRegular() {
            tableReportPhotosRegular.rows().clear().draw();
            tableReportPhotosCamionRegular.rows().clear().draw();
            $("#table-report_photos_regular_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")
            $("#table-report_photos_camion_regular_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            let worker_ids = [];
            for(let a=0 ; a<$("#worksite-photo-agent_regular").select2('data').length ; a++) {
                worker_ids.push($("#worksite-photo-agent_regular").select2('data')[a].id)
            }

            let dates = $("#worksite-planning-dates_regular").datepicker("getDates")
            dates = dates.map(d => moment(d).format('YYYY-MM-DD')) 

            let planning_id = $("#worksite-planning option:selected").val()
            
            let data = {};
            if (dates.length != 0)
                data['dates'] = dates
            if (planning_id != "")
                data['planning_id'] = planning_id

            data['worker_ids'] = worker_ids

            $.ajax({
                url: `${HOST_API}/planning_regulars/report_photos/${$("#worksite_id").val()}`,
                type: 'GET',
                data: data,
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableReportPhotosRegular.rows().clear().draw();
                    tableReportPhotosCamionRegular.rows().clear().draw();
                    let photo_before = null
                    let photo_after = null
                    jQuery.each(data, function (i, val) { 
                        if (!val.camion) {
                            if (val.photo_before != null) {
                                photo_before = `<div class='worksite-photos-info'>
                                    <a href='${val.photo_before}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_before}' data-photo='${val.photo_before}' alt='photo' class='image-responsive-height'>
                                            <div class="overlayer bottom-left full-width">
                                                <div class="overlayer-wrapper item-info ">
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`
                            }
                            else {
                                //photo_before = "<b>Photo pas encore uploader</b>"
                                photo_before = `<div style='width: 100%;' data-photo_id="${val.id}" data-planning_type='regular'>
                                              <input type='file' name='worksite[upload_photo_before_${val.id}]' id="worksite_upload_photo_before_${val.id}" class="upload_photo_before"></div>`
                            }

                            if (val.photo_after != null) {
                                photo_after = `<div class='worksite-photos-info'>
                                    <a href='${val.photo_after}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_after}' data-photo='${val.photo_after}' alt='photo' class='image-responsive-height'>
                                            <div class="overlayer bottom-left full-width">
                                                <div class="overlayer-wrapper item-info ">
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`
                            }
                            else {
                                //photo_after = "<b>Photo pas encore uploader</b>"
                                photo_after = `<div style='width: 100%;' data-photo_id="${val.id}" data-planning_type='regular'>
                                              <input type='file' name='worksite[upload_photo_after_${val.id}]' id="worksite_upload_photo_after_${val.id}" class="upload_photo_after"></div>`
                            }

                            let newRow = tableReportPhotosRegular.row.add( [
                                val.worker.name,
                                photo_before,
                                photo_after,
                                moment(val.created_at).format("LLL"),
                                `<div class="form-check checkbox-circle complete">
                                    <input type="checkbox" name="worksite-photo_inverse" id="worksite-photo_inverse_regular-${val.id}" data-planning_photo_id="${val.id}" ${val.inverse == true ? 'checked' : ''} >
                                    <label for="worksite-photo_inverse_regular-${val.id}"></label>
                                </div>`,
                                `<div class="form-check checkbox-circle complete">
                                    <input type="checkbox" name="worksite-photo_include_regular" id="worksite-photo_include_regular-${val.id}" data-planning_photo_id="${val.id}" ${val.no_include == true ? 'checked' : ''} >
                                    <label for="worksite-photo_include_regular-${val.id}"></label>
                                </div>`,
                                `<a href='javascript:void(0)' title='Supprimer' class='btn-delete-prestation_regular' data-id='${val.id}'><span class="iconify" data-icon="mdi:trash-can" data-width="18" data-height="18"></span>`
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `report_photo_regular-` + val.id);
                            
                            $(".btn-delete-prestation_regular").on("click", function(e) {
                                deletePrestationRegular($(e.currentTarget).data('id'))
                            })

                            $("#worksite-photo_inverse_regular-" + val.id).on("click", function() {
                                $.ajax({
                                    url: `${HOST_API}/worksites/photo_feature_regular/${val.id}`,
                                    type: 'PUT',
                                    data: {
                                        inverse: $(this).prop("checked")
                                    },
                                    suppressGlobalErrorHandler: true,
                                    beforeSend: function (xhr) {
                                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                                    },
                                    dataType: "json",
                                    success: function (data) {
                                    }
                                })
                            })
                    
                            $("#worksite-photo_include_regular-" + val.id).on("click", function() {
                                $.ajax({
                                    url: `${HOST_API}/worksites/photo_feature_regular/${val.id}`,
                                    type: 'PUT',
                                    data: {
                                        no_include: $(this).prop("checked")
                                    },
                                    suppressGlobalErrorHandler: true,
                                    beforeSend: function (xhr) {
                                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                                    },
                                    dataType: "json",
                                    success: function (data) {
                        
                                    }
                                })
                            })
                        }
                        else {
                            let photo_after = ''
                            
                            if (val.photo_after != null) {
                                photo_after = `<div class="worksite-photos-info">
                                    <a href='${val.photo_after}' class='glightbox gallery-images' data-type='image' data-gallery="gallery-photo_${val.id}">
                                        <div class='gallery-item m-2' data-width="1" data-height="1" ><img src='${val.photo_after}' data-photo='${val.photo_after}' alt='photo' class='image-responsive-height'>
                                            <div class="overlayer bottom-left full-width">
                                                <div class="overlayer-wrapper item-info ">
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`
                            }

                            let newRow = tableReportPhotosCamionRegular.row.add( [
                                val.worker.name,
                                photo_after,
                                moment(val.created_at).format("LL"),
                                `<div class="form-check checkbox-circle complete">
                                    <input type="checkbox" name="worksite-photo_include_regular" id="worksite-photo_include_regular-${val.id}" data-planning_photo_id="${val.id}" ${val.no_include == true ? 'checked' : ''} >
                                    <label for="worksite-photo_include_regular-${val.id}"></label>
                                </div>`,
                                `<a href='javascript:void(0)' title='Supprimer' class='btn-delete-camion_regular' data-id='${val.id}'><span class="iconify" data-icon="mdi:trash-can" data-width="18" data-height="18"></span>`
                            ] ).draw( false ).node();

                            $(newRow).attr("id", `report_photo_regular-` + val.id);

                            $(".btn-delete-camion_regular").on("click", function() {
                                deletePrestationRegular(val.id)
                            })

                            $("#worksite-photo_include_regular-" + val.id).on("click", function() {
                                $.ajax({
                                    url: `${HOST_API}/worksites/photo_feature_regular/${val.id}`,
                                    type: 'PUT',
                                    data: {
                                        no_include: $(this).prop("checked")
                                    },
                                    suppressGlobalErrorHandler: true,
                                    beforeSend: function (xhr) {
                                        xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                                    },
                                    dataType: "json",
                                    success: function (data) {

                                    }
                                })
                            })
                        }

                        const lightbox = GLightbox({
                            touchNavigation: true,
                            loop: true,
                            autoplayVideos: true
                        });
                    })
                }
            })
        }

        function changeState(id, state) {
            $.ajax({
                url: `${HOST_API}/worksites/change_state/${id}`,
                type: 'PUT',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    state: state
                },
                dataType: "json",
                success: function (data) {
                    $(".rate_link").html(`<a href='${window.location.origin}/surveys/${data.code}' title='Accéder au lien de formulaire de satisfaction client' target='new'><span class='label'>${window.location.origin}/surveys/${data.code}</span></a>`)
                }
            })
        }


        function initMap() {
            var markers = [];
            let worksite_lat = 48.866667
            let worksite_long = 2.333333

            if ($("#worksite-lat").val() != "") {
                worksite_lat = $("#worksite-lat").val() 
            }
            if ($("#worksite-lat").val() != "") {
                worksite_long = $("#worksite-long").val() 
            }

            map = new google.maps.Map(document.getElementById("worksite-map-canvas"), {
                center: { lat: parseInt(worksite_lat), lng: parseInt(worksite_long)},
                zoom: 5,
                mapTypeId: "roadmap",
            });

      
            new google.maps.LatLng(worksite_lat, worksite_long);
            var latLng = new google.maps.LatLng(worksite_lat, worksite_long);
            map.setCenter(latLng);
            map.setZoom(15)

            // new google.maps.Marker({
            //     position: { lat: $("#worksite-lat").val(), lng: $("#worksite-long").val() },
            //     map
            // });

            new google.maps.Marker({
                position: { lat: parseFloat(worksite_lat), lng:  parseFloat(worksite_long) },
                map
            });

        }

        function initMessage() {
            fetch(`${HOST_API}/plannings/${planning_id}/message_sms`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        //$("#send_sms-text").attr('disabled', false)
                        $("#send_sms-text").val(data.message)
                    })
                }
            })
        }
        
        function getRating() {
            fetch(`${HOST_API}/worksites/rating/${$("#worksite_id").val()}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${$("#user_token").val()}`,
                    'Content-Type': 'application/json'
                }),
                mode: 'cors',
                cache: 'default'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        if (data != null) {
                            var rater = require("rater-js");
                            var myRater = rater({element: document.querySelector("#rater"), rateCallback: function rateCallback(rating, done) {
                                    this.setRating(rating); 
                                    done(); 
                                },
                                starSize:32,
                                step:0.5,
                                rating:data.note,
                                readOnly: true
                            });
                        }
                    })
                }
            })
        }

        $("input[name='worksite-photo_inverse'").on("click", function() {
            let planning_photo_id = $(this).data("planning_photo_id")
            $.ajax({
                url: `${HOST_API}/worksites/photo_feature/${planning_photo_id}`,
                type: 'PUT',
                data: {
                    inverse: $(this).prop("checked")
                },
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
    
                }
            })
        })

        $("input[name='worksite-photo_include'").on("click", function() {
            let planning_photo_id = $(this).data("planning_photo_id")
            $.ajax({
                url: `${HOST_API}/worksites/photo_feature/${planning_photo_id}`,
                type: 'PUT',
                data: {
                    no_include: $(this).prop("checked")
                },
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
    
                }
            })
        })

        $("input[name='worksite-photo_inverse_regular'").on("click", function() {
            let planning_photo_id = $(this).data("planning_photo_id")
            $.ajax({
                url: `${HOST_API}/worksites/photo_feature_regular/${planning_photo_id}`,
                type: 'PUT',
                data: {
                    inverse: $(this).prop("checked")
                },
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
    
                }
            })
        })

        $("input[name='worksite-photo_include_regular'").on("click", function() {
            let planning_photo_id = $(this).data("planning_photo_id")
            $.ajax({
                url: `${HOST_API}/worksites/photo_feature_regular/${planning_photo_id}`,
                type: 'PUT',
                data: {
                    no_include: $(this).prop("checked")
                },
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
    
                }
            })
        })

        function getRegularPlannings() {

            
            let plannings = []
            let colors = ['#008744', '#0057e7', '#d62d20', '#ffa700', '#000', '#9d00ff']
            
            fetch(`/planning_regulars/${$("#worksite_id").val()}`, {
                method: 'GET',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let calendarEl = document.getElementById('calendar-regular');
                        let i = 0;

                        jQuery.each(data, function (i, val) {
                            
                            for (var d = new Date(val.date_start); d <= new Date(val.date_end); d.setDate(d.getDate() + 1)) {
                                
                                let day = {
                                    id: val.id,
                                    title: val.planning_regular_workers.length + ' agents',
                                    start: moment(d).format("YYYY-MM-DD"),
                                    color: colors[i],
                                    description: "ddddddddd"
                                }

                                plannings.push(day)
                            }

                            i +=1
                        })
                        
                        //console.log(plannings)
                        var calendar = new FullCalendar.Calendar(calendarEl, {
                            initialView: 'dayGridMonth',
                            events: plannings,
                            eventClick: function(info) {
                                $('#modal-worksite-agents').modal({
                                    backdrop: 'static',
                                    keyboard: false  
                                })
                                
                                showAgents(info.event.id, 'regular', moment(info.event.start).format("YYYY-MM-DD"))
                            }
                        });

                        calendar.render();
            
                        $('#tab-planning_regulars').removeClass('active')

                    })
                }
            })
           
        }


        initMap()
        getRating()
        SMS.initSms()
        InitPhotos()
        getRegularPlannings()
    }
})