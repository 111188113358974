// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'; 
import 'bootstrap/dist/js/bootstrap'
// import 'bootstrap/dist/css/bootstrap'

// Global
require("jquery");
require("popper.js");
require("select2")
require("sweetalert2")
require("js-loading-overlay")
//require("bootstrap-sass")
require("jquery-validation")
require("packs/general/jquery.scrollbar.min")
require("packs/general/interact.min")
require("packs/general/classie")
require("packs/general/sidebar")
require("packs/general/bootstrap-datepicker")
require("packs/general/bootstrap-datepicker.fr.min")
require("packs/general/bootstrap-timepicker.min")
require("packs/general/selectFx")
require("packs/general/pages")
require("packs/general/jquery.dataTables.min")
require("packs/general/dataTables.bootstrap")
require("dropzone")
require("packs/general/MetroJs.min")
require("packs/general/imagesloaded.pkgd.min")
//require("packs/general/isotope.pkgd.min")
require('packs/general/dialogFx')
//require('packs/app/rater')
require('packs/general/rater-js')
//require("datatables")
//require("packs/general/bootstrap.min")
require("packs/general/jBox.all")
require("packs/general/dataTables.checkboxes.min")
require("packs/general/datetime")
require("packs/general/datetime-moment")
//require("jspdf")

// App
//require("packs/app/send_sms")
require("packs/app/type_materials")
require("packs/app/skills")
require("packs/app/contacts")
require("packs/app/users")
require("packs/app/regions")
require("packs/app/materials")
require("packs/app/worksites")
require("packs/app/worksite")
require("packs/app/plannings")
require("packs/app/planning_regulars")
require("packs/app/reports")
require("packs/app/surveys")
require("packs/app/profile")
require("packs/app/categories")
require("packs/app/notifications")
require("packs/app/dashboard")
require("packs/app/photos")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import interact from 'packs/general/interact.min';
window.interact = interact;

import jQueryScrollbar from 'jquery.scrollbar';
window.jQueryScrollbar = jQueryScrollbar;

import Swal from 'sweetalert2';
window.Swal = Swal;

import bloodhound from 'packs/general/bloodhound.js';
window.Bloodhound = bloodhound;

import handlebars from 'handlebars';
window.Handlebars = handlebars;

window.HOST_API = 'https://api.ideo.noreply.tn/'

// import jsPDF from "jspdf";
// window.jsPDF = jsPDF;

moment.locale("fr")

// $("#btn-signin").on("click", function() {
//     $(".connect-loader").removeClass("hidden")
//     $("#btn-signin").attr("disabled", true)
    
//     $('#form-login').submit(function(e){
//         e.preventDefault()
//     })

//     $.ajax({
//         url: "/login",
//         type: 'POST',
//         dataType: 'json',
//         data: {
//             email: $("#signin_email").val(),
//             password: $("#signin_password").val()
//         },
//         success: function(data) {
//             console.log("ok")
//            window.location = "/"
//         },
//         error: function(data) {
//             console.log("no")
//             $(".alert-login").removeClass("hidden")
//             $(".alert-login").text(data.responseJSON.message)

//             $(".connect-loader").addClass("hidden")

//             $("#form-login input[type=submit]").removeAttr("disabled")
//         }
//     })
// })
