document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "surveys") {

        let answers = []

        var rater = require("rater-js");
        var myRater = rater({element: document.querySelector("#rater"), rateCallback: function rateCallback(rating, done) {
                this.setRating(rating); 
                done(); 
            },
            starSize:32,
            step:0.5
        });

        $(".btn-surveys").on("click", function(e) {
            let question = $(e.currentTarget).data("question")
            $(".surveys-" + question).removeClass("active")
            $(this).addClass("active")

            if (question == 2) {
                if ($(e.currentTarget).text() == "Non") {
                    $(".reason_why_not_satisfied").removeClass("hidden")
                }
                else {
                    $(".reason_why_not_satisfied").addClass("hidden")
                    $("#survey-not_satisfied").val()
                }
            }
        })
        
        $("#btn-submit-surveys").on("click", function() {

            Swal.fire({
                title: "Formulaire de satisfaction",
                icon: "warning",
                text: `Valider vos réponses ?`,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    $(".progress").removeClass("hidden")
                    $(this).attr("disabled", true)

                    answers = []
                    let rates = myRater.getRating() //$(".rating").rate("getValue")
                    Array.prototype.forEach.call(document.getElementsByClassName('btn-surveys'), function(item) {
                        if($(item).hasClass('active') == true) {
                            answers.push(`${$(item).text()}`)
                        }
                    })

                    if ($(".btn-surveys[data-question='2'].active").text() == "Oui") {
                        $("#survey-not_satisfied").val("")
                    }

                    answers.push($("#survey-not_satisfied").val())
                    answers.push(rates)

                    let data =  {}
                    data['rate'] = answers

                    fetch(`${HOST_API}/worksites/rate/${$("#rate_code").val()}`, {
                        method: 'PUT',
                        headers: new Headers({
                            'Authorization': `Bearer ${$("#user_token").val()}`,
                            'Content-Type': 'application/json'
                        }),
                        mode: 'cors',
                        cache: 'default',
                        body: JSON.stringify(data)
                    }).then(function(response) {
                        response.json().then(function(data) {
                            window.location.reload()
                        })
                    })
                }
            });

        })
    }
})