document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "skills") {
        let tableSkills = null;
        let method = "new"
        let skill_id;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableSkills != null) {
                tableSkills.destroy();
            }
        })

        tableSkills = $('#table-skills').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bSearching": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            //"sDom": "<t><'row'<p i>>",
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "iDisplayLength": 50,
            "bAutoWidth": true,
            'width' : [[0, '10']],
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 2,
                    'className': 'dt-body-right'
                }
            ],
            "oLanguage": {
                "sSearch": "Rechercher:",
                "sZeroRecords": "Aucune entrée correspondante trouvée",
                "sEmptyTable": "Aucune donnée disponible dans le tableau"
            }
        });
        $("#table-skills_wrapper > div > .col-sm-6:nth-child(1)").remove()

        // open add/edit skill
        $('#btn-add-skill').on("click", function () {
            $('#modal-skill').modal({
                backdrop: 'static',
                keyboard: false  
            })
        });

        // set method new or edit
        $("#modal-skill").on('show.bs.modal', function(e) { 
            if ($(e.relatedTarget).data('method') == 'edit') {
                method = $(e.relatedTarget).data('method')
                
                skill_id = $(e.relatedTarget).data('skill_id')
        
                $("#btn-save-skill").attr("disabled", true)
                $("#skill-name").attr("disabled", true)
                $("#modal-skill .close").attr("data-dismiss", "")
                $(".skill-left_zone").html("<img src='/assets/img/loading.svg' width='32'>")

                $(".subtitle-modal").text("Modifier une compétence")
                get(skill_id)
            }
            else {
                method = "new"
                $(".subtitle-modal").text("Ajouter une nouvelle compétence")
                $("#btn-save-skill").data('method', 'new')
            }
        })

        // autofocus
        $("#modal-skill").on('shown.bs.modal', function(e) { 
            $("#skill-name").focus()
        });

        // close add/edit skill
        $("#modal-skill").on("hidden.bs.modal", function() {
            resetFields()
        })

        // btn save
        $("#btn-save-skill").on("click", function() {
            $("#form-skill").validate()
            $('#form-skill').submit(function(e){
                e.preventDefault()
            })

            if ($("#form-skill").valid()) {
                $(".progress").removeClass("hidden")
                $("#btn-save-skill").attr("disabled", true)
                $("#skill-name").attr("disabled", true)
                $("#modal-skill .close").attr("data-dismiss", "")

                if (method == "new") {
                    create()
                }
                else {
                    update()
                }
            }
        })

        function get(id) {
            let url = `${HOST_API}/skills`
            $(".dataTables_empty").html("<img src='/assets/img/loading.svg' width='48'>")

            if (id != undefined) {
                url += `/${id}`
            }

            $.ajax({
                url: url,
                type: 'GET',
                suppressGlobalErrorHandler: true,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    if (id == undefined) {
                        let canDestroy = '';
                        tableSkills.rows().clear().draw();
                        
                        jQuery.each(data, function (i, val) {
                            if (val.associated == false) {
                                canDestroy = `<a href="javascript:void(0)" id='btn-delete-skill-${val.id}' class="dropdown-item">Supprimer</a>`
                            }
                            else {
                                canDestroy = ''
                            }
    
                            let options = `<div class="dropdown">
                                <a id="card-settings-${val.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                    <i class="card-icon card-icon-settings "></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                        <a href="javascript:void(0)" data-skill_id='${val.id}' data-toggle="modal" data-target="#modal-skill" data-method='edit' class="dropdown-item">Modifier</a>
                                        ${canDestroy}
                                    </div>
                                </div>`
                            let newRow = tableSkills.row.add( [
                                val.id,
                                val.name,
                                options
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "skill-" + val.id);
                            
                            $(`#btn-delete-skill-${val.id}`).on("click", function() {
                                Swal.fire({
                                    title: "Suppression",
                                    icon: "warning",
                                    text: `Voulez-vous vraiment supprimer la compétence "${val.name}"?`,
                                    showCancelButton: true,
                                    cancelButtonText: 'Annuler',
                                    confirmButtonText: 'Oui',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        destroy(val.id)
                                    }
                                });
                            })
                            
                            $(".form-group > input").attr("disabled", false)
                            $("#dataTables_empty .dataTables_empty").html("")
                        });
                    }
                    else {
                        $("#btn-save-skill").attr("disabled", false)
                        $("#skill-name").attr("disabled", false)
                        $("#modal-skill .close").attr("data-dismiss", "modal")
                        $(".skill-left_zone").html("")

                        $("#skill-name").val(data.name)
                    }
                },
                error: function(jqXHR, textStatus, error) {
                    $(".alert-list_skills").removeClass("hidden")
                    $(".alert-list_skills").text(textStatus)
                }
            });
        }

        function create() {
            $.ajax({
                url: `${HOST_API}/skills`,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#skill-name").val()
                },
                dataType: "json",
                success: function (data) {
                    let options = `<div class="dropdown">
                        <a id="card-settings-${data.id}" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                            <i class="card-icon card-icon-settings "></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="card-settings" style="will-change: transform;">
                                <a href="javascript:void(0)" data-skill_id='${data.id}' data-toggle="modal" data-target="#modal-skill" data-method='edit' class="dropdown-item">Modifier</a>
                                <a href="javascript:void(0)" id='btn-delete-skill-${data.id}' class="dropdown-item">Supprimer</a>
                            </div>
                        </div>`
                    let newRow = tableSkills.row.add( [
                        data.id,
                        data.name,
                        options
                    ] ).draw( false ).node();

                    $(newRow).attr("id", "skill-" + data.id);
                    
                    $(`#btn-delete-skill-${data.id}`).on("click", function() {
                        Swal.fire({
                            title: "Suppression",
                            icon: "warning",
                            text: `Voulez-vous vraiment supprimer la compétence "${data.name}"?`,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            confirmButtonText: 'Oui',
                        }).then(result => {
                            if (result.isConfirmed) {
                                destroy(data.id)
                            }
                        });
                    })
                    
                    $('#modal-skill').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Nouvelle compétence enregistrée!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-skill").text(data.responseJSON.error)
                    $(".alert-skill").removeClass("hidden")
                }
            });
        }

        function update() {
            $.ajax({
                url: `${HOST_API}/skills/${skill_id}`,
                type: 'PUT',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                data: {
                    name: $("#skill-name").val()
                },
                dataType: "json",
                success: function (data) {
                    $("#skill-" + skill_id + " td:nth-child(2)").text($("#skill-name").val());
                    $('#modal-skill').modal("hide")

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Compétence modifié!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    disableFields(false)
                    $(".alert-skill").text(data.responseJSON.error)
                    $(".alert-skill").removeClass("hidden")
                }
            });
        }

        function destroy(id) {
            $(`#card-settings-${id}`).remove()

            $.ajax({
                url: `${HOST_API}/skills/${id}`,
                type: 'DELETE',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
                },
                dataType: "json",
                success: function (data) {
                    tableSkills.row($("#skill-" + id)).remove().draw();

                    $('.page-content-wrapper').pgNotification({
                        style: 'simple',
                        message: "Compétence supprimée!",
                        position: "top-right",
                        timeout: 0,
                        type: "success",
                        timeout: 3000,
                    }).show();
                },
                error: function(data) {
                    $(".progress").addClass("hidden")
                    $(".alert-skill").text(data.responseJSON.error)
                    $(".alert-skill").removeClass("hidden")
                }
            });
        }

        
        function resetFields() {
            $(".progress").addClass("hidden")
            $("#btn-save-skill").attr("disabled", false)
            $("#skill-name").attr("disabled", false)
            $("#modal-skill .close").attr("data-dismiss", "modal")
            $("#skill-name").val("")

            $(".alert-skill").addClass("hidden")
            $(".alert-skill").text("")

            skill_id = null
        }

        function disableFields(disable){
            if(disable) {
                $("#btn-save-skill").attr("disabled", true)
                $("#skill-name").attr("disabled", true)
                $("#modal-skill .close").attr("data-dismiss", "")
                $(".progress").removeClass("hidden")
            }
            else {
                $("#btn-save-skill").attr("disabled", false)
                $("#skill-name").attr("disabled", false)
                $("#modal-skill .close").attr("data-dismiss", "modal")
                $(".progress").addClass("hidden")
            }
        }

        get()
    }
})