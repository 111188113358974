let tableSendSMS;
let planning_id;
let planning_type;
let total_nbr_sms_txt = 160;
let text_send = `Voulez-vous vraiment envoyer cet SMS à tous les agents?`
let worksite_finished = false;

export function setPlanningId(val) {
    planning_id = val
}

export function initSms() {
   
    worksite_finished = false

    document.addEventListener("turbolinks:before-cache", function() {
        if (tableSendSMS != null) {
            tableSendSMS.destroy();
        }
    })
    
    tableSendSMS = $('#table-send_sms').DataTable({
        "bPaginate": false,
        "bFilter": false,
        "bSearching": false,
        "bInfo": false,
        "bLengthChange": false,
        "destroy": true,
        "scrollCollapse": true,
        "oLanguage": {
            "sLengthMenu": "_MENU_ ",
            "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
        },
        "bAutoWidth": false,
        'order' : [[0,'desc']],
        "oLanguage": {
            "sSearch": "Rechercher:",
            "sZeroRecords": "Aucune entrée correspondante trouvée",
            "sEmptyTable": "Aucune donnée disponible dans le tableau"
        }
    });
    $("#table-send_sms_wrapper > div > .col-sm-6:nth-child(1)").remove()

    $("#send_sms-text").on("keyup change", function() {
        //calcMessageSmsLength()
    })

    $("#btn-send_sms").on("click", function() {
        $("#form-send_sms").validate()
        $('#form-send_sms').submit(function(e){
            e.preventDefault()
        })
    
        if ($("#form-send_sms").valid()) {
            Swal.fire({
                title: "Envoyer SMS",
                icon: "warning",
                text: text_send,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
            }).then(result => {
                if (result.isConfirmed) {
                    $("#btn-send_sms").attr("disabled", true)
                    $("#send_sms-text").attr("disabled", true)
                    
                    envoyerSMS(planning_id, $("#send_sms-text").val())
                }
            });
        }
    })
}

export function showSMSAgents(planning_id, url) {
    $("#table-send_sms_wrapper .dataTables_empty").html("<img src='/assets/img/loading.svg' width='32'>")

    $.ajax({
        url: `${HOST_API}/worksites/${url}`,
        type: 'GET',
        suppressGlobalErrorHandler: true,
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
        },
        data: {
            id: planning_id
        },
        dataType: "json",
        success: function (data) {
            let workers;

            tableSendSMS.rows().clear().draw();
            $(".planning-date").text(moment(data.date).format("LL"))
            
            if (url == 'plannings')
                workers = data.planning_workers
            else if (url == 'plannings-regular') 
                workers = data.planning_regular_workers

            jQuery.each(workers, function (i, val) {
                // table send sms
                let newRow2 = tableSendSMS.row.add( [
                    val.worker.name,
                    ''
                ] ).draw( false ).node();
                
                $(newRow2).attr("id", `agent-sms-` + val.worker.id);
                $(newRow2).attr("class", `agent-sms`);
            })

            if (data.worksite.state != "in_progress") {
                worksite_finished = true
                $("#btn-send_sms").attr("disabled", true)
            }

            planning_type = url
            
            if (url == 'plannings') {
                getSmsStatus(planning_id, 'plannings')
            }
            else if (url == 'plannings-regular') {
                getSmsStatus(planning_id, 'planning_regulars')
            }
            
        }
    })
}

export function envoyerSMS(planning_id, message) {
    $(".agent-sms.sms-not-delivred td:nth-child(2)").html(`<img src='/assets/img/loading.svg' class='worksite-loader' width='20'>`)
    
    let url;
    if (planning_type == 'plannings')
        url = 'plannings'
    else if (planning_type == 'plannings-regular') 
        url = 'planning_regulars'

    $.ajax({
        url: `${HOST_API}/${url}/send_sms`,
        type: 'POST',
        suppressGlobalErrorHandler: true,
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
        },
        data: {
            planning_id: planning_id,
            message: message
        },
        dataType: "json",
        success: function (data) {
            let sms_status = 'sms-queue'
            let sms_title = 'Envoyer SMS'
            jQuery.each(data, function (i, val) {
                
                let response;

                if (val.code == 200){
                    response = `<span class="iconify sms-sent" data-icon="ep:success-filled" data-width="16" data-height="16"></span> Livré`
                    $(`#agent-sms-${val.agent_id}`).removeClass('sms-not-delivred')
                }
                else
                    response = `<span class="iconify sms-error" data-icon="ep:success-filled" data-width="16" data-height="16"></span> ${val.message}`
                
                $(`#agent-sms-${val.agent_id} td:nth-child(2)`).html(response)
            })

            if (data[data.length -1].status == 'partial_error'){
                sms_status = 'sms-partial-error'
                sms_title = "Il y a des erreurs d'envoi!"
            }
            else if(data[data.length-1].status == 'all_error'){
                sms_status = 'sms-error'
                sms_title = "Tous les sms n'ont pas encore été envoyés!"
            }
            else if (data[data.length-1].status == 'ok') {
                sms_status = 'sms-sent'
                sms_title = "Tous les sms ont été envoyés avec succès"
            }

            if(data[data.length -1].status == 'ok') {
                $("#btn-send_sms").attr("disabled", true)
            }
            else if (data[data.length -1].status == 'partial_error') {
                $("#btn-send_sms").attr("disabled", false)
                text_send = "Le SMS ne sera envoyé qu'aux personnes n'ayant pas reçu de message"
            }
            else if (data[data.length -1].status == 'all_error') {
                $("#btn-send_sms").attr("disabled", false)
                text_send = "Le SMS ne sera envoyé qu'aux personnes n'ayant pas reçu de message"
            }
            else if (data[data.length -1].status == 'not_yet') {
                $("#btn-send_sms").attr("disabled", false)
                text_send = `Voulez-vous vraiment envoyer cet SMS à tous les agents?`
            }

            $(`a[data-planning_id='${planning_id}'] i`).removeClass("sms-error")
            $(`a[data-planning_id='${planning_id}'] i`).removeClass("sms-sent")
            $(`a[data-planning_id='${planning_id}'] i`).removeClass("sms-partial-error")
            $(`a[data-planning_id='${planning_id}'] i`).addClass(sms_status)
            $(`a[data-planning_id='${planning_id}'] i`).attr("title", sms_title)

            //$("#btn-send_sms").attr("disabled", false)
            $("#send_sms-text").attr("disabled", false)
        }
    })
}

export function getSmsStatus(planning_id, url) {

    $(".agent-sms td:nth-child(2)").html(`<img src='/assets/img/loading.svg' class='worksite-loader' width='20'>`)
    
    $.ajax({
        url: `${HOST_API}/${url}/sms_status/${planning_id}`,
        type: 'GET',
        suppressGlobalErrorHandler: true,
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', `Bearer ${$("#user_token").val()}`);
        },
        dataType: "json",
        success: function (data) {

            jQuery.each(data, function (i, val) {
                let response;

                if (val.code != null) {
                    if (val.code == 200){
                        response = `<span class="iconify sms-sent" data-icon="ep:success-filled" data-width="16" data-height="16"></span> Livré`
                    }
                    else {
                        response = `<span class="iconify sms-error" data-icon="ep:success-filled" data-width="16" data-height="16"></span> ${val.message}`
                        $(`#agent-sms-${val.agent_id}`).addClass("sms-not-delivred")
                    }
                }
                else {
                    response = `<span class='badge'>Pas encore envoyé</span>`
                    $(`#agent-sms-${val.agent_id}`).addClass("sms-not-delivred")
                   
                }
                
                $(`#agent-sms-${val.agent_id} td:nth-child(2)`).html(response)
            })

            if (worksite_finished == false) {
                if(data[data.length -1].all_status == 'ok') {
                    $("#btn-send_sms").attr("disabled", true)
                }
                else if (data[data.length -1].all_status == 'partial_error') {
                    $("#btn-send_sms").attr("disabled", false)
                    text_send = "Le SMS ne sera envoyé qu'aux personnes n'ayant pas reçu de message"
                }
                else if (data[data.length -1].all_status == 'all_error') {
                    $("#btn-send_sms").attr("disabled", false)
                    text_send = "Le SMS ne sera envoyé qu'aux personnes n'ayant pas reçu de message"
                }
                else if (data[data.length -1].all_status == 'not_yet') {
                    $("#btn-send_sms").attr("disabled", false)
                    text_send = `Voulez-vous vraiment envoyer cet SMS à tous les agents?`
                }
            }
            else {
                $("#btn-send_sms").attr("disabled", true)
            }

            $(".sms-loading").addClass("hidden")
        }
    })
}

export function calcMessageSmsLength() {
    $(".send_sms-max_chars").text(total_nbr_sms_txt - $("#send_sms-text").val().length)
}

export function resetTableSMS() {
    tableSendSMS.rows().clear().draw();
    worksite_finished = false
}